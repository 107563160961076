import React from "react";
import { formatCurrency } from "../../../../../../formatCurrency";

export default function OverviewCard({ data }) {
  console.log("data data data", data);
  return (
    <div
      className="rounded position-relative m-0 p-3"
      style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)" }}
    >
      <div
        className="d-grid mt-3"
        style={{
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "1rem",
          justifyContent: "center",
          justifyItems: "center",
        }}
      >
        {data.map((employee) => {
          return (
            <div
              key={employee.label}
              className="d-flex align-items-center flex-column text-center"
            >
              <div
                className="fw-bold"
                style={{ fontSize: "1.2rem", color: "#DAB44E" }}
              >
                {employee.label === "Days Worked"
                  ? employee.value
                  : formatCurrency(employee.value)}
              </div>
              <div>
                <p
                  className="opacity-50"
                  style={{ color: "#434343", fontSize: ".75rem" }}
                >
                  {employee.label}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
