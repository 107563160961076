import React, { Component } from "react";
import NavBar from "../../components/navbar";
import { InfiniteScroll, Progress, Table, dialog } from "nq-component";
import AddNewEstimate from "./add-new-estimate/AddNewEstimate";
import BaseListPage from "../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  updateObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import EstimatePresenter from "./EstimatePresenter";
import printComponent from "../../printComponent";
import EstimateListPrint from "./EstimateListPrint";
import InvTable from "../../components/InvTable";
import Item from "nq-component/dist/Menu/Item";
import InvoicesPrint from "./InvoicesPrint;";
import InputFactory from "../../components/InputFactory";
import ApproveDialogContent from "./components/ApproveDialogContent";
// import InvoicesPrint from "../invoices/components/InvoicesPrint;";
class EstimatePage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new EstimatePresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
      invoiceData: {},
      selectedClient: null,
      filteredProjects: [],
    };

    this.contractPDF = React.createRef();
    this.invoicePDF = React.createRef();
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  // componentDidMount() {
  //   this.presenter.componentDidMount();

  //   // Check if the page has already been refreshed
  //   if (!sessionStorage.getItem("hasRefreshed")) {
  //     // Set timeout to refresh the page after 2 seconds (2000 milliseconds)
  //     setTimeout(() => {
  //       // Reload the page
  //       window.location.reload();
  //       // Set flag to prevent further refreshes
  //       sessionStorage.setItem("hasRefreshed", true);
  //     }, 2000);
  //   }
  // }
  // componentDidMount() {
  //   this.presenter.componentDidMount();

  //   // Check if the page has already been refreshed in this navigation session
  //   if (!window.history.state || !window.history.state.hasRefreshed) {
  //     // Set timeout to refresh the page after 2 seconds (2000 milliseconds)
  //     setTimeout(() => {
  //       // Reload the page
  //       window.location.reload();

  //       // Update the history state to mark that the page has been refreshed
  //       window.history.replaceState({ hasRefreshed: true }, "");
  //     }, 2000);
  //   }
  // }
  // componentDidMount() {
  //   this.presenter.componentDidMount();

  //   if (!window.history.state || !window.history.state.hasRefreshed) {
  //     setTimeout(() => {
  //       // Use a state change to force a component re-render instead of reloading the page
  //       this.forceUpdate();
  //       window.history.replaceState({ hasRefreshed: true }, "");
  //     }, 2000);
  //   }
  // }

  componentDidMount() {
    this.presenter.componentDidMount();

    const estimateRefresh = sessionStorage.getItem("estimateRefresh");

    // if (estimateRefresh === "true") {
    //   setTimeout(() => {
    //     // Refresh the page
    //     window.location.reload();
    //     // Remove estimateRefresh from sessionStorage
    //     sessionStorage.removeItem("estimateRefresh");
    //   }, 2000);
    // }
  }

  getCollectionName() {
    return "services_final";
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }
  onClickApprove(index) {
    dialog.fire({
      html: (
        <ApproveDialogContent
          onApprove={() => {
            this.presenter.onClickApprove(index, "Approved");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }
  onClickExport() {
    const objectss = this.getSelected();
    console.log("Selected data:", objectss);

    const id = objectss[0]?.estimate_num;
    console.log("ID:", id);
    this.exportPDF(id);
    const filename = this.exportPDF(id);
    this.presenter.onClickExport(filename);
  }

  exportPDF(id) {
    const filename = `Estimate_(${id})`;
    printComponent(this.contractPDF.current, filename);
    console.log("Exporting PDF for ID:", id);
    return filename;
  }

  onClickPdf(index) {
    dialog.close();
    const data = this.state.objects[index];
    this.navigateTo(`/create-invoice/${data.id}`);
    // const data = this.state.objects[index];
    // console.log("Selected data:", data);
    // this.setState({ invoiceData: data });
    // printComponent(this.invoicePDF.current, "invoices");
    // const id = objectss[0]?.estimate_num;
    // console.log("ID:", id);
    // this.exportPDF(id);
    // const filename = this.exportPDF(id);
    // this.presenter.onClickExport(filename);
  }

  // onChangeFilter(type, value, field) {
  //   const where = { ...this.state.filters };

  //   switch (type) {
  //     case "Pointer":
  //       if (Object.keys(value).length > 0) {
  //         where[field] = { id: value.id };

  //         if (field === "client_name") {
  //           this.setState(
  //             { selectedClient: value.id },
  //             this.fetchProjectsByClient(value.id),
  //             console.log(
  //               "filtered",
  //               this.state.selectedClient,
  //               this.state.filteredProjects
  //             )
  //           );
  //         }
  //       } else {
  //         delete where[field];
  //       }
  //       break;

  //     case "Boolean":
  //       where[field] = value;
  //       break;

  //     default:
  //       if (value) {
  //         where[field] = { $regex: value, $options: "i" };
  //       } else {
  //         delete where[field];
  //       }
  //   }

  //   this.setState({ filters: where }, () => this.filterSubmit(where));
  // }

  // fetchProjectsByClient(clientId) {
  //   findObjectUseCase()
  //     .execute("projects", { where: { client: { id: clientId } } })
  //     .then((projects) => {
  //       this.setState({ filteredProjects: projects });
  //       console.log("filtered projects", this.state.filteredProjects, clientId);
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching projects:", err);
  //     });
  // }

  onChangeFilter(type, value, field) {
    const where = { ...this.state.filters };

    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };

          if (field === "client_name") {
            const newSelectedClient = value.id;
            const hasChanged = this.state.selectedClient !== newSelectedClient;

            this.setState({ selectedClient: newSelectedClient }, () => {
              this.fetchProjectsByClient(newSelectedClient);
              if (!hasChanged) {
                this.fetchProjectsByClient(newSelectedClient);
              }
            });
          }
        } else {
          if (field === "client_name") {
            this.setState(
              {
                selectedClient: null,
                filteredProjects: [],
                filters: {},
              },
              () => {
                delete where["project_name"];
                this.filterSubmit({});
              }
            );
          }
          delete where[field];
        }
        break;

      case "Boolean":
        where[field] = value;
        break;

      default:
        if (value) {
          where[field] = { $regex: value, $options: "i" };
        } else {
          delete where[field];
        }
    }

    this.setState({ filters: where }, () => {
      this.filterSubmit(where);
    });
  }

  async fetchProjectsByClient(clientId) {
    findObjectUseCase()
      .execute("projects")
      .then((projects) => {
        const filteredProjects = projects
          .filter((obj) => obj.client?.id === clientId)
          .map((obj) => ({ id: obj.id, name: obj.name })) // Map directly to the project ID and name
          .filter(
            (value, index, self) =>
              self.findIndex((v) => v.id === value.id) === index
          ); // Ensure uniqueness by ID

        this.setState({ filteredProjects });
        console.log("filtered projects:", this.state.filteredProjects);
      })
      .catch((err) => console.error("Error fetching projects:", err));
  }

  handleEdit(index) {
    this.presenter.handleEdit(index);
  }
  handleCreate(index, objects) {
    this.presenter.handleCreate(index, objects);
  }

  async handleDelete(index) {
    const hatdog = await findObjectUseCase().execute("estimate_forms");

    try {
      await this.showDialog({
        title: "Delete Data?",
        message: "Are you sure you want to delete?",
      });

      const reversedIndex = hatdog.length - 1 - index;
      if (reversedIndex >= 0 && reversedIndex < hatdog.length) {
        const objectId = hatdog[reversedIndex].id;

        console.log("objectId", objectId);
        await deleteObjectUseCase().execute("estimate_forms", objectId);
      } else {
        console.log("Invalid index or empty hatdog array.");
      }
    } catch (error) {
      console.log("Deletion canceled.");
    }
  }
  onClickItemDelete(index, object) {
    this.presenter.onClickItemDelete(index);
  }

  onClickItemInvoice(index, object) {
    dialog.fire({
      html: (
        <>
          <div className="text-center">
            <i
              class="bi bi-question-circle"
              style={{ color: "#ebbf38", fontSize: "70px" }}
            ></i>
            <h4>Are you sure want to Create Invoice?</h4>
          </div>
          <div className="text-end m-4 ">
            <button
              className="btn me-2 "
              style={{ backgroundColor: "#ebbf38" }}
              onClick={() => this.onClickPdf(index)}
            >
              YES
            </button>
            <button
              className="btn"
              style={{ backgroundColor: "#f1f2f4" }}
              onClick={() => dialog.close()}
            >
              CANCEL
            </button>
          </div>
        </>
      ),
      footer: false,
    });
  }

  onCollapse(index, object) {
    const item = object[index];
    return (
      <>
        <div className="d-flex">
          <div className="me-1">
            {(object.statuses === "Pending" ||
              object.statuses === "Pending (Warm Lead)" ||
              object.statuses === "Pending (Cold Lead)") && (
              <button
                className="btn text-white me-1"
                onClick={this.onClickApprove.bind(this, index)}
                style={{ backgroundColor: "#4aab73" }}
              >
                {" "}
                <i className="bi bi-check me-1"></i>Approve
              </button>
            )}
            <button
              className="btn btn-primary"
              onClick={() => this.onClickItem(index)}
            >
              <i className="bi bi-pencil-square me-1"></i>EDIT
            </button>
          </div>
          <div className="me-1">
            <button
              className="btn btn-danger"
              onClick={() => this.onClickItemDelete(index, object)}
            >
              {" "}
              <i className="bi bi-trash me-1"></i>DELETE
            </button>
          </div>
          <div className="">
            {object.statuses === "Approved" && (
              <button
                className="btn text-white"
                onClick={() => this.onClickItemInvoice(index, object)}
                style={{ backgroundColor: "#ebbf38" }}
              >
                {" "}
                <i className="bi bi-plus me-1"></i>CREATE INVOICE
              </button>
            )}
          </div>
        </div>
      </>
    );
  }

  // getAcl() {
  //   const roles = this.getCurrentRoles();
  //   const aclRoles = roles.map((r) => `role:${r.name}`);
  //   const user = this.getCurrentUser();
  //   return {
  //     read: ["*", user.id, ...aclRoles],
  //     write: [user.id, ...aclRoles],
  //   };
  // }

  render() {
    const { showModal } = this.state;
    const user = this.getCurrentRoles();
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, total, count, progress } = this.state;
    console.log("yehey", objects);
    if (!schema) return <Progress />;

    let objectss = this.getSelected();
    let invoice = this.state.invoiceData;

    const formattedObjects = objects.map((obj) => {
      const createdAtDate = obj.updatedAt.split("T")[0];
      const parsedCreatedAtDate = new Date(createdAtDate);
      const diffInMs = new Date() - parsedCreatedAtDate;
      const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

      let newStatus = obj.statuses;

      if (obj.statuses === "Pending") {
        if (diffInDays >= 15) {
          newStatus = "Pending (Cold Lead)";
        } else {
          newStatus = "Pending (Warm Lead)";
        }
      } else if (obj.statuses === "Pending (Cold Lead)") {
        if (diffInDays < 15) {
          newStatus = "Pending (Warm Lead)";
        }
      }

      // const formattedTotal = formatCurrency(obj.total);

      return {
        ...obj,
        // total: formattedTotal,
        statuses: newStatus,
      };
    });

    let actionList = [];

    formattedObjects.forEach((object, index) => {
      let label = "";
      let onClick = null;

      switch (object.statuses) {
        case "Pending":
        case "Pending (Warm Lead)":
        case "Pending (Cold Lead)":
          label = "Edit";
          onClick = this.handleEdit.bind(this, index);
          break;
        case "Approved":
          break;
        default:
          break;
      }

      if (label) {
        actionList.push({
          label: label,
          onClick: onClick,
        });
      }
    });

    return (
      <>
        <div className="d-none">
          <div ref={this.contractPDF}>
            <EstimateListPrint object={objectss} />
          </div>
        </div>
        <div className="d-none">
          <div ref={this.invoicePDF}>
            <InvoicesPrint object={invoice} />
          </div>
        </div>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize"> Estimate</h1>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <button
                  onClick={this.toggleModal}
                  className="btn bg-main px-3 py-2"
                >
                  <i className="bi bi-plus-lg me-2"></i>Create Estimate
                </button>
                {objectss && objectss.length > 0 && (
                  <button
                    className="btn bg-sec me-2 px-3 py-2"
                    onClick={() => this.onClickExport()}
                  >
                    <i className="bi bi-download me-2"></i>Download PDF
                  </button>
                )}
                {/* <div className="d-flex justify-content-between align-items-center">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    if (field === "project_name") {
                      options = {
                        ...options,
                        data: this.state.selectedClient
                          ? this.state.filteredProjects
                          : [],
                        placeholder: this.state.selectedClient
                          ? "Select a project"
                          : "Select a project",
                      };

                      return (
                        <InputFactory
                          key={field}
                          className="ms-1"
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter.bind(this, type)}
                          disabled={!this.state.selectedClient}
                          {...options}
                        />
                      );
                    }
                    if (field === "client_name") {
                      return (
                        <InputFactory
                          key={field}
                          className="ms-1"
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter.bind(this, type)}
                          {...options}
                        />
                      );
                    }
                  })}
                </div>
              </div> */}
                <div className="d-flex justify-content-between align-items-center">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    if (field === "project_name") {
                      options = {
                        ...options,
                        data: this.state.selectedClient
                          ? this.state.filteredProjects
                          : [],
                        placeholder: this.state.selectedClient
                          ? "Select a project"
                          : "Select a project",
                      };

                      return (
                        <select
                          className="form-select ms-1"
                          onChange={(e) =>
                            this.onChangeFilter(
                              "Pointer",
                              { id: e.target.value },
                              "project_name"
                            )
                          }
                          disabled={!this.state.selectedClient}
                        >
                          <option value="">
                            {this.state.selectedClient
                              ? "Select a project"
                              : "Please select a client first"}
                          </option>

                          {this.state.filteredProjects.map((project) => (
                            <option key={project.id} value={project.id}>
                              {project.name}
                            </option>
                          ))}
                        </select>
                      );
                    }
                    if (field === "client_name") {
                      return (
                        <InputFactory
                          key={field}
                          className="ms-1"
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter.bind(this, type)}
                          {...options}
                        />
                      );
                    }
                  })}
                </div>
              </div>

              {/* Modal */}
              <AddNewEstimate
                showModal={showModal}
                toggleModal={this.toggleModal}
              />

              <InvTable
                fields={schema.fields}
                objects={formattedObjects}
                selectable
                collapsable
                // actionsList={actionList}
                excludeFields={[
                  "id",
                  "ASFType",
                  "discountType",
                  "updatedAt",
                  "approveAt",
                  "acl",
                  "name",
                  "description",
                  "exclusive_item",
                  "notes",
                  "ASF",
                  "discount",
                  "subTotal",
                  "taxAmount",
                  "address",
                  "company_name",
                  "telephone_number",
                  "mobile_number",
                  // "services",
                  "quantity",
                  "rate",
                  "amount",
                  "actions",
                  "asfType",
                  "exclusive",
                  "asf",
                  "tax",
                  "estimate_type",
                  "services",
                  "addVAT",
                  "nameService",
                  "nameDescription",
                  "descriptionArray",
                  "downpaymentType",
                  "downpaymentVal",
                  "downpayment",
                  "downpaymentAmount",
                ]}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onCollapse={this.onCollapse.bind(this)}
                // actions={{
                //   handleEdit: this.handleEdit.bind(this),
                //   handleDelete: this.handleDelete.bind(this),
                //   handleCreate: this.handleCreate.bind(this, objects),
                // }}
                onClickItem={this.onClickItem.bind(this)}
                className="mt-3"
              ></InvTable>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default EstimatePage;
