import React from "react";
import { formatCurrency } from "../../../formatCurrency";

const getDeductions = (value) => {
  if (!value || isNaN(value)) return 0;
  return value;
};

const getOtherDeductions = (object) => {
  if (!object) return;
  let total = 0;

  object?.moreOtherDeductions?.forEach(
    (item) => (total += item.deductionAmount || 0)
  );

  return total;
};

export default function EmployeeCard({
  object,
  employeeName,
  employeePosition,
  type,
  monthlyRate,
  increases,
  startDate,
  endDate,
  cashAdvanceInfo,
  regularHolidayPay,
}) {
  console.log("OBJJJJ", object);

  // const calculateDaysWorked = () => {
  //   const total =
  //     (object?.HRMonday || 0) +
  //     (object?.HRTuesday || 0) +
  //     (object?.HRWednesday || 0) +
  //     (object?.HRThursday || 0) +
  //     (object?.HRFriday || 0) +
  //     (object?.HRSaturday || 0) +
  //     (object?.HRSunday || 0);

  //   return Math.floor(total / 8);
  // };
  const calculateDaysWorked = () => {
    const days = [
      object?.HRMonday,
      object?.HRTuesday,
      object?.HRWednesday,
      object?.HRThursday,
      object?.HRFriday,
      object?.HRSaturday,
      object?.HRSunday,
    ];

    const totalDays = days.reduce((acc, hours) => {
      return acc + (hours > 0 ? 1 : 0);
    }, 0);

    return totalDays;
  };

  const calculateSubtotal = () => {
    let total = 0;

    if (increases) {
      Object.keys(increases).forEach((item) => (total += increases[item]));
      object?.adjustments?.forEach(
        (item) => (total += item.adjustmentAmount || 0)
      );
    }

    total += object?.allowance || 0;

    if (object?.caRequest) {
      const totalCashAdvance = cashAdvanceInfo
        .filter((ca) => ca.createdAt >= startDate && ca.createdAt <= endDate)
        .reduce(
          (acc, curr) =>
            acc + (parseFloat(curr.amounts.replace(/,/g, ""), 10) || 0),
          0
        );
      console.log("totsSSSS", total);
      total += totalCashAdvance;
    }

    total += regularHolidayPay || 0;

    total += object?.leaveRequestPay || 0;

    return total;
  };

  const calculateDeductions = () => {
    let total = 0;
    total =
      getDeductions(object?.TIN) +
      getDeductions(object?.PHILHEALTH) +
      getDeductions(object?.SSS) +
      getDeductions(object?.PAGIBIG) +
      getDeductions(object?.PAGIBIGLOAN) +
      getDeductions(object?.SSSDELQ) +
      getDeductions(object?.SSSLOAN) +
      getDeductions(object?.SSSCONDO) +
      getDeductions(object?.CADeductions) +
      getOtherDeductions(object);

    console.log("tots", total);

    return total;
  };

  const employeeInfo = [
    {
      label: type === "Weekly" ? "Days Worked" : "Basic Salary",
      value:
        type === "Weekly"
          ? calculateDaysWorked()
          : formatCurrency(monthlyRate / 2 || 0),
    },
    {
      label: "Subtotal",
      value:
        type === "Weekly"
          ? formatCurrency(calculateSubtotal())
          : formatCurrency(object?.subtotal),
    },
    {
      label: "Deductions",
      value:
        type === "Weekly"
          ? formatCurrency(calculateDeductions())
          : formatCurrency(object?.deductions),
    },
    {
      label: "Total",
      value:
        type === "Weekly"
          ? formatCurrency(calculateSubtotal() - calculateDeductions())
          : formatCurrency(object?.subtotal - object?.deductions),
    },
  ];

  console.log("employee", employeeInfo);

  return (
    <div
      className="rounded p-3 position-relative m-0"
      style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)" }}
    >
      <div className="row p-0 m-0">
        <p
          className="m-0"
          style={{
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#434343",
          }}
        >
          {employeeName}
        </p>
      </div>
      <div className="row p-0 m-0">
        <p
          className="m-0 opacity-50 "
          style={{ color: "#434343", fontSize: ".875rem" }}
        >
          {employeePosition}
        </p>
      </div>
      <div className="row justify-content-around mt-3">
        {employeeInfo.map((employee) => {
          return (
            <div
              key={employee.label}
              className="col-auto d-flex align-items-center flex-column"
            >
              <div
                className="row-auto fw-bold"
                style={{ fontSize: "1.2rem", color: "#DAB44E" }}
              >
                {employee.value}
              </div>
              <div className="row-auto ">
                <p
                  className="opacity-50"
                  style={{ color: "#434343", fontSize: ".875rem" }}
                >
                  {employee.label}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
