import React from "react";
import NavBar from "../../components/navbar";
import { Button, InfiniteScroll, Progress, dialog } from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import BudgetRequestPresenter from "./BudgetRequestPresenter";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import InputFactory from "../../components/InputFactory";
import DateRange from "../../components/DateRange";
import Table from "../../components/Table";
import classNames from "../../classNames";
import ProgressBar from "./progressBar/ProgressBar";
import ListItems from "./listItem/ListItems";
import { ActionButton } from "./actionButton/ActionButton";
import ApproveDialogContent from "./approveDialog/ApproveDialogContent";
import FilterComponent from "./filterComponent/FilterComponent";
import CancelDialogContent from "./cancelDialog/CancelDialogContent";
import PoTable from "../../components/PoTable";

class BudgetRequestPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new BudgetRequestPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
    };
  }
  componentDidMount() {
    const hasRefreshed = localStorage.getItem("hasRefreshed");
    if (!hasRefreshed) {
      // Set the flag to prevent continuous refresh
      localStorage.setItem("hasRefreshed", "true");

      // Refresh the page
      // window.location.reload();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      localStorage.removeItem("hasRefreshed");
      this.setState({ loading: false });
      this.presenter.componentDidMount();
      this.filterList();
    }
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.id === "admin" || role.id === "masterAdmin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({ creditedTo: { id: user?.id } });
    }
  }

  getCollectionName() {
    return "budget_request";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeEmployee(where) {
    this.presenter.onChangeEmployee(where);
  }

  filterSubmit2(where) {
    this.presenter.filterSubmit2(where);
  }
  filterSubmitClient(where) {
    this.presenter.filterSubmitClient(where);
  }
  filterSubmit4(where) {
    this.presenter.filterSubmit4(where);
  }

  filterSubmit5(where) {
    this.presenter.filterSubmit5(where);
  }

  filterSubmit3(where) {
    this.presenter.filterSubmit3(where);
  }

  onChangeFilter2(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = value.name;
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit2(where);
    //console.log(type, value, field);
  }

  onChangeFilterClient(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmitClient(where);
    //console.log(type, value, field);
  }

  onChangeFilter4(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit4(where);
  }

  onChangeFilter5(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit5(where);
  }

  onChangeFilter3(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit3(where);
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onSetDisApproved(e) {
    this.setState({ disApproveMessage: e.target.value });
  }

  disapprove(index, dp) {
    this.presenter.onClickStatus(index, "Disapproved", dp);
  }

  onClickApprove(index) {
    dialog.fire({
      html: (
        <ApproveDialogContent
          onApprove={() => {
            this.presenter.onClickStatus(index, "Approved");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  onClickPaid(index, object) {
    console.log("paidObject", object);
    this.presenter.onClickStatus(index, "Paid", object);
  }

  onClickLiquidation(index) {
    this.presenter.onClickStatus(index, "Liquidation");
  }

  onClickApproveLiquidation(index) {
    this.presenter.onClickStatus(index, "Paid");
  }

  onClickDisapproved(index) {
    dialog.fire({
      html: (
        <div>
          <div>
            <h6 className="fw-bold" style={{ color: "#EBBD2F" }}>
              <i
                className="bi bi-file-earmark-medical"
                style={{ color: "#EBBD2F" }}
              ></i>{" "}
              Disapprove Budget Request
            </h6>
          </div>
          <div>
            <p>
              Enter the reason why you want to Disapprove this budget request:
            </p>
          </div>
          <div>
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Text"
              onInput={(e) => this.onSetDisApproved(e)}
            />
          </div>
          <div className="text-center m-4">
            <button
              className="btn btn-sm me-2"
              onClick={() => {
                this.disapprove(index, this.state.disApproveMessage);
                dialog.close();
              }}
              style={{ backgroundColor: "#EBBD2F", color: "white" }}
            >
              Disapprove
            </button>
            <button
              className="btn btn-light btn-sm"
              onClick={() => dialog.close()}
            >
              Cancel
            </button>
          </div>
        </div>
      ),
      footer: false,
    });
    // this.presenter.onClickStatus(index, "disapproved");
  }

  onClickCancel(index) {
    dialog.fire({
      html: (
        <CancelDialogContent
          onApprove={() => {
            this.presenter.onClickStatus(index, "Cancelled");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  onClickLiquidate(index, field) {
    this.presenter.onClickLiquidate(index, field);
  }

  onClickViewLiquidation(index, field) {
    this.presenter.onClickViewLiquidation(index, field);
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onCollapse(index, object) {
    const user = this.getCurrentUser();
    const roleAdmin = user.roles.find((r) => r.id === "masterAdmin");
    const role = user.roles.find((r) => r.id === "admin");
    const role1 = user.roles.find((r) => r.id === "normal");
    const hasSingleNormalRole = user.roles.length === 1 && role1;

    const buttonConfig = [
      {
        condition:
          object.statuses !== "Cancelled" &&
          object.statuses !== "Done" &&
          object.statuses !== "Paid" &&
          object.statuses !== "Liquidation" &&
          object.statuses !== "Approved",
        onClick: () => this.onClickItem(index),
        className: "btn btn-sm text-white",
        style: { backgroundColor: "#EBBD2F" },
        iconClass: "bi bi-pencil-square me-1",
        label: "EDIT",
      },
      {
        condition: object.statuses === "Liquidation",
        onClick: () => this.onClickLiquidate(index),
        className: "btn btn-sm text-white",
        style: { backgroundColor: "#EBBD2F" },
        iconClass: "bi bi-pencil-square me-1",
        label: "EDIT",
      },
      {
        condition:
          hasSingleNormalRole &&
          object.statuses === "Paid" &&
          object.statuses !== "Cancelled" &&
          object.statuses !== "Disapproved",
        onClick: () => this.onClickLiquidate(index),
        className: "btn btn-success text-white btn-sm ms-3",
        label: "LIQUIDATE",
      },
      // Liquidate Button Configuration
      {
        condition:
          object.statuses === "Paid" &&
          object.statuses !== "Cancelled" &&
          object.statuses !== "Disapproved",

        onClick: () => this.onClickLiquidate(index),
        className: "btn btn-success text-white btn-sm ms-3",
        label: "LIQUIDATION",
      },
    ];

    if (role || roleAdmin) {
      buttonConfig.push(
        // View Liquidation Button Configuration
        {
          condition: object.statuses === "Approved",
          onClick: () => this.onClickItem(index),
          className: "btn btn-sm text-white",
          style: { backgroundColor: "#EBBD2F" },
          iconClass: "bi bi-pencil-square me-1",
          label: "EDIT",
        },
        {
          condition:
            object.receipt &&
            object.statuses !== "Paid" &&
            object.statuses !== "Cancelled" &&
            object.statuses !== "Disapproved",
          onClick: () => this.onClickViewLiquidation(index),
          className: "btn btn-success text-white btn-sm ms-3",
          label: "View liquidation",
        },
        // Approve Button Configuration
        {
          condition:
            (object.statuses === "Pending" ||
              object.statuses === "Disapproved") &&
            object.statuses !== "Cancelled" &&
            object.statuses !== "Disapproved",
          onClick: () => this.onClickApprove(index),
          className: "btn text-white btn-sm ms-3",
          style: { backgroundColor: "#4aab73" },
          iconClass: "bi bi-check-lg me-1",
          label: "Approve",
        },
        // Paid Button Configuration
        {
          condition:
            object.statuses !== "Liquidation" &&
            object.statuses !== "Paid" &&
            object.statuses !== "Done" &&
            object.statuses !== "Pending" &&
            object.statuses !== "Cancelled" &&
            object.statuses !== "Disapproved",
          onClick: () => this.onClickPaid(index, object),
          className: "btn text-white btn-sm ms-3",
          style: { backgroundColor: "#EBBD2F" },
          iconClass: "bi bi-cash-coin",
          label: "Paid",
        },
        // Liquidate Button Configuration
        {
          condition:
            object.statuses === "Liquidation" &&
            object.statuses !== "Cancelled" &&
            object.statuses !== "Disapproved" &&
            role1,
          onClick: () => this.onClickLiquidate(index),
          className: "btn btn-success text-white btn-sm ms-3",
          label: "LIQUIDATION",
        },
        // Disapprove Button Configuration
        {
          condition:
            object.statuses === "Pending" &&
            object.statuses !== "Cancelled" &&
            object.statuses !== "Disapproved",
          onClick: () => this.onClickDisapproved(index),
          className: "btn btn-danger text-white btn-sm ms-3",
          iconClass: "bi bi-x-lg me-1",
          label: "Disapprove",
        },
        {
          condition:
            object.statuses === "Pending" &&
            object.statuses !== "Cancelled" &&
            object.statuses !== "Disapproved",
          onClick: () => this.onClickCancel(index),
          className: "btn btn-light btn-sm ms-3",
          iconClass: "bi bi-x-lg me-1",
          label: "Cancel Request",
        }
      );
    }

    return (
      <div>
        <ListItems object={object} />

        <ProgressBar status={object.statuses} />

        {buttonConfig.map(
          (btn, idx) =>
            btn.condition && (
              <ActionButton
                key={idx}
                onClick={btn.onClick}
                className={btn.className}
                style={btn.style}
                iconClass={btn.iconClass}
                label={btn.label}
              />
            )
        )}
      </div>
    );
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };
  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, count, progress } = this.state;
    const { isOffcanvasOpen } = this.state;

    const user = this.getCurrentRoles();
    console.warn("userRoles", user[0]?.id);

    console.log("objec", objects);
    const excludeArrayUser = [
      "acl",
      "password",
      "users",
      "items_list",
      "br",
      "date",
      "supplier",
      "particulars",
      "tin",
      "or",
      "chart",
      "remark",
      "receipt",
      "status",
      "client_name",
      "creditedTo",
      "liqui",
      "items1",
      "items2",
      "items3",
      "items4",
      "items5",
      "items6",
      "items7",
      "items8",
      "items9",
      "items10",
      "requesteeString",
    ];
    const excludeArray = [
      "acl",
      "password",
      "users",
      "items_list",
      "br",
      "date",
      "createdAt",
      "supplier",
      "particulars",
      "tin",
      "or",
      "chart",
      "remark",
      "receipt",
      "status",
      "client_name",
      // "requesteeString",
      "projectString",
      "creditedTo",
      "liqui",
      "items1",
      "items2",
      "items3",
      "items4",
      "items5",
      "items6",
      "items7",
      "items8",
      "items9",
      "items10",
    ];
    const roles = this.getCurrentRoles();
    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Budget Request</h1>

                <div className="d-block d-md-none mt-2">
                  <i
                    className="bi bi-filter"
                    style={{ fontSize: "25px", color: "#EBBD2F" }}
                    onClick={this.toggleOffcanvas}
                    aria-controls="offcanvasRight"
                  ></i>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters2 || {}).map((field) => {
                          let { type, ...options } = schema.filters2[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter2.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters5 || {}).map((field) => {
                          let { type, ...options } = schema.filters5[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter5.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}
                        {Object.keys(schema.filters15 || {}).map((field) => {
                          let { type, ...options } = schema.filters15[field];

                          return (
                            <InputFactory
                              key={field}
                              className="ms-1"
                              type={type}
                              field={field}
                              where={{}}
                              onChange={this.onChangeFilterClient.bind(
                                this,
                                type
                              )}
                              {...options}
                            />
                          );
                        })}

                        {roles.some(
                          (role) =>
                            role.id === "admin" || role.id === "masterAdmin"
                        ) &&
                          Object.keys(schema.filters3 || {}).map((field) => {
                            let { type, ...options } = schema.filters3[field];

                            return (
                              <InputFactory
                                key={field}
                                className="ms-1 "
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter3.bind(this, type)}
                                {...options}
                              />
                            );
                          })}

                        <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="createdAt"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block">
                <div className="d-flex mt-3">
                  {Object.keys(schema.filters4 || {}).map((field) => {
                    //filter for client
                    let { type, ...options } = schema.filters4[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter4.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    );
                  })}
                  {Object.keys(schema.filters15 || {}).map((field) => {
                    let { type, ...options } = schema.filters15[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilterClient.bind(this, type)}
                        {...options}
                      />
                    );
                  })}
                  {Object.keys(schema.filters2 || {}).map((field) => {
                    let { type, ...options } = schema.filters2[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter2.bind(this, type)}
                        {...options}
                      />
                    );
                  })}
                  {roles.some(
                    (role) => role.id === "admin" || role.id === "masterAdmin"
                  ) &&
                    Object.keys(schema.filters3 || {}).map((field) => {
                      let { type, ...options } = schema.filters3[field];

                      return (
                        <InputFactory
                          key={field}
                          className="ms-1 "
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter3.bind(this, type)}
                          {...options}
                        />
                      );
                    })}

                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                  {(user[0]?.id === "admin" || user[0]?.id === "masterAdmin") &&
                    selected.length > 0 && (
                      <div className="dropdown">
                        <button
                          className="border-0"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-three-dots"></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li
                            className="dropdown-item"
                            role="button"
                            onClick={this.onClickDeleteSelected.bind(this)}
                          >
                            <i className="bi bi-trash"></i> Delete Selected
                          </li>
                        </ul>
                      </div>
                    )}
                </div>
              </div>

              <PoTable
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  user[0]?.name === "user" ? excludeArrayUser : excludeArray
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
            <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn  shadow-sm "
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#EBBD2F",
                }}
              >
                <i className="bi bi-plus " />
              </Button>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default BudgetRequestPage;
