import BaseListPresenter from "../../../base/BaseListPresenter";

class PayslipPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  async getObjects() {
    this.reset();
    await this.countObjects();
    await this.findObjects();
    await this.getHolidays();
    await this.getEmpoyeePayroll();
  }

  createQuery() {
    const roles = this.view.getCurrentRoles().map((item) => item.id);
    const where =
      roles.includes("Production") || roles.includes("user")
        ? { type: "Weekly", status: "Paid" }
        : { type: "Monthly", status: "Paid" };
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: { ...this.where, ...this.search, ...this.filter, ...where },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  async getHolidays() {
    try {
      const holidays = await this.findObjectUseCase.execute("holidays", {
        keys: ["date", "type"],
        include: ["type"],
      });
      this.view.setState({ holidays });
    } catch (error) {
      console.log(error);
    }
    this.progress = false;
  }

  async getEmpoyeePayroll() {
    const payrollData = await this.findObjectUseCase.execute(
      "employee_payroll_info",
      {
        where: { userId: this.view.getCurrentUser().id },
      }
    );

    console.log("PayrollDATA", payrollData);
    this.view.setState({ userPayrolls: payrollData });
  }
}

export default PayslipPresenter;
