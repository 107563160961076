import React from "react";
import Modal from "../../../../../components/Modal";
import Header from "./components/Header";
import OverviewCard from "./components/OverviewCard";
import TableBreakdown from "./components/TableBreakdown";
import Adjustments from "./components/Adjustments";
import OtherDeductions from "./components/OtherDeductions";
import CTA from "./components/CTA";
import MonthlyTableBreakdown from "./components/MonthlyTableBreakdown";

export default function ViewPayslipModal({
  isOpen,
  toggleModal,
  selectedPreview,
  holidays,
}) {
  const {
    subtotal,
    total,
    daysWorked,
    allowance,
    CADeductions,
    deductions,
    TIN,
    PHILHEALTH,
    SSS,
    PAGIBIG,
    tsDeductions,
  } = selectedPreview?.employeePayrollInfo || {};

  // console.log(selectedPreview);
  const isWeekly = selectedPreview.payrollType === "Weekly";

  const totalOverviewData = [
    {
      label: isWeekly ? "Days Worked" : "Basic Salary",
      value: isWeekly ? daysWorked : selectedPreview.basicSalary,
    },
    {
      label: "Subtotal",
      value:
        subtotal ||
        selectedPreview.basicSalary ||
        selectedPreview.subtotal ||
        0,
    },
    { label: "Total", value: total || selectedPreview.subtotal || 0 },
  ];

  const deductionsOverviewData = [
    { label: "Allowances", value: allowance || 0 },
    { label: "Cash Advance", value: CADeductions || 0 },
    { label: "Total Deduction", value: deductions || 0 },
  ];

  const deductionDetailsData = [
    { label: "CA Deduction", value: CADeductions || 0 },
    { label: "TIN", value: TIN || 0 },
    { label: "PHILHEALTH", value: PHILHEALTH || 0 },
    { label: "SSS", value: SSS || 0 },
    { label: "PAGIBIG", value: PAGIBIG || 0 },
    { label: "Attendance Deduction", value: tsDeductions || 0 },
  ];

  const otherDeductions = [
    {
      label: "PAGIBIG LOAN",
      value: selectedPreview.employeePayrollInfo?.PAGIBIGLOAN || 0,
    },
    {
      label: "SSS DELQ",
      value: selectedPreview.employeePayrollInfo?.SSSDELQ || 0,
    },
    {
      label: "SSS CONDO",
      value: selectedPreview.employeePayrollInfo?.SSSCONDO || 0,
    },
    {
      label: "SSS LOAN",
      value: selectedPreview.employeePayrollInfo?.SSSLOAN || 0,
    },
    ...(selectedPreview.employeePayrollInfo?.moreOtherDeductions ?? []).map(
      (item) => ({
        label: item.deductionLabel,
        value: item.deductionAmount,
      })
    ),
  ];

  const adjustments = [
    ...(selectedPreview.employeePayrollInfo?.adjustments ?? []).map((item) => ({
      label: item.adjustmentDescription,
      value: item.adjustmentAmount,
    })),
  ];

  return (
    <Modal isOpen={isOpen} size="lg">
      <div className="p-4 d-flex flex-column gap-4">
        <Header
          name={selectedPreview.fullName}
          startDate={selectedPreview.startDate}
          endDate={selectedPreview.endDate}
        />
        <OverviewCard data={totalOverviewData} />
        <OverviewCard data={deductionsOverviewData} />
        {selectedPreview.payrollType === "Weekly" ? (
          <TableBreakdown
            payroll={selectedPreview.employeePayrollInfo}
            startDate={selectedPreview.startDate}
            endDate={selectedPreview.endDate}
            holidays={holidays}
          />
        ) : (
          <MonthlyTableBreakdown
            payroll={selectedPreview.employeePayrollInfo}
          />
        )}
        <OverviewCard data={deductionDetailsData} />
        <div className="d-flex gap-4 ">
          <Adjustments adjustments={adjustments} />
          <OtherDeductions deductions={otherDeductions} />
        </div>
        <CTA toggleModal={toggleModal} />
      </div>
    </Modal>
  );
}
