import React from "react";
import NavBar from "../../components/navbar";
import { Button, InfiniteScroll, Progress, dialog } from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import InputFactory from "../../components/InputFactory";
import DateRange from "../../components/DateRange";
import DesignListPresenter from "./DesignListPresenter";
import { browseFile } from "nq";
import classNames from "../../classNames";
import CliTable from "../../components/CliTable";
import ApproveDialogContent from "./components/ApproveDialogContent";
import DeclineDialogContent from "./components/DeclineDialogContent";
const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

class DesignListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new DesignListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }

  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.id === "Admin" || role.id === "masterAdmin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({ employee: { id: user?.id } });
    }
  }

  getCollectionName() {
    return "project_designs";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onSetApprovedAmount(e) {
    this.setState({ amounts: e.target.value });
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] =
          field === "statuses" && value
            ? { $eq: value }
            : { $regex: value, $options: "i" };
    }
    this.filterSubmit(where);
  }

  onClickApprove(index) {
    dialog.fire({
      html: (
        <ApproveDialogContent
          onApprove={() => {
            this.presenter.onClickStatus(index, "Approved");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }
  onClickDecline(index) {
    dialog.fire({
      html: (
        <DeclineDialogContent
          onDecline={() => {
            this.presenter.onClickStatus(index, "Declined");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  onCollapse(index, object) {
    const role = this.getCurrentRoles();
    const user = this.getCurrentUser();

    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Designer: </span>
              <span className="text-nowrap">{object.employee?.fullName}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Design Link: </span>
              <span className="text-nowrap">
                <a
                  href={object.link}
                  style={{ color: "#cf6f00" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-paperclip"></i>
                  {object.link}
                </a>
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Description: </span>
              <span className="text-nowrap">{object.description}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Date: </span>
              <span className="text-nowrap">
                {formatDate(object.createdAt)}
              </span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">Status: </span>
              <span
                className={`text-nowarp fw-bold ${
                  object.statuses === "Cancelled"
                    ? "text-primary"
                    : object.statuses === "Approved"
                    ? "text-success"
                    : object.statuses === "Declined"
                    ? "text-danger"
                    : object.statuses === "For Approval"
                    ? "text-warning"
                    : ""
                }`}
                style={object.statuses === "Paid" ? { color: "#CF6F00" } : {}}
              >
                {object.statuses}
              </span>
            </li>
          </ul>
        </div>

        <div>
          {object.statuses === "For Approval" && (
            <>
              {!user.roles.some(
                (role) => role.id === "hrAdmin" || role.id === "masterAdmin"
              ) && (
                <button
                  onClick={this.onClickItem.bind(this, index)}
                  className="btn btn-primary mb-2 mt-2 me-2"
                  style={{ backgroundColor: "#EBBD2F", border: 0 }}
                >
                  View
                </button>
              )}
            </>
          )}
          {object.statuses === "Disapproved" && (
            <>
              {!user.roles.some(
                (role) => role.id === "hrAdmin" || role.id === "masterAdmin"
              ) && (
                <button
                  onClick={this.onClickItem.bind(this, index)}
                  className="btn btn-primary mb-2 mt-2 me-2"
                  style={{ backgroundColor: "#EBBD2F", border: 0 }}
                >
                  Edit
                </button>
              )}
            </>
          )}
        </div>
        {object.statuses === "For Approval" && (
          <>
            {user.roles.some(
              (role) => role.id === "hrAdmin" || role.id === "masterAdmin"
            ) && (
              <>
                <button
                  onClick={this.onClickApprove.bind(this, index)}
                  className="btn btn-primary mb-2 mt-2 me-2"
                  style={{ backgroundColor: "#4aab73", border: 0 }}
                >
                  <i class="bi bi-check"></i> APPROVE
                </button>
                <button
                  onClick={this.onClickDecline.bind(this, index)}
                  className="btn btn-danger mb-2 mt-2 me-2"
                  style={{ border: 0 }}
                >
                  <i class="bi bi bi-x"></i> DECLINE
                </button>
                <button
                  onClick={this.onClickItem.bind(this, index)}
                  className="btn btn-primary"
                  style={{ backgroundColor: "#EBBD2F", border: 0 }}
                >
                  <i class="bi bi-pencil-square"></i> EDIT
                </button>
              </>
            )}
          </>
        )}
      </div>
    );
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, total, count, progress } = this.state;
    const user = this.getCurrentUser();

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Design</h1>

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#EBBD2F" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="createdAt"
                        />
                        {user.roles.some(
                          (role) =>
                            role.id === "admin" ||
                            role.id === "hrAdmin" ||
                            role.id === "masterAdmin"
                        ) && this.state.selected.length > 0 ? (
                          <button
                            className="btn btn-danger"
                            aria-expanded="false"
                            onClick={this.onClickDeleteSelected.bind(this)}
                          >
                            <i className="bi bi-trash"></i>Delete
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3 justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <div className="mb-2">
                        <InputFactory
                          key={field}
                          className="ms-1"
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter.bind(this, type)}
                          {...options}
                        />
                      </div>
                    );
                  })}

                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>
                {user.roles.some(
                  (role) => role.id === "hrAdmin" || role.id === "masterAdmin"
                ) && this.state.selected.length > 0 ? (
                  <button
                    className="btn btn-danger"
                    aria-expanded="false"
                    onClick={this.onClickDeleteSelected.bind(this)}
                  >
                    <i className="bi bi-trash"></i>Delete
                  </button>
                ) : (
                  <></>
                )}
              </div>

              <CliTable
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce((acc, key) => {
                  const options = schema.fields[key];
                  if (options.read === false) {
                    acc.push(key);
                  }
                  switch (options._type || options.type) {
                    case "Relation":
                    case "Array":
                    case "Object":
                    case "File":
                      acc.push(key);
                      break;
                    default:
                  }
                  return acc;
                }, ["acl", "password", "br", "or", "items", "id", "description"].concat(user.roles.some((role) => role.id !== "hrAdmin" && role.id !== "masterAdmin") ? ["name"] : []))}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>

            <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#EBBD2F",
                }}
              >
                <i className="bi bi-plus" />
              </Button>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default DesignListPage;
