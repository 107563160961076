import React, { useState } from "react";
import InputFactory from "../InputFactory";
import Drop from "../DropdownFilter";
import CustomDateInput from "../../pages/invoices/CustomDate";

const newCategoryData = {
  questions: [
    {
      service: {},
      rate: 0,
      quantity: 0,
      amount: 0,
      name: "",
      description: "",
    },
  ],
};

function InvoiceQuestion({
  pid,
  cid,
  object,
  onChangeService,
  setObjectState,
  back,
  onSubtotalChange,
  onTaxChange,
  onTotalChange,
  selectedProject,
  options,
  projectChange,
  onDateChange,
  onNameChange,
  onDescriptionChanges,
}) {
  const [discountType, setDiscountType] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [asfAmount, setAsfAmount] = useState(0);
  const [asfType, setAsfType] = useState("");
  const [downpaymentType, setDownpaymentType] = useState("");
  const [
    downpaymentAmount,
    setDownpaymentAmount,
    downpaymentVal,
    setDownpaymentVal,
  ] = useState(0);

  function onAddCategory(e) {
    e.preventDefault();
    const newCategory = JSON.parse(JSON.stringify(newCategoryData));
    const newCategories = [...object.services, newCategory];
    const updatedExam = { ...object, services: newCategories };
    setObjectState(updatedExam);
  }

  function handleChangeName(categoryIndex, questionIndex, event) {
    onNameChange(event);
    sessionStorage.setItem("updatedName", JSON.stringify(event));
  }

  function handleChangeDescription(categoryIndex, questionIndex, event) {
    // Call the provided onDescriptionChange function
    if (typeof onDescriptionChanges === "function") {
      onDescriptionChanges(event);
    } else {
      console.error("onDescriptionChange is not a function");
    }

    // Retrieve the existing descriptions from sessionStorage
    let updatedDescriptions =
      JSON.parse(sessionStorage.getItem("updatedDescriptionsInvoices")) || [];

    // Create a new description object
    const newDescription = {
      categoryIndex,
      questionIndex,
      description: event,
    };

    // Find if there's already a description for this category and question
    const existingDescriptionIndex = updatedDescriptions?.findIndex(
      (desc) =>
        desc.categoryIndex === categoryIndex &&
        desc.questionIndex === questionIndex
    );

    if (existingDescriptionIndex !== -1) {
      // Update existing description
      updatedDescriptions[existingDescriptionIndex] = newDescription;
    } else {
      // Add new description
      updatedDescriptions.push(newDescription);
    }

    // Save the updated descriptions array to sessionStorage
    sessionStorage.setItem(
      "updatedDescriptionsInvoices",
      JSON.stringify(updatedDescriptions)
    );
  }

  function onDeleteCategory(id, categoryIndex) {
    // Copy of services and descriptionArray
    const updatedCategories = [...object.services];
    const updatedDesc = object.descriptionArray
      ? [...object.descriptionArray]
      : [];

    // Find the service index based on its id (since indexes may not always match)
    const serviceIndex = updatedCategories.findIndex(
      (service) => service.id === id
    );

    if (serviceIndex === -1) {
      console.error("Service not found");
      return;
    }

    // Remove the corresponding service
    updatedCategories.splice(serviceIndex, 1);

    // Remove the corresponding description by matching categoryIndex
    const descriptionIndex = updatedDesc.findIndex(
      (desc) => desc.categoryIndex === categoryIndex
    );
    if (descriptionIndex !== -1) {
      updatedDesc.splice(descriptionIndex, 1);
    }

    // Adjust categoryIndex for remaining descriptions with higher indices
    const adjustedDesc = updatedDesc.map((desc) => {
      if (desc.categoryIndex > categoryIndex) {
        desc.categoryIndex -= 1; // Decrease the index to account for the removed item
      }
      return desc;
    });

    // Update the services and descriptions in the object and set the state
    const updatedExam = {
      ...object,
      services: updatedCategories,
      descriptionArray: adjustedDesc,
    };

    console.log("UPDATED DESCRIPTIONS", updatedExam);
    setObjectState(updatedExam);

    // Handle updatedDescriptions in sessionStorage (if required)
    let sessionDescriptions =
      JSON.parse(sessionStorage.getItem("updatedDescriptionsInvoices")) || [];

    // Filter and remove the corresponding description in sessionStorage
    sessionDescriptions = sessionDescriptions.filter(
      (desc) => desc.categoryIndex !== categoryIndex
    );

    // Adjust indices in sessionStorage
    sessionDescriptions = sessionDescriptions.map((desc) => {
      if (desc.categoryIndex > categoryIndex) {
        desc.categoryIndex -= 1;
      }
      return desc;
    });

    // Save the updated descriptions back to sessionStorage
    sessionStorage.setItem(
      "updatedDescriptionsInvoices",
      JSON.stringify(sessionDescriptions)
    );

    // Logging for debugging
    console.log("Updated Categories: ", updatedCategories);
    console.log(
      "Updated Descriptions in sessionStorage: ",
      sessionDescriptions
    );
  }

  function handleChangeQuantity(categoryIndex, questionIndex, event) {
    const { value } = event.target;
    const newCategories = [...object.services];
    const question = newCategories[categoryIndex].questions[questionIndex];
    // question.quantity = parseInt(value) || 0;
    question.quantity = parseFloat(value) || 0; // Use parseFloat to handle decimal values
    question.amount = question.rate * question.quantity;
    setObjectState({ ...object, services: newCategories });
  }

  function handleChangeRate(categoryIndex, questionIndex, event) {
    const { value } = event.target;
    const newCategories = [...object.services];
    const question = newCategories[categoryIndex].questions[questionIndex];
    // question.rate = parseInt(value) || 0;
    question.rate = parseFloat(value) || 0; // Use parseFloat to handle decimal values
    question.amount = question.rate * question.quantity;
    setObjectState({ ...object, services: newCategories });
  }

  function handleServiceChange(categoryIndex, questionIndex, value) {
    const newCategories = [...object.services];
    newCategories[categoryIndex].questions[questionIndex].name = value.name;
    newCategories[categoryIndex].questions[questionIndex].description =
      value.description;
    newCategories[categoryIndex].questions[questionIndex].service = value;
    const updatedExam = { ...object, services: newCategories };
    setObjectState(updatedExam);
    handleChangeDescription(categoryIndex, questionIndex, value.description);
  }

  function handleVAT(value, field) {
    setObjectState({ ...object, addVAT: value });
    setObjectState({
      ...object,
      downpaymentVal:
        parseFloat(
          calculateDP(object.downpaymentAmount, object.downpaymentType)
        ) || 0,
    }); //Recompute
  }

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
      minimumFractionDigits: 2, // Ensure at least 2 decimal places
      maximumFractionDigits: 2, // Limit to 2 decimal places
    }).format(amount);
  };

  function calculateTax(discount, dType, asf, aType) {
    const subtotal = calculateSubtotal();
    let total = subtotal;

    let discountValue = discount;
    if (dType === "percent") {
      discountValue = subtotal * (discount / 100);
    }
    total -= discountValue;

    // ASF
    let asfValue = asf;
    if (aType === "percent") {
      asfValue = subtotal * (asf / 100);
    }
    total += asfValue;

    const tax = total * 0.12;

    onTaxChange(tax);
    return tax;
  }

  function calculateDiscount(discount, type) {
    const subtotal = calculateSubtotal();
    if (type === "percent") {
      return subtotal * (discount / 100);
    }

    return discount;
  }

  function calculateAsf(asf, type) {
    const subtotal = calculateSubtotal();
    if (type === "percent") {
      return subtotal * (asf / 100);
    }

    return asf;
  }

  function calculateTotal(discount, dType, asf, aType) {
    const subtotal = calculateSubtotal();
    let total = subtotal;

    // DISCOUNT
    let discountValue = discount;
    if (dType === "percent") {
      discountValue = subtotal * (discount / 100);
    }
    total -= discountValue;

    // ASF
    let asfValue = asf;
    if (aType === "percent") {
      asfValue = subtotal * (asf / 100);
    }
    total += asfValue;

    const tax = total * 0.12;
    // total += tax;
    if (object.addVAT === true) {
      total += tax;
    } else {
      total += 0;
    }

    onTotalChange(total);
    return total; // Total amount is the sum of subtotal and tax
  }

  function handleDiscount(value, field) {
    console.log("DISCOUNT", parseFloat(value) || 0);

    setDiscountAmount(parseFloat(value) || 0);
    setObjectState({ ...object, discount: parseFloat(value) || 0 });
  }

  function handleDiscountType(type) {
    setDiscountType(type);
    setObjectState({ ...object, discountType: type });
  }
  function handleNotesChange(value, field) {
    setObjectState({ ...object, notes: value });
  }

  function calculateSubtotal() {
    const totalBeforeDiscount = object.services?.reduce((total, service) => {
      if (service.__operation === "REMOVE") return total;
      return (
        total +
        service.questions.reduce((serviceTotal, question) => {
          return serviceTotal + (question.amount || 0);
        }, 0)
      );
    }, 0);

    onSubtotalChange(Math.max(totalBeforeDiscount, 0));
    return Math.max(totalBeforeDiscount, 0);
  }

  function calculateDP(dp, type) {
    const subtotal = calculateSubtotal() || 0;
    const discount = object.discount || 0;
    const asf = object.asf || 0;
    const tax = object.addVAT === true ? object.tax : 0;
    console.log("tax", tax);

    if (type === "percent") {
      console.log("dpp", dp);
      console.log("object?.total", object?.total);
      console.log("discount", discount);
      console.log("asf", asf);
      console.log("tax", tax);
      // const result = (object?.total - discount + asf + tax) * (dp / 100);
      const result = object?.total * (dp / 100);
      console.log("dfdf", Math.round(result)); // Use Math.round() to ensure proper rounding
      sessionStorage.setItem("invoiceDp", result);
      return Math.round(result); // Return the rounded result
    } else {
      sessionStorage.setItem("invoiceDp", dp);
    }

    console.log("dp", dp);

    return dp;
  }

  function handleAsf(value, field) {
    setAsfAmount(parseFloat(value) || 0);
    setObjectState({ ...object, asf: parseFloat(value) || 0 });
  }

  function handleASFType(type) {
    setAsfType(type);
    setObjectState({ ...object, asfType: type });
  }

  function handleDownpaymentTypeChange(type) {
    setDownpaymentType(type);
    setObjectState({ ...object, downpaymentType: type });
  }

  // React.useEffect(() => {
  //   const dpVal = calculateDP(downpaymentAmount, downpaymentType);
  //   setDownpaymentVal(dpVal);
  //   setObjectState((prevState) => ({
  //     ...prevState,
  //     downpaymentVal: dpVal,
  //   }));
  // }, [
  //   downpaymentAmount,
  //   downpaymentType,
  //   object.total,
  //   object.discount,
  //   object.asf,
  //   object.addVAT,
  // ]);

  // React.useEffect(() => {
  //   const dpVal = calculateDP(object.downpaymentAmount, object.downpaymentType);
  //   setObjectState((prevState) => ({
  //     ...prevState,
  //     downpaymentVal: dpVal,
  //   }));
  // }, [true]);

  function handleDownpayment(value, field) {
    console.log("valval", value);
    setDownpaymentAmount(parseFloat(value) || 0);
    // setDownpaymentVal(parseFloat(value) || 0);
    setObjectState({ ...object, downpaymentAmount: parseFloat(value) || 0 });
    setObjectState({
      ...object,
      downpaymentVal:
        parseFloat(
          calculateDP(object.downpaymentAmount, object.downpaymentType)
        ) || 0,
    });
  }

  function handlePO(value, field) {
    setAsfAmount(parseFloat(value) || 0);
    setObjectState({ ...object, po_num: value });
  }

  function getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const newDesc = sessionStorage.getItem("updatedDescriptionsInvoices");
  const parseNewDesc = JSON.parse(newDesc);

  React.useEffect(() => {
    // Clear sessionStorage on component mount (page refresh)
    // sessionStorage.clear();
    sessionStorage.removeItem("updatedDescriptionsInvoices");
  }, []); // The empty array means this runs only once on mount

  console.log("MANLOLOKO", parseNewDesc);

  return (
    <>
      <div className="bg-white p-4 row g-3 mb-3">
        <div>
          <hr className="dropdown-divider" />
        </div>

        <div className="row">
          <div className="col">
            {!cid && (
              <div className="">
                <label className="form-label">Client Name</label>
                <InputFactory
                  type="UserInputPointer"
                  dynamic={false}
                  target="clients"
                  field="client_name"
                  object={object}
                  placeholder="Select Client Name"
                  onChange={onChangeService}
                  required={true}
                />
              </div>
            )}
            {!pid && (
              <div className="">
                <label className="form-label">Project Name</label>
                <Drop
                  placeholder={
                    selectedProject
                      ? "Select a project"
                      : object.project_name?.name
                  }
                  value={selectedProject ? selectedProject : null}
                  options={options}
                  onChange={projectChange}
                />
              </div>
            )}
            <label className="form-label pt-3 ">Due Date</label>
            <div className="row">
              <CustomDateInput
                defaultDate={getCurrentDateFormatted()}
                onDateChange={onDateChange}
              />
            </div>
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="col">
                  <label className="fw-bold">Discount</label>
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className={`btn ${
                        discountType === "percent" ||
                        object.discountType === "percent"
                          ? "btn-primary"
                          : "btn-secondary"
                      } m-2`}
                      style={{ padding: "0.15rem 0.5rem", fontSize: "0.75rem" }}
                      onClick={() => handleDiscountType("percent")}
                    >
                      %
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        discountType === "value" ||
                        object.discountType === "value"
                          ? "btn-primary"
                          : "btn-secondary"
                      } m-2`}
                      style={{ padding: "0.15rem 0.5rem", fontSize: "0.75rem" }}
                      onClick={() => handleDiscountType("value")}
                    >
                      ₱
                    </button>
                  </div>
                </div>
                {discountType === "percent" && (
                  <InputFactory
                    className="mt-2"
                    // type="parseNumber"
                    type="Number"
                    dynamic={false}
                    field="discount"
                    object={object}
                    placeholder="Enter Discount Percentage..."
                    onChange={handleDiscount}
                  />
                )}
                {discountType === "value" && (
                  <InputFactory
                    className="mt-2"
                    // type="parseNumber"
                    type="Number"
                    dynamic={false}
                    field="discount"
                    object={object}
                    placeholder="Enter Discount Value..."
                    onChange={handleDiscount}
                  />
                )}
              </div>
              <div className="col">
                <div className="col">
                  <label className="fw-bold">ASF</label>
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className={`btn ${
                        asfType === "percent" || object.asfType === "percent"
                          ? "btn-primary"
                          : "btn-secondary"
                      } m-2`}
                      style={{ padding: "0.15rem 0.5rem", fontSize: "0.75rem" }}
                      onClick={() => handleASFType("percent")}
                    >
                      %
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        asfType === "value" || object.asfType === "value"
                          ? "btn-primary"
                          : "btn-secondary"
                      } m-2`}
                      style={{ padding: "0.15rem 0.5rem", fontSize: "0.75rem" }}
                      onClick={() => handleASFType("value")}
                    >
                      ₱
                    </button>
                  </div>
                </div>
                {asfType === "percent" && (
                  <InputFactory
                    className="mt-2"
                    // type="parseNumber"
                    type="Number"
                    dynamic={false}
                    field="asf"
                    object={object}
                    placeholder="Enter ASF Percentage..."
                    onChange={handleAsf}
                  />
                )}
                {asfType === "value" && (
                  <InputFactory
                    className="mt-2"
                    // type="parseNumber"
                    type="Number"
                    dynamic={false}
                    field="asf"
                    object={object}
                    placeholder="Enter ASF Value..."
                    onChange={handleAsf}
                  />
                )}
              </div>
              <div className="col">
                <div className="d-flex col ">
                  <label className="fw-bold mt-2">Add VAT?</label>
                  <InputFactory
                    className="mt-2 ms-2"
                    type="Boolean"
                    field="addVAT"
                    object={object}
                    onChange={handleVAT}
                    required={false}
                  />
                </div>
              </div>
            </div>
            <div className="col mt-4">
              <label className=" fw-bold">Notes</label>
              <InputFactory
                type="TextArea"
                field="name"
                object={object}
                placeholder={"Enter exclusive items.."}
                defaultValue="1) Inclusive of Labor Fees (Inclusive of Night Differential) 2) Inclusive of Contingency Fee 3) Inclusive of Indirect Cost Fee 4) Inclusive of Value Added Taxes 5) Price Validity: 30 Days after Receiving 6) If the quotation is approved, Quotation Number must be indicated at Purchase Orders (PO) 7) Any alteration of the design and additional items not included in the contract will be billed accordingly. 8) GMD deserves the right to hold, pullout, suspend delivery if payments and other conditions are not met. - GMD Productions has a NO DP & NO Signed Contract = NO Production Policy - We request the following Payment Terms: 50% downpayment upon confirmation of project - Cost is based on specified requirements however additional requirements other than stated above shall be billed separately. - All permits must be c/o client Account Name: GMD PRODUCTIONS INC BDO CHECKING - 012758000370 BPI CHECKING - 6011 04 82 03"
                required={true}
                onChange={handleNotesChange}
              />
            </div>
            <div className="col mt-4">
              <label className=" fw-bold">PO NUMBER</label>
              <InputFactory
                type="Number"
                field="po_num"
                object={object}
                placeholder={"Enter PO Number"}
                onChange={handlePO}
              />
            </div>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>SERVICE</th>
              <th>NAME</th>
              <th>DESCRIPTION</th>
              <th>QTY</th>
              <th>RATE</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {object?.services?.map((service, category_index) => {
              return (
                <>
                  {!service.__operation && (
                    <>
                      <tr key={service.id || category_index}>
                        {service?.questions.map((question, question_index) => {
                          return (
                            <React.Fragment key={question_index}>
                              <td>
                                <span
                                  className="ms-2"
                                  role="button"
                                  onClick={() =>
                                    onDeleteCategory(service.id, category_index)
                                  }
                                >
                                  <i className="bi bi-trash"></i>
                                </span>
                              </td>
                              <td>
                                <InputFactory
                                  type="ModdedInputPointer"
                                  target="services_list"
                                  field="name"
                                  placeholder={
                                    question.service?.name ?? "Add Service"
                                  }
                                  defaultValue={question.service?.name}
                                  object={object}
                                  required={true}
                                  onChange={(e) =>
                                    handleServiceChange(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                />
                              </td>

                              <td>
                                <InputFactory
                                  type="String"
                                  field="nameService"
                                  object={object}
                                  placeholder="name"
                                  defaultValue={question.service?.name}
                                  required={true}
                                  disabled
                                  // disabled={[
                                  //   "Approved",
                                  //   "Sent",
                                  //   "Paid",
                                  // ].includes(object.statuses)}
                                  onChange={(e) =>
                                    handleChangeName(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                />
                              </td>

                              <td>
                                <InputFactory
                                  type="TextArea"
                                  field="nameDescription"
                                  object={object}
                                  // placeholder={
                                  //   question.service?.description ??
                                  //   "description"
                                  // }
                                  defaultValue={
                                    object.descriptionArray &&
                                    object.descriptionArray.find(
                                      (desc) =>
                                        desc.categoryIndex === category_index
                                    )
                                      ? parseNewDesc?.find(
                                          (desc) =>
                                            desc.categoryIndex ===
                                            category_index
                                        )?.description
                                        ? parseNewDesc?.find(
                                            (desc) =>
                                              desc.categoryIndex ===
                                              category_index
                                          )?.description
                                        : object.descriptionArray.find(
                                            (desc) =>
                                              desc.categoryIndex ===
                                              category_index
                                          ).description
                                      : question.service?.description
                                  }
                                  required={true}
                                  disabled={[
                                    "Approved",
                                    "Sent",
                                    "Paid",
                                  ].includes(object.statuses)}
                                  onChange={(e) =>
                                    handleChangeDescription(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={question.quantity}
                                  onChange={(e) =>
                                    handleChangeQuantity(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={question.rate}
                                  onChange={(e) =>
                                    handleChangeRate(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={question.amount}
                                  disabled
                                  required
                                />
                              </td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    </>
                  )}
                </>
              );
            })}
          </tbody>
        </table>

        <button
          className="btn d-flex justify-content-start align-items-left"
          type="button"
          onClick={onAddCategory}
          style={{ border: "none", width: "150px" }}
        >
          <span className="bi bi-plus-circle-fill text-danger me-1 ms-2"></span>
          <span className="text-danger ms-2">Add Service</span>
        </button>
        <div className="row">
          <div className="col-8"></div>
          <div className="col">
            <ul
              className="list-unstyled ms-1 text-truncate"
              style={{ width: "100%" }}
            >
              <li style={{ padding: "5px" }}>
                <div className="col">
                  <div className="col">
                    <label className="fw-bold">Invoice Amount</label>
                    <div className="btn-group" role="group">
                      <button
                        type="button"
                        className={`btn ${
                          downpaymentType === "percent" ||
                          object.downpaymentType === "percent"
                            ? "btn-primary"
                            : "btn-secondary"
                        } m-2`}
                        style={{
                          padding: "0.15rem 0.5rem",
                          fontSize: "0.75rem",
                        }}
                        onClick={() => handleDownpaymentTypeChange("percent")}
                      >
                        %
                      </button>
                      <button
                        type="button"
                        className={`btn ${
                          downpaymentType === "value" ||
                          object.downpaymentType === "value"
                            ? "btn-primary"
                            : "btn-secondary"
                        } m-2`}
                        style={{
                          padding: "0.15rem 0.5rem",
                          fontSize: "0.75rem",
                        }}
                        onClick={() => handleDownpaymentTypeChange("value")}
                      >
                        ₱
                      </button>
                    </div>
                  </div>
                  {downpaymentType === "percent" && (
                    <InputFactory
                      className="mt-1"
                      type="Number"
                      dynamic={false}
                      field="downpaymentAmount"
                      object={object}
                      placeholder="Enter Percentage..."
                      onChange={handleDownpayment}
                    />
                  )}
                  {downpaymentType === "value" && (
                    <InputFactory
                      className="mt-1"
                      type="Number"
                      dynamic={false}
                      field="downpaymentAmount"
                      object={object}
                      placeholder="Enter Value..."
                      onChange={handleDownpayment}
                    />
                  )}
                </div>
              </li>
              <li style={{ padding: "5px" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <span
                    className="fw-bold fs-6"
                    style={{ paddingRight: "20px" }}
                  >
                    Subtotal
                  </span>

                  <span className="fw-bold fs-6" style={{ color: "" }}>
                    {formatCurrency(calculateSubtotal())}
                  </span>
                </div>
              </li>
              <li style={{ padding: "5px" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <span
                    className="bold"
                    style={{ paddingRight: "20px", fontSize: ".9rem" }}
                  >
                    Discount
                  </span>

                  <span
                    className="fw-medium"
                    style={{ color: "#ce1126", fontSize: ".9rem" }}
                  >
                    -
                    {object.id
                      ? formatCurrency(
                          calculateDiscount(
                            object.discount,
                            object.discountType
                          )
                        )
                      : formatCurrency(
                          calculateDiscount(discountAmount, discountType)
                        )}
                  </span>
                </div>
              </li>
              <li style={{ padding: "5px" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <span
                    className="bold"
                    style={{ paddingRight: "20px", fontSize: ".9rem" }}
                  >
                    ASF
                  </span>

                  <span
                    className="bold"
                    style={{ color: "#49af41", fontSize: ".9rem" }}
                  >
                    +
                    {object.id
                      ? formatCurrency(calculateAsf(object.asf, object.asfType))
                      : formatCurrency(calculateAsf(asfAmount, asfType))}
                    {/* {thiss} */}
                  </span>
                </div>
              </li>

              {object.addVAT === true && (
                <li style={{ padding: "5px" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="bold" style={{ fontSize: ".9rem" }}>
                      Tax 12%
                    </span>
                    <span
                      className="bold"
                      style={{ color: "#49af41", fontSize: ".9rem" }}
                    >
                      +
                      {formatCurrency(
                        calculateTax(
                          object.discount,
                          object.discountType,
                          object.asf,
                          object.asfType
                        )
                      )}
                    </span>
                  </div>
                </li>
              )}
              <li style={{ padding: "5px" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="bold" style={{ fontSize: ".9rem" }}>
                    Total
                  </span>
                  {/* <span
                    className="bold fw-bold fs-6"
                    style={{ color: "#D8AE3F" }}
                  > */}
                  <span className="bold" style={{ fontSize: ".9rem" }}>
                    {object.id
                      ? formatCurrency(
                          calculateTotal(
                            object.discount,
                            object.discountType,
                            object.asf,
                            object.asfType
                          )
                          // -
                          //   (calculateDP(
                          //     object.downpaymentAmount,
                          //     object.downpaymentType
                          //   ) || 0)
                        )
                      : formatCurrency(
                          calculateTotal(
                            discountAmount,
                            discountType,
                            asfAmount,
                            asfType
                          )
                          // -
                          //   (calculateDP(
                          //     object.downpaymentAmount,
                          //     object.downpaymentType
                          //   ) || 0)
                        )}
                  </span>
                </div>
              </li>

              <li style={{ padding: "5px" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="bold" style={{ fontSize: ".9rem" }}>
                    Payment
                  </span>
                  <span
                    className="bold"
                    style={{
                      // color: "#ce1126",
                      fontSize: ".9rem",
                    }}
                  >
                    {formatCurrency(
                      calculateDP(
                        object.downpaymentAmount,
                        object.downpaymentType
                      ) || 0
                    )}
                  </span>
                </div>
              </li>
              <li style={{ padding: "5px" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="bold" style={{ fontSize: ".9rem" }}>
                    Balance Due
                  </span>
                  <span
                    className="bold"
                    style={{
                      // color: "#ce1126",
                      fontSize: ".9rem",
                    }}
                  >
                    {formatCurrency(
                      (calculateDP(
                        object.downpaymentAmount,
                        object.downpaymentType
                      ) || 0) - (object.received || 0)
                    )}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="row text-center justify-content-center">
          {object.statuses === "Pending" && object.invoice_num ? (
            <div className="col-4">
              <button
                type="submit"
                className="btn fs-sm me-3"
                style={{ backgroundColor: "#dfbe68" }}
              >
                <i className="bi bi-file-earmark-check me-2"></i>SAVE
              </button>
            </div>
          ) : null}
          {!object.invoice_num ? (
            <div className="col-4">
              <button
                type="submit"
                className="btn fs-sm me-3"
                style={{ backgroundColor: "#dfbe68" }}
              >
                <i className="bi bi-file-earmark-check me-2"></i>Create Invoice
              </button>
            </div>
          ) : null}
          <div className="col-4 ">
            <button
              type="button"
              className="btn btn-light fs-sm"
              onClick={back}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoiceQuestion;
