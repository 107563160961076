import { dialog } from "nq-component";
import BaseListPresenter from "../../base/BaseListPresenter";
import { upsertUseCase } from "../../usecases/object";
import classNames from "../../classNames";

function calculateTotalCollectibles(invoices) {
  return invoices.reduce((sum, invoice) => {
    return sum + (invoice.collectibles || 0);
  }, 0);
}
class AccountsPresenter extends BaseListPresenter {
  componentDidMount() {
    this.init();

    return this.getObjects();
  }
  async getObjects() {
    this.reset();
    await this.getPayables();
    await this.countObjects();
    await this.findObjects();
    await this.getInvoices();
  }

  async findObjects() {
    const collection = this.view.getCollectionName();
    const query = this.createQuery();
    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
  onClickAdd() {
    // const collection = this.view.getCollectionName();
    this.view.navigateTo("/accounts/new-transaction");
  }

  async onClickBDOHistory(BDO) {
    const bank = BDO.bdoObject?.name;
    const banker = "bank=" + bank;
    this.view.navigateTo("/accounts/history", banker);
  }

  async onClickBPIHistory(BPI) {
    const bank = BPI.bpiObject?.name;
    const banker = "bank=" + bank;
    this.view.navigateTo("/accounts/history", banker);
  }

  async onClickMBHistory(MB) {
    const bank = MB.metrobankObject?.name;
    const banker = "bank=" + bank;
    this.view.navigateTo("/accounts/history", banker);
  }

  async onClickPJGHistory(PJG) {
    const bank = PJG.pjgAbonoObject?.name;
    const banker = "bank=" + bank;
    this.view.navigateTo("/accounts/history", banker);
  }

  async onClickGMDFundHistory(GMDFund) {
    const bank = GMDFund.gmdFundObject?.name;
    const banker = "bank=" + bank;
    this.view.navigateTo("/accounts/history", banker);
  }

  async onClickGMDRevoHistory(GMDRevo) {
    const bank = GMDRevo.gmdRevolvingObject?.name;
    const banker = "bank=" + bank;
    this.view.navigateTo("/accounts/history", banker);
  }

  handleClose() {
    dialog.close();
    window.location.reload();
  }

  async handleTransfer(fromAccount, toAccount, amount) {
    try {
      const collection = this.view.getCollectionName();
      const query = this.createQuery();
      const objects = await this.findObjectUseCase.execute(collection, query);

      const fromCollectionIndex = objects.findIndex(
        (obj) => obj.name === fromAccount
      );
      const toCollectionIndex = objects.findIndex(
        (obj) => obj.name === toAccount
      );

      if (fromCollectionIndex === -1 || toCollectionIndex === -1) {
        dialog.fire({
          html: (
            <div className="text-end">
              <div className="text-center p-4">
                <i
                  className={classNames(
                    "bi bi-exclamation-circle",
                    "text-warning"
                  )}
                  style={{ fontSize: "5rem" }}
                ></i>
                <h4 className="fw-bold">Warning!</h4>
                <p className="m-0">
                  Source or destination collection not found.
                </p>
                <button
                  className="btn mb-2 mt-2 me-2"
                  style={{ backgroundColor: "#EBBD2F" }}
                  onClick={() => dialog.close()}
                >
                  Confirm
                </button>
              </div>
            </div>
          ),
          footer: false,
        });
        return;
      }

      if (fromAccount === toAccount) {
        dialog.fire({
          html: (
            <div className="text-end">
              <div className="text-center p-4">
                <i
                  className={classNames(
                    "bi bi-exclamation-circle",
                    "text-warning"
                  )}
                  style={{ fontSize: "5rem" }}
                ></i>
                <h4 className="fw-bold">Warning!</h4>
                <p className="m-0">
                  Source and destination accounts cannot be the same.
                </p>
                <button
                  className="btn mb-2 mt-2 me-2"
                  style={{ backgroundColor: "#EBBD2F" }}
                  onClick={() => dialog.close()}
                >
                  Confirm
                </button>
              </div>
            </div>
          ),
          footer: false,
        });
        return;
      }

      const fromBalanceAfterTransfer =
        objects[fromCollectionIndex].balance - amount;
      if (fromBalanceAfterTransfer < 0) {
        dialog.fire({
          html: (
            <div className="text-end">
              <div className="text-center p-4">
                <i
                  className={classNames(
                    "bi bi-exclamation-circle",
                    "text-warning"
                  )}
                  style={{ fontSize: "5rem" }}
                ></i>
                <h4 className="fw-bold">Warning!</h4>
                <p className="m-0">
                  Insufficient balance in the source account.
                </p>
                <button
                  className="btn mb-2 mt-2 me-2"
                  style={{ backgroundColor: "#EBBD2F" }}
                  onClick={() => dialog.close()}
                >
                  Confirm
                </button>
              </div>
            </div>
          ),
          footer: false,
        });
        return;
      }

      objects[fromCollectionIndex].balance -= parseFloat(amount);
      objects[toCollectionIndex].balance += parseFloat(amount);

      objects[fromCollectionIndex].balance = parseFloat(
        objects[fromCollectionIndex].balance
      );
      objects[toCollectionIndex].balance = parseFloat(
        objects[toCollectionIndex].balance
      );

      const transMoneyOut = {
        account: objects[fromCollectionIndex],
        type: "Money Out",
        account_balance: objects[fromCollectionIndex].balance,
        fund_source: objects[toCollectionIndex],
        amounts: parseFloat(amount).toString(),
        note: "Fund Transfer",
      };

      const transMoneyIn = {
        account: objects[toCollectionIndex],
        type: "Money In",
        account_balance: objects[toCollectionIndex].balance,
        fund_source: objects[fromCollectionIndex],
        amounts: parseFloat(amount).toString(),
        note: "Fund Transfer",
      };

      const fundTransfer = {
        bankTo: objects[toCollectionIndex],
        bankFrom: objects[fromCollectionIndex],
        amount: parseFloat(amount),
      };

      await upsertUseCase().execute(
        "gmd_accounts",
        objects[fromCollectionIndex]
      );

      await upsertUseCase().execute(
        "gmd_accounts",
        objects[fromCollectionIndex]
      );
      await upsertUseCase().execute("gmd_accounts", objects[toCollectionIndex]);
      await upsertUseCase().execute("transaction", transMoneyIn);
      await upsertUseCase().execute("transaction", transMoneyOut);
      await upsertUseCase().execute("fund_transfer", fundTransfer);

      await dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames("bi bi-check-circle", "text-warning")}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">Transfer successful!</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={this.handleClose.bind(this)}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });

      // this.view.navigateTo("/accounts");
    } catch (error) {
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames("bi bi-x-circle", "text-warning")}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Failed!</h4>
              <p className="m-0">Error during transfer: {error}</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
    }
  }

  onClickImport(file) {
    this.view.showProgress();
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const csvText = e.target.result;
        const data = this.parseCSV(csvText);
        // this.saveObjects(data);
      } catch (error) {
        console.error("Error processing the CSV file:", error);

        dialog.fire({
          html: (
            <div className="text-end">
              <div className="text-center p-4">
                <i
                  className={classNames(
                    "bi bi-exclamation-circle",
                    "text-warning"
                  )}
                  style={{ fontSize: "5rem" }}
                ></i>
                <h4 className="fw-bold">Warning!</h4>
                <p className="m-0">
                  An error occurred while reading the CSV file.
                </p>
                <button
                  className="btn mb-2 mt-2 me-2"
                  style={{ backgroundColor: "#EBBD2F" }}
                  onClick={() => dialog.close()}
                >
                  Confirm
                </button>
              </div>
            </div>
          ),
          footer: false,
        });
      }
    };
    reader.readAsText(file);
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += `"${array[i][index]}"`;
      }

      str += line + "\r\n";
    }

    return str;
  }

  exportToCSV = async () => {
    const roles = this.view.getCurrentRoles().map((item) => item.id);
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const bankNameFromURL = urlSearchParams.get("bank");
      const specified = bankNameFromURL ? bankNameFromURL + "-" : "";
      const collection = "transaction";
      const query = {
        include: ["all"],
      };
      const objectCSV = await this.findObjectUseCase.execute(collection, query);
      console.warn("objectto", objectCSV);
      const csvData = objectCSV.map((transaction) => ({
        Date: transaction.createdAt,
        Account: transaction.account?.name,
        Note: transaction.note || "n/a",
        Type: transaction.types?.type,
        Amount: transaction.amounts,
        AccountBalance: roles.includes("admin")
          ? "****"
          : transaction.account_balance,
        FundSource: transaction.fund_source?.name
          ? transaction.fund_source?.name
          : transaction.account.name || "n/a",
      }));

      const csvString = this.convertToCSV(csvData);
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = specified + collection + ".csv";
      a.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting to CSV:", error);
    }
  };

  async getPayables() {
    try {
      const collection = "payables";
      const query = {
        include: ["all"],
      };
      const payables = await this.findObjectUseCase.execute(collection);
      console.log("PAYABLES", payables);
      const totalPayables = payables
        .filter((payable) => payable.statuses === "Pending")
        .reduce(
          (sum, payable) =>
            sum +
            (parseFloat(payable?.pending_balance) ||
              parseFloat(payable?.amount)),
          0
        );

      this.view.setPayables(totalPayables);
      console.log("TOTAL PAYABLES", totalPayables);
    } catch (error) {
      console.error("Error fetching payables:", error);
    }
  }

  async getInvoices() {
    const collection = "invoices_final";
    const query = {
      where: {
        statuses: "Approved",
      },
      include: ["all"],
    };
    try {
      const response = await this.findObjectUseCase.execute(collection, query);
      const totalCollectibles = calculateTotalCollectibles(response);
      this.view.setInvoices(totalCollectibles);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  }
}

export default AccountsPresenter;
