import React from "react";
import NavBar from "../../components/navbar";
import { Button, InfiniteScroll, Progress } from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import InputFactory from "../../components/InputFactory";
import DateRange from "../../components/DateRange";

import PoTable from "../../components/PoTable";
import PurchaseOrderPresenter from "./PurchaseOrderListPresenter";
import printComponent from "../../printComponent";
import { browseFile } from "nq";
import PurchaseOrderPrint from "./components/PurchaseOrderPrint;";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

class PurchaseOrderPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new PurchaseOrderPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
      objectPrint: {},
    };
    this.contractPDF = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.id === "admin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({ created_by: { id: user?.id } });
    }
  }
  getCollectionName() {
    return "purchase_orders";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeEmployee(where) {
    this.presenter.onChangeEmployee(where);
  }

  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }
  filterSubmit2(where) {
    console.log(where);
    this.presenter.filterSubmit2(where);
  }

  filterSubmit3(where) {
    this.presenter.filterSubmit3(where);
  }

  onChangeFilter2(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit2(where);
  }

  onChangeFilter3(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit3(where);
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  exportPDF(object) {
    //console.log(this.contractPDF)
    this.setState({ objectPrint: object });
    printComponent(this.contractPDF.current, "Contracts");
  }

  onClickItemDownload(index, field) {
    this.presenter.onClickExport(index);
  }

  // onClickImport() {
  //   this.presenter.onClickImport();
  // }

  onClickImport() {
    browseFile("*").then((files) => {
      if (files.length > 0) {
        const file = files[0];
        this.presenter.onClickImport(file);
      }
    });
  }

  onCollapse(index, object) {
    const role = this.getCurrentRoles();
    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">PO#: </span>
              <span className="text-nowrap">{object.po_num}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Due Date: </span>
              <span className="text-nowrap">{formatDate(object.due_date)}</span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">Client Name: </span>
              <span className="text-nowrap">{object.client_name?.name}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Project Name: </span>
              <span className="text-nowrap">{object.project_name?.name}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Vendor Name: </span>
              <span className="text-nowrap">{object.vendor_name?.name}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Vendor Email: </span>
              <span className="text-nowrap">{object.vendor_email}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Vendor Address: </span>
              <span className="text-nowrap">{object.vendor_address}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Status: </span>
              <span
                className={`text-nowarp fw-bold  ${
                  object.statuses === "Pending"
                    ? "text-main"
                    : object.statuses === "Approved"
                    ? "text-approve"
                    : "text-info "
                }`}
              >
                {object.statuses}
              </span>
            </li>
          </ul>
        </div>
        <>
          {object.statuses === "Pending" ? (
            <>
              <button
                onClick={this.onClickItem.bind(this, index)}
                className="btn btn-primary me-2"
                style={{ backgroundColor: "#EBBD2F", border: 0 }}
              >
                <i class="bi bi-pencil-square"></i> EDIT
              </button>
              <button
                onClick={this.onClickItemDownload.bind(this, index)}
                className="btn btn-primary"
                style={{ backgroundColor: "#EBBD2F", border: 0 }}
              >
                <i class="bi bi-pencil-square"></i> DOWNLOAD PDF
              </button>
            </>
          ) : object.statuses === "Cancelled" ? (
            <></>
          ) : (
            <>
              <button
                onClick={this.onClickItem.bind(this, index)}
                className="btn btn-primary me-2"
                style={{ backgroundColor: "#EBBD2F", border: 0 }}
              >
                VIEW
              </button>
              <button
                onClick={this.onClickItemDownload.bind(this, index)}
                className="btn btn-primary"
                style={{ backgroundColor: "#EBBD2F", border: 0 }}
              >
                <i class="bi bi-pencil-square"></i> DOWNLOAD PDF
              </button>
            </>
          )}
        </>
      </div>
    );
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };
  // exportPDF() {
  //   printComponent(this.contractPDF.current, "Contracts");
  // }
  onClickExport() {
    this.presenter.onClickExport();
  }

  render() {
    //console.log(this.state.selected);
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, total, count, progress } = this.state;
    console.warn("e", objects);
    console.log("objects 1234", objects);
    const user = this.getCurrentRoles();

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="d-none">
          <div ref={this.contractPDF}>
            <PurchaseOrderPrint object={this.state?.objectPrint} />
          </div>
        </div>
        <div className="d-none">
          <div className="" ref={this.contractPDFS}>
            <PoTable
              fields={schema.fields}
              groups={schema.groups}
              objects={objects}
              collapsable
              excludeFields={Object.keys(schema.fields).reduce(
                (acc, key) => {
                  const options = schema.fields[key];
                  if (options.read === false) {
                    acc.push(key);
                  }
                  switch (options._type || options.type) {
                    case "Relation":
                    case "Array":
                    case "Object":
                    case "File":
                      acc.push(key);
                      break;
                    default:
                  }
                  return acc;
                },
                [
                  "acl",
                  "password",
                  "br",
                  "or",
                  "items",
                  "id",
                  // "vendor_email",
                  // "vendor_address",
                  // "vendor_name",
                  // "created_by",
                  "items1",
                  "items2",
                  "items3",
                  "items4",
                  "items5",
                  "items6",
                  "items7",
                  "items8",
                  "items9",
                  "items10",
                  "vendorString",
                  "projectString",
                  "requesteeString",
                ]
              )}
              progress={progress}
              onClickItem={this.onClickItem.bind(this)}
              onCollapse={this.onCollapse.bind(this)}
              className="mt-3 w-100 "
            />
          </div>
        </div>
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">
                  Purchase Orders
                </h1>

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#EBBD2F" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                    <div className="dropdown">
                      <button
                        className="border-0"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-three-dots"></i>
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          className="dropdown-item"
                          role="button"
                          onClick={this.onClickExport.bind(this)}
                        >
                          <i className="bi bi-filetype-pdf"></i> Export PDF
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters2 || {}).map((field) => {
                          let { type, ...options } = schema.filters2[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter2.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters5 || {}).map((field) => {
                          let { type, ...options } = schema.filters5[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter5.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters3 || {}).map((field) => {
                          let { type, ...options } = schema.filters3[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1 "
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter3.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="createdAt"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3 justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  {Object.keys(schema.filters2 || {}).map((field) => {
                    let { type, ...options } = schema.filters2[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter2.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  {Object.keys(schema.filters3 || {}).map((field) => {
                    let { type, ...options } = schema.filters3[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1 "
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter3.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>

                {(user[0].id === "admin" || user[0].id === "masterAdmin") &&
                  selected.length > 0 && (
                    <div className="dropdown">
                      <button
                        className="border-0"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-three-dots"></i>
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          className="dropdown-item"
                          role="button"
                          onClick={this.onClickDeleteSelected.bind(this)}
                        >
                          <i className="bi bi-trash"></i> Delete Selected
                        </li>
                      </ul>
                    </div>
                  )}
              </div>

              <PoTable
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "br",
                    "or",
                    "items",
                    "id",
                    "createdAt",
                    "client_name",
                    "project_name",
                    "vendor_email",
                    "vendor_address",
                    // "vendor_name",
                    "account",
                    "total",
                    "created_by",
                    "items1",
                    "items2",
                    "items3",
                    "items4",
                    "items5",
                    "items6",
                    "items7",
                    "items8",
                    "items9",
                    "items10",
                    "vendorString",
                    "projectString",
                    "requesteeString",
                    "discount",
                    // "createdAt"
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
            <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#EBBD2F",
                }}
              >
                <i className="bi bi-plus" />
              </Button>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default PurchaseOrderPage;
