import BaseFormPresenter from "../../base/BaseFormPresenter";
import { findObjectUseCase } from "../../usecases/object";

class InvoicesFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }
  async submit(e) {
    const invoice = await findObjectUseCase().execute("invoices");
    const mappedServices = [];
    const queryPID = {
      where: {
        id: this.view.props.params.pid,
      },
    };
    const queryCID = {
      where: {
        id: this.view.props.params.cid,
      },
    };

    const pid = await findObjectUseCase().execute("projects", queryPID);
    const cid = await findObjectUseCase().execute("clients", queryCID);
    console.log("pic and cid", pid[0], cid[0], cid[0].email, cid[0].address);

    if (this.view.props.params.pid && this.view.props.params.cid) {
      this.change.project_name = pid[0];
      this.change.client_name = cid[0];
      this.change.client_email = cid[0]?.email;
      this.change.client_address = cid[0]?.address;
    } else if (!this.change.project_name || !this.object.project_name) {
      this.change.project_name = this.view.state.selectedProject;
    }

    let highestInvNo = 0;
    invoice.forEach((item) => {
      if (item.invoice_num && item.invoice_num > highestInvNo) {
        highestInvNo = item.invoice_num;
      }
    });

    this.view.state.services.forEach((service) => {
      const mappedService = {
        id: service.id,
        service: service.service,
        description: service.description,
        name: service.name,
        quantity: service.quantity,
        rate: service.rate,
      };

      mappedServices.push(mappedService);
    });
    mappedServices.forEach((service) => {
      service.invoice_num = this.change.invoice_num;
    });
    const {
      object,
      due_date,
      totalAmount,
      tax,
      asfType,
      discountType,
      subtotal,
    } = this.view.state;

    this.change = {
      invoice_num: highestInvNo + 1,
      due_date: `${due_date}`,
      received: 0,
      amount: parseFloat(totalAmount),
      collectibles: parseFloat(totalAmount),
      estimates: object.estimates,
      notes: object.notes,
      po_num: object.po_num,
      asf: parseFloat(object.asf.replace(/,/g, "")),
      discount: parseFloat(object.discount.replace(/,/g, "")),
      tax: tax,
      asf_type: asfType,
      disc_type: discountType,
      subtotal: subtotal,
      services: mappedServices,
      client_name:
        this.view.props.params.pid && this.view.props.params.cid
          ? cid[0]
          : this.view.state.selectedProject,
      client_email:
        this.view.props.params.pid && this.view.props.params.cid
          ? cid[0]?.email
          : object.client_email,
      client_address:
        this.view.props.params.pid && this.view.props.params.cid
          ? cid[0]?.address
          : object.client_address,
      project_name:
        this.view.props.params.pid && this.view.props.params.cid
          ? pid[0]
          : this.view.state.selectedProject,
    };
    try {
      await this.upsertUseCase.execute("invoices", this.change);

      this.change = {};
      this.view.showSuccess("Successfully saved!");
      if (this.view.props.params.pid && this.view.props.params.cid) {
        await this.view.navigateTo(
          "/view/project/" + this.view.props.params.pid
        );
      } else {
        this.view.navigateTo("/invoices");
      }
    } catch (error) {
      throw error;
    }
  }
}

export default InvoicesFormPresenter;
