import React from "react";
import {
  Button,
  InfiniteScroll,
  Progress,
  Search,
  SelectSearch,
} from "nq-component";
import BaseListPage from "../../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import InputFactory from "../../../components/InputFactory";
import DateRange from "../../../components/DateRange";

import CredTable from "../../../components/CredTable";
import TotalLeaveCreditsPresenter from "./TotalLeaveCreditsPresenter";
import { browseFile } from "nq";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

class TotalLeaveCreditsPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TotalLeaveCreditsPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
    };
    this.contractPDF = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.id === "admin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({});
    }
  }
  getCollectionName() {
    return "users";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }
  filterSubmit2(where) {
    console.log(where);
    this.presenter.filterSubmit2(where);
  }

  filterSubmit3(where) {
    this.presenter.filterSubmit3(where);
  }

  onChangeFilter2(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit2(where);
  }

  onChangeFilter3(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit3(where);
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onCollapse(index, object) {
    const role = this.getCurrentRoles();
    const user = this.getCurrentUser();
    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Employee Name </span>
              <span className="text-nowrap">{object.fullName}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Credits </span>
              <span className="text-nowrap">
                {object.credits !== undefined && object.credits !== 0
                  ? `${object.credits} Credits`
                  : "No Credits"}
              </span>
            </li>

            {/* 
            <li>
              <span className="ms-2 fw-light fw-bold">Remarks </span>
              <span className="text-nowrap">{object.remarks}</span>
            </li> */}
            <li>
              <span className="ms-2 fw-light fw-bold">Date </span>
              <span className="text-nowrap">
                {formatDate(object.updatedAt)}
              </span>
            </li>
          </ul>
        </div>
        {user.roles.some(
          (role) => role.id === "masterAdmin" || role.id === "hrAdmin"
        ) && (
          <button
            onClick={this.onClickItem.bind(this, index)}
            className="btn btn-primary"
            style={{ backgroundColor: "#EBBD2F", border: 0 }}
          >
            <i class="bi bi-pencil-square"></i> EDIT
          </button>
        )}
      </div>
    );
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, count, progress } = this.state;
    const user = this.getCurrentUser();
    if (!schema) return <Progress />;
    return (
      <>
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#EBBD2F" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {/* {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })} */}

                        {Object.keys(schema.filters2 || {}).map((field) => {
                          let { type, ...options } = schema.filters2[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter2.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters5 || {}).map((field) => {
                          let { type, ...options } = schema.filters5[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter5.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters3 || {}).map((field) => {
                          let { type, ...options } = schema.filters3[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1 "
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter3.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="createdAt"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                {/* <Button
                  className="btn m-1"
                  style={{ backgroundColor: "#dab451" }}
                  onClick={this.onClickAdd.bind(this)}
                >
                  <i className="bi bi-plus" /> Create/Add Task
                </Button> */}
              </div>

              <div className="d-md-flex d-none mt-3  justify-content-between align-items-center ">
                <div className="d-flex">
                  {/* {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    );
                  })} */}

                  {Object.keys(schema.filters2 || {}).map((field) => {
                    let { type, ...options } = schema.filters2[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter2.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  {Object.keys(schema.filters3 || {}).map((field) => {
                    let { type, ...options } = schema.filters3[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1 "
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter3.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>
              </div>

              <CredTable
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "br",
                    "or",
                    "items",
                    "id",
                    "client",
                    "project",
                    "location",
                    "description",
                    "created_by",
                    "username",
                    "firstName",
                    "middleName",
                    "lastName",
                    "email",
                    "confirmPassword",
                    "birthday",
                    "contactNumber",
                    "address",
                    "roles",
                    "employeeType",
                    "status",
                    "photo",
                    "hiredDate",
                    "effectiveDate",
                    "sss",
                    "tin",
                    "pagibig",
                    "philhealth",
                    "bankNumber",
                    "departmentHead",
                    "dailyRate",
                    "monthlyRate",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
            {/* {user.roles.some(
              (role) => role.name === "masterAdmin" || role.name === "hrAdmin"
            ) && (
              <div className="position-fixed bottom-0 end-0 m-4">
                <Button
                  className="btn shadow-sm"
                  onClick={this.onClickAdd.bind(this)}
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "25px",
                    backgroundColor: "#EBBD2F",
                  }}
                >
                  <i className="bi bi-plus fs-5" />
                </Button>
              </div>
            )} */}
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default TotalLeaveCreditsPage;
