import React from "react";
import NavBar from "../../../components/navbar";
import { Button, InfiniteScroll, Progress } from "nq-component";
import BaseListPage from "../../../base/BaseListPage";
import {
  countObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import InputFactory from "../../../components/InputFactory";
import DateRange from "../../../components/DateRange";

import Table from "../../../components/Table";
import CashReceiptPresenter from "./CashInReceiptPresenter";
import { browseFile } from "nq";

class CashReceiptPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new CashReceiptPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
      gmdTransfer: {},
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }

  setTransfer(gmdTransfer) {
    this.setState({ gmdTransfer });
  }

  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.id === "admin" || role.id === "masterAdmin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({ created_by: { id: user?.id } });
    }
  }
  getCollectionName() {
    return "transaction";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeEmployee(where) {
    this.presenter.onChangeEmployee(where);
  }

  filterSubmit2(where) {
    console.log(where);
    this.presenter.filterSubmit2(where);
  }

  filterSubmit3(where) {
    this.presenter.filterSubmit3(where);
  }

  onChangeFilter2(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit2(where);
  }

  onChangeFilter3(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit3(where);
  }

  onClick() {
    this.presenter.onClick();
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  onClickExport(
    totalGmdTransferAmount,
    bdoTotalDeposits,
    bpiTotalDeposits,
    metrobankTotalDeposits,
    bdoTotalInterest,
    bpiTotalInterest,
    metrobankTotalInterest
  ) {
    this.presenter.exportToCSV(
      totalGmdTransferAmount,
      bdoTotalDeposits,
      bpiTotalDeposits,
      metrobankTotalDeposits,
      bdoTotalInterest,
      bpiTotalInterest,
      metrobankTotalInterest
    );
  }

  render() {
    const { isOffcanvasOpen, gmdTransfer } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, count, progress } = this.state;

    if (!schema) return <Progress />;

    const filteredTransactions = objects.filter((transaction) =>
      ["BDO Main", "BPI", "Metrobank"].includes(transaction.account?.name)
    );

    const calculateTotals = (transactions) => {
      let totalInterest = 0;
      let totalDeposits = 0;

      transactions.forEach((transaction) => {
        const amount = parseFloat(transaction.amount);
        if (transaction.type === "Money In") {
          totalInterest += amount;
        } else if (transaction.type === "Money Out") {
          totalDeposits += amount;
        }
      });

      return { totalInterest, totalDeposits };
    };

    const bdoTransactions = filteredTransactions.filter(
      (transaction) => transaction.account?.name === "BDO Main"
    );
    const bpiTransactions = filteredTransactions.filter(
      (transaction) => transaction.account?.name === "BPI"
    );
    const metrobankTransactions = filteredTransactions.filter(
      (transaction) => transaction.account?.name === "Metrobank"
    );

    const { totalInterest: bdoTotalInterest, totalDeposits: bdoTotalDeposits } =
      calculateTotals(bdoTransactions);
    const { totalInterest: bpiTotalInterest, totalDeposits: bpiTotalDeposits } =
      calculateTotals(bpiTransactions);
    const {
      totalInterest: metrobankTotalInterest,
      totalDeposits: metrobankTotalDeposits,
    } = calculateTotals(metrobankTransactions);

    const gmdTransferFromTotal = Array.isArray(gmdTransfer)
      ? gmdTransfer.filter(
          (obj) => obj?.bankFrom && obj.bankFrom.name === "GMD Fund"
        )
      : [];

    const totalGmdTransferAmount = gmdTransferFromTotal.reduce((total, obj) => {
      return total + obj.amount;
    }, 0);

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="p-lg-4 d-flex">
                  <h1 className="fw-bold text-capitalize">
                    <span onClick={this.onClick.bind(this)}>
                      <i class="bi bi-chevron-left"></i>
                    </span>
                    Total Cash Receipts
                  </h1>
                </div>

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#EBBD2F" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                    <div className="dropdown">
                      <button
                        className="border-0"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-three-dots"></i>
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          className="dropdown-item"
                          role="button"
                          onClick={this.onClickExport.bind(
                            this,
                            totalGmdTransferAmount,
                            bdoTotalDeposits,
                            bpiTotalDeposits,
                            metrobankTotalDeposits,
                            bdoTotalInterest,
                            bpiTotalInterest,
                            metrobankTotalInterest
                          )}
                        >
                          <i className="bi bi-filetype-csv"></i> Export CSV
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters2 || {}).map((field) => {
                          let { type, ...options } = schema.filters2[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter2.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters5 || {}).map((field) => {
                          let { type, ...options } = schema.filters5[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter5.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters3 || {}).map((field) => {
                          let { type, ...options } = schema.filters3[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1 "
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter3.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="createdAt"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none my-3 justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters2 || {}).map((field) => {
                    let { type, ...options } = schema.filters2[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter2.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  {Object.keys(schema.filters3 || {}).map((field) => {
                    let { type, ...options } = schema.filters3[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1 "
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter3.bind(this, type)}
                        {...options}
                      />
                    );
                  })}

                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>

                <div className="dropdown">
                  <button
                    className="border-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-three-dots"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.onClickExport.bind(
                        this,
                        totalGmdTransferAmount,
                        bdoTotalDeposits,
                        bpiTotalDeposits,
                        metrobankTotalDeposits,
                        bdoTotalInterest,
                        bpiTotalInterest,
                        metrobankTotalInterest
                      )}
                    >
                      <i className="bi bi-filetype-pdf"></i> Export CSV
                    </li>
                  </ul>
                </div>
              </div>

              <table className="table">
                <thead
                  className="bg-warning"
                  style={{ backgroundColor: "#FFFF00" }}
                >
                  <tr>
                    <th scope="col" style={{ backgroundColor: "#DAB451" }}></th>
                    <th scope="col" style={{ backgroundColor: "#DAB451" }}>
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" style={{ fontWeight: "normal" }}>
                      BDO - Deposits
                    </th>
                    <td>{formatCurrency(bdoTotalDeposits)}</td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ fontWeight: "normal" }}>
                      BPI - Deposits
                    </th>
                    <td>{formatCurrency(bpiTotalDeposits)}</td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ fontWeight: "normal" }}>
                      Metrobank - Deposits
                    </th>
                    <td>{formatCurrency(metrobankTotalDeposits)}</td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ fontWeight: "normal" }}>
                      BDO - Interest
                    </th>
                    <td>{formatCurrency(bdoTotalInterest)}</td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ fontWeight: "normal" }}>
                      BPI - Interest
                    </th>
                    <td>{formatCurrency(bpiTotalInterest)}</td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ fontWeight: "normal" }}>
                      Metrobank - Interest
                    </th>
                    <td>{formatCurrency(metrobankTotalInterest)}</td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ fontWeight: "normal" }}>
                      Fund Transfer from GMD Bank
                    </th>
                    <td>{formatCurrency(totalGmdTransferAmount)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default CashReceiptPage;
