import React from "react";
import NavBar from "../../../components/navbar";
import { Button, InfiniteScroll, Progress } from "nq-component";
import BaseListPage from "../../../base/BaseListPage";
import {
  countObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import InputFactory from "../../../components/InputFactory";
import DateRange from "../../../components/DateRange";

import Table from "../../../components/Table";
import CashDisbursementPresenter from "./CashDisbursementPresenter";
import { browseFile } from "nq";

class CashDisbursementPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new CashDisbursementPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
      purchase_order: {},
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }

  setPO(purchase_order) {
    this.setState({ purchase_order });
  }

  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.id === "admin" || role.id === "masterAdmin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({ created_by: { id: user?.id } });
    }
  }
  getCollectionName() {
    return "expense";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeEmployee(where) {
    this.presenter.onChangeEmployee(where);
  }

  filterSubmit2(where) {
    console.log(where);
    this.presenter.filterSubmit2(where);
  }

  filterSubmit3(where) {
    this.presenter.filterSubmit3(where);
  }

  onChangeFilter2(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit2(where);
  }

  onChangeFilter3(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit3(where);
  }

  onClick() {
    this.presenter.onClick();
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  onClickExport(
    salariesWages,
    officeTeam,
    workforce,
    weekOneTotalAmount,
    weekTwoTotalAmount,
    weekThreeTotalAmount,
    weekFourTotalAmount,
    weekFiveTotalAmount,
    totalAmount,
    creditCardBillTotalAmount,
    purchaseOrderTotalAmount
  ) {
    const objectCSV = this.state.objects;
    this.presenter.exportToCSV(
      salariesWages,
      officeTeam,
      workforce,
      weekOneTotalAmount,
      weekTwoTotalAmount,
      weekThreeTotalAmount,
      weekFourTotalAmount,
      weekFiveTotalAmount,
      totalAmount,
      creditCardBillTotalAmount,
      purchaseOrderTotalAmount,
      objectCSV
    );
  }

  render() {
    const { isOffcanvasOpen, objects, purchase_order } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    console.log("schema", objects);
    const projectAmounts = {}; //new

    const weekOne = "Workforce  - Week 1";
    const weekOneObjects = objects.filter(
      (item) => item.chart_accounts?.name === weekOne
    );
    const weekOneTotalAmount = weekOneObjects.reduce(
      (total, item) => total + item.amount,
      0
    );

    const weekTwo = "Workforce  - Week 2";
    const weekTwoObjects = objects.filter(
      (item) => item.chart_accounts?.name === weekTwo
    );
    const weekTwoTotalAmount = weekTwoObjects.reduce(
      (total, item) => total + item.amount,
      0
    );

    const weekThree = "Workforce  - Week 3";
    const weekThreeObjects = objects.filter(
      (item) => item.chart_accounts?.name === weekThree
    );
    const weekThreeTotalAmount = weekThreeObjects.reduce(
      (total, item) => total + item.amount,
      0
    );

    const weekFour = "Workforce  - Week 4";
    const weekFourObjects = objects.filter(
      (item) => item.chart_accounts?.name === weekFour
    );
    const weekFourTotalAmount = weekFourObjects.reduce(
      (total, item) => total + item.amount,
      0
    );

    const weekFive = "Workforce  - Week 5";
    const weekFiveObjects = objects.filter(
      (item) => item.chart_accounts?.name === weekFive
    );
    const weekFiveTotalAmount = weekFiveObjects.reduce(
      (total, item) => total + item.amount,
      0
    );

    const workforce =
      weekOneTotalAmount +
      weekTwoTotalAmount +
      weekThreeTotalAmount +
      weekFourTotalAmount +
      weekFiveTotalAmount;

    const officeTeam20th = "Office Team (20th)";
    const officeTeam20thObjects = objects.filter(
      (item) => item.chart_accounts?.name === officeTeam20th
    );
    const officeTeam20thTotalAmount = officeTeam20thObjects.reduce(
      (total, item) => total + item.amount,
      0
    );

    const officeTeam5th = "Office Team (5th)";
    const officeTeam5thObjects = objects.filter(
      (item) => item.chart_accounts?.name === officeTeam5th
    );
    const officeTeam5thTotalAmount = officeTeam5thObjects.reduce(
      (total, item) => total + item.amount,
      0
    );

    const officeTeam = officeTeam5thTotalAmount + officeTeam20thTotalAmount;
    const salariesWages = workforce + officeTeam;
    const objectsWithRequestNumber = objects.filter((obj) =>
      obj.hasOwnProperty("requestNumber")
    );
    const sortedObjects = objectsWithRequestNumber.sort(
      (a, b) => b.amount - a.amount
    );
    const totalAmount = sortedObjects.reduce(
      (total, obj) => total + obj.amount,
      0
    );

    const opExpense = "OPEX -";
    const opExpenseObjects = objects.filter((item) =>
      item.chart_accounts?.name.toLowerCase().includes(opExpense.toLowerCase())
    );

    const combinedOpExpenseData = opExpenseObjects.reduce((result, obj) => {
      const name = obj.chart_accounts?.name;
      if (!result[name]) {
        result[name] = { amount: obj.amount };
      } else {
        result[name].amount += obj.amount;
      }
      return result;
    }, {});

    const creditCardBill = "Credit Card Bill";
    const creditCardBillObjects = objects.filter(
      (item) => item.chart_accounts?.name === creditCardBill
    );
    const creditCardBillTotalAmount = creditCardBillObjects.reduce(
      (total, item) => total + item.amount,
      0
    );

    const purchaseOrder = "Sent";
    const purchaseOrderArray = Object.values(purchase_order);

    const purchaseOrderObjects = purchaseOrderArray.filter(
      (item) => item.statuses === purchaseOrder
    );

    const vendorTotalMap = new Map();

    purchaseOrderObjects.forEach((item) => {
      const vendorName = item.vendor_name?.name;
      const totalAmount = item.total;

      if (vendorName && totalAmount) {
        if (!vendorTotalMap.has(vendorName)) {
          vendorTotalMap.set(vendorName, totalAmount);
        } else {
          vendorTotalMap.set(
            vendorName,
            vendorTotalMap.get(vendorName) + totalAmount
          );
        }
      }
    });

    const purchaseOrderTotalAmount = purchaseOrderObjects.reduce(
      (total, item) => total + item.total,
      0
    );

    if (!schema) return <Progress />;
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="p-lg-4 d-flex">
                <h1 className="fw-bold text-capitalize">
                  <span onClick={this.onClick.bind(this)}>
                    <i class="bi bi-chevron-left"></i>
                  </span>
                  Total Cash Disbursement
                </h1>
              </div>

              <div className="d-block d-md-none mt-2">
                <div className="d-flex align-items-center ">
                  <i
                    className="bi bi-filter"
                    style={{ fontSize: "25px", color: "#EBBD2F" }}
                    onClick={this.toggleOffcanvas}
                    aria-controls="offcanvasRight"
                  ></i>
                  <div className="dropdown">
                    <button
                      className="border-0"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi bi-three-dots"></i>
                    </button>
                    <ul className="dropdown-menu">
                      <li
                        className="dropdown-item"
                        role="button"
                        onClick={this.onClickExport.bind(
                          this,
                          salariesWages,
                          officeTeam,
                          workforce,
                          weekOneTotalAmount,
                          weekTwoTotalAmount,
                          weekThreeTotalAmount,
                          weekFourTotalAmount,
                          weekFiveTotalAmount,
                          totalAmount,
                          creditCardBillTotalAmount,
                          purchaseOrderTotalAmount
                        )}
                      >
                        <i className="bi bi-filetype-csv"></i> Export CSV
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className={`offcanvas offcanvas-end ${
                    isOffcanvasOpen ? "show" : ""
                  }`}
                  tabIndex="-1"
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                  style={{
                    visibility: isOffcanvasOpen ? "visible" : "hidden",
                  }}
                >
                  <div
                    className="offcanvas-header"
                    style={{ backgroundColor: "#EBBD2F" }}
                  >
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">
                      Filters
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      onClick={this.toggleOffcanvas}
                    ></button>
                  </div>
                  <div className="offcanvas-body">
                    <div className=" mt-3">
                      <DateRange
                        onChange={this.onChangeDate.bind(this)}
                        field="createdAt"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-md-flex d-none my-3 justify-content-between align-items-center ">
              <div className="d-flex">
                <DateRange
                  onChange={this.onChangeDate.bind(this)}
                  field="createdAt"
                />
              </div>

              <div className="dropdown">
                <button
                  className="border-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-three-dots"></i>
                </button>
                <ul className="dropdown-menu">
                  <li
                    className="dropdown-item"
                    role="button"
                    onClick={this.onClickExport.bind(
                      this,
                      salariesWages,
                      officeTeam,
                      workforce,
                      weekOneTotalAmount,
                      weekTwoTotalAmount,
                      weekThreeTotalAmount,
                      weekFourTotalAmount,
                      weekFiveTotalAmount,
                      totalAmount,
                      creditCardBillTotalAmount,
                      purchaseOrderTotalAmount
                    )}
                  >
                    <i className="bi bi-filetype-pdf"></i> Export CSV
                  </li>
                </ul>
              </div>
            </div>

            <table className="table">
              <thead
                className="bg-warning"
                style={{ backgroundColor: "#FFFF00" }}
              >
                <tr>
                  <th scope="col" style={{ backgroundColor: "#DAB451" }}></th>
                  <th
                    scope="col"
                    style={{ backgroundColor: "#DAB451", fontWeight: "normal" }}
                  >
                    Amount{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" style={{ fontWeight: "normal" }}>
                    Salaries and Wages
                  </th>
                  <td>{formatCurrency(salariesWages)}</td>
                </tr>
                <tr>
                  <th scope="row" style={{ fontWeight: "normal" }}>
                    Office Team
                  </th>
                  <td>{formatCurrency(officeTeam)}</td>
                </tr>
                <tr>
                  <th scope="row" style={{ fontWeight: "normal" }}>
                    Workforce
                  </th>
                  <td>{formatCurrency(workforce)}</td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    style={{ fontWeight: "normal" }}
                    className="text-end"
                  >
                    Week 1
                  </th>
                  <td>{formatCurrency(weekOneTotalAmount)}</td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    style={{ fontWeight: "normal" }}
                    className="text-end"
                  >
                    Week 2
                  </th>
                  <td>{formatCurrency(weekTwoTotalAmount)}</td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    style={{ fontWeight: "normal" }}
                    className="text-end"
                  >
                    Week 3
                  </th>
                  <td>{formatCurrency(weekThreeTotalAmount)}</td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    style={{ fontWeight: "normal" }}
                    className="text-end"
                  >
                    Week 4
                  </th>
                  <td>{formatCurrency(weekFourTotalAmount)}</td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    style={{ fontWeight: "normal" }}
                    className="text-end"
                  >
                    Week 5
                  </th>
                  <td>{formatCurrency(weekFiveTotalAmount)}</td>
                </tr>
                <tr>
                  <th scope="row" style={{ fontWeight: "normal" }}>
                    Total Project Expenses
                  </th>
                  <td>{formatCurrency(totalAmount)}</td>
                </tr>
                {sortedObjects.forEach((obj) => {
                  const projectName = obj.project_name.name;
                  const amount = obj.amount;
                  if (projectAmounts.hasOwnProperty(projectName)) {
                    projectAmounts[projectName] += amount;
                  } else {
                    projectAmounts[projectName] = amount;
                  }
                })}
                {Object.keys(projectAmounts).map((projectName, index) => (
                  <tr key={index}>
                    <th
                      scope="row"
                      style={{ fontWeight: "normal" }}
                      className="text-end"
                    >
                      {projectName}
                    </th>
                    <td>{formatCurrency(projectAmounts[projectName])}</td>
                  </tr>
                ))}
                <tr>
                  <th scope="row" style={{ fontWeight: "normal" }}>
                    Operating Expenses
                  </th>
                  <td>
                    {formatCurrency(
                      Object.values(combinedOpExpenseData).reduce(
                        (total, data) => total + data.amount,
                        0
                      )
                    )}
                  </td>
                </tr>
                {Object.entries(combinedOpExpenseData).map(([name, data]) => (
                  <tr key={name}>
                    <th
                      scope="row"
                      style={{ fontWeight: "normal" }}
                      className="text-end"
                    >
                      {name.replace("OPEX -", "")}
                    </th>
                    <td>{formatCurrency(data.amount)}</td>
                  </tr>
                ))}
                <tr>
                  <th scope="row" style={{ fontWeight: "normal" }}>
                    Credit Card Bill
                  </th>
                  <td>{formatCurrency(creditCardBillTotalAmount)}</td>
                </tr>
                <tr>
                  <th scope="row" style={{ fontWeight: "normal" }}>
                    Team Supplier
                  </th>
                  <td>{formatCurrency(purchaseOrderTotalAmount)}</td>
                </tr>
                {[...vendorTotalMap.entries()].map(
                  ([vendorName, totalAmount]) => (
                    <tr key={vendorName}>
                      <th
                        scope="row"
                        style={{ fontWeight: "normal" }}
                        className="text-end"
                      >
                        {vendorName}
                      </th>
                      <td>{formatCurrency(totalAmount)}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default CashDisbursementPage;
