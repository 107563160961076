import React from "react";
import NavBar from "../../components/navbar";
import {
  Button,
  InfiniteScroll,
  Progress,
  Search,
  SelectSearch,
} from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import InputFactory from "../../components/InputFactory";
import DateRange from "../../components/DateRange";

import CliTable from "../../components/CliTable";
import ClientListPresenter from "./ClientListPresenter";
import ClientPrint from "./components/ClientPrint";
import printComponent from "../../printComponent";
import { browseFile } from "nq";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

class ClientListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new ClientListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
    };
    this.contractPDF = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }
  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (role.some((role) => role.id === "admin")) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({});
    }
  }
  getCollectionName() {
    return "clients";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onCollapse(index, object) {
    const role = this.getCurrentRoles();
    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Client Name: </span>
              <span className="text-nowrap">{object.name}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Total Projects: </span>
              <span className="text-nowrap">{object.total_projects}</span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">Total Profit: </span>
              <span className="text-nowrap">{object.total_profit}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Total Expenses: </span>
              <span className="text-nowrap">{object.total_expenses}</span>
            </li>
          </ul>
        </div>
        <button
          onClick={this.onClickItem.bind(this, index)}
          className="btn btn-primary"
          style={{ backgroundColor: "#EBBD2F", border: 0 }}
        >
          <i class="bi bi-pencil-square"></i> EDIT
        </button>
      </div>
    );
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  exportPDF() {
    printComponent(this.contractPDF.current, "Clients");
  }
  onClickExportPDF() {
    this.presenter.onClickExport();
  }
  onClickExport() {
    this.presenter.exportToCSV();
  }

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, total, count, progress } = this.state;
    const user = this.getCurrentUser();
    if (!schema) return <Progress />;

    console.log("IBJJJ", objects);
    return (
      <>
        <NavBar />
        <div className="d-none">
          <div ref={this.contractPDF}>
            {this.state.selected.length > 0 ? (
              <ClientPrint object={selected} />
            ) : (
              <ClientPrint object={objects} />
            )}
          </div>
        </div>
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Clients</h1>

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#EBBD2F" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        <Button
                          className="btn"
                          style={{ backgroundColor: "#d4942a" }}
                          onClick={this.onClickExport.bind(this)}
                        >
                          Export CSV
                        </Button>
                        <Button
                          className="btn"
                          style={{ backgroundColor: "#dab451" }}
                          onClick={this.onClickExportPDF.bind(this)}
                        >
                          <i className="bi bi-filetype-pdf"></i> Export PDF
                        </Button>

                        {user.roles.some(
                          (role) =>
                            role.id === "masterAdmin" || role.id === "admin"
                        ) && this.state.selected.length > 0 ? (
                          <button
                            className="btn btn-danger"
                            aria-expanded="false"
                            onClick={this.onClickDeleteSelected.bind(this)}
                          >
                            <i className="bi bi-trash"></i>Delete
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <Button
                  className="btn m-1"
                  style={{ backgroundColor: "#dab451" }}
                  onClick={this.onClickAdd.bind(this)}
                >
                  <i className="bi bi-plus" /> New Client
                </Button>
              </div>

              <div className="d-md-flex d-none mt-3  justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    );
                  })}
                </div>
                <div className="d-flex align-items-center">
                  {user.roles.some(
                    (role) => role.id === "masterAdmin" || role.id === "admin"
                  ) && this.state.selected.length > 0 ? (
                    <button
                      className="btn btn-danger"
                      aria-expanded="false"
                      onClick={this.onClickDeleteSelected.bind(this)}
                    >
                      <i className="bi bi-trash"></i>Delete
                    </button>
                  ) : (
                    <></>
                  )}
                  <Button
                    className="btn m-1"
                    style={{ backgroundColor: "#cf6f00" }}
                    onClick={this.onClickExport.bind(this)}
                  >
                    Export to CSV
                  </Button>
                  <Button
                    className="btn m-1"
                    style={{ backgroundColor: "#dab451" }}
                    onClick={this.onClickExportPDF.bind(this)}
                  >
                    Export to PDF
                  </Button>
                </div>
              </div>

              <CliTable
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "br",
                    "or",
                    "items",
                    "id",
                    "createdAt",
                    "company_name",
                    "email",
                    "fax",
                    "amount",
                    "amounts",
                    "other",
                    "notes",
                    "website",
                    "billing_address",
                    "shipping_address",
                    "phone_number",
                    "telephone_number",
                    "mobile_number",
                    "project_name",
                    "created_by",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default ClientListPage;
