import BaseListPresenter from "../../../base/BaseListPresenter";
import { upsertUseCase } from "../../../usecases/object";

class OvertimeListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    deleteObjectUseCase
  ) {
    super(view, findObjectUseCase, countObjectUseCase, deleteObjectUseCase);
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  // OVERRIDES

  createQuery() {
    const roles = this.view.getCurrentRoles().map((item) => item.id);
    const currentUser = this.view.getCurrentUser();
    const where = () => {
      if (
        roles.includes("production") ||
        roles.includes("designer") ||
        roles.includes("sales") ||
        roles.includes("admin")
      ) {
        return {
          employee: { id: currentUser.id },
        };
      } else if (roles.includes("hrAdmin") || roles.includes("user")) {
        return {};
      }
    };

    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: { ...this.where, ...this.search, ...this.filter, ...where() },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.include = [
      "employee.roles",
      "projectName.name",
      "approver",
      "approver.roles",
    ];
    this.keys = [
      "employee",
      "nightDifferential",
      "status",
      "totalOvertime",
      "projectName",
      "employeeName",
      "date",
      "approver",
    ];
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  onClickAdd() {
    this.view.navigateTo("/forms/time-sheet");
  }

  onClickItem(index) {
    const newObjects = this.view.modifyObjectBasedOnRole(this.objects);
    const object = newObjects[index];
    this.view.navigateTo("/forms/time-sheet/" + object.id);
  }

  onClickView(index) {
    const newObjects = this.view.modifyObjectBasedOnRole(this.objects);
    const object = newObjects[index];
    this.view.navigateTo("/forms/time-sheet/" + object.id, { view: true });
  }

  // METHODS
  async onApproveClick(object) {
    const collection = this.view.getCollectionName();
    console.log("OBJECT TO APPROVE", object);

    try {
      await upsertUseCase().execute(collection, {
        status: "Approved",
        id: object.id,
      });
      this.view
        .showOvertimeApprovedDialog()
        .then(() => {
          this.getObjects();
        })
        .catch((error) => console.log(error));
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }

  async onDisapproveClick(object) {
    const collection = this.view.getCollectionName();

    try {
      this.view
        .showOvertimeDisapprovedDialog()
        .then(async () => {
          await upsertUseCase().execute(collection, {
            status: "Disapproved",
            id: object.id,
          });
          this.getObjects();
        })
        .catch((error) => console.log(error));
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default OvertimeListPresenter;
