import React from "react";
import MainPagePresenter from "./MainPagePresenter";
import { Menu } from "nq-component";
import { getAllSchemasUseCase } from "../../usecases/schema/usecases";
import { getCurrentUserUseCase, signOutUseCase } from "../../usecases/user";
import { Routes, Route } from "react-router-dom";
// import { OffCanvas } from "nq-component";
import CollectionListPage from "../collection-list/CollectionListPage";
import CollectionFormPage from "../collection-form/CollectionFormPage";
import BasePage from "../../base/BasePage";
import NotFoundPage from "../notfound";
import { Layout, Progress, LogoHolder } from "nq-component";
import MigrationPage from "../migration/MigrationPage";
import AccountPage from "../account/AccountPage";
import RoleFormPage from "../role-form/RoleFormPage";
import withRouter from "../../withRouter";
import DashboardPage from "../dashboard/DashboardPage";
import HooksPage from "../web-hook/HooksPage";
import FunctionPage from "../web-hook/FunctionPage";
import SchemaPage from "../schema/SchemaPage";

import BudgetRequestPage from "../budget-request/BudgetRequestPage";
import BudgetRequestFormPage from "../budget-request/BudgetRequestFormPage";
import LiquidateFormPage from "../budget-request/liquidate/LiquidateFormPage";
import PayablesPage from "../payables/PayablesPage";
import PayablesFormPage from "../payables/PayablesFormPage";
import ExpensePage from "../expenses/ExpensePage";
import ExpenseFormPage from "../expenses/ExpenseFormPage";
import OffCanvas from "../../components/OffCanvas";
import AccountsPage from "../accountModule/AccountsPage";
import PurchaseOrderListPage from "../purchase-orders/PuchaseOrderListPage";
import PurchaseOrderFormPage from "../purchase-orders/PurchaseOrderFormPage";
import VendorAddPage from "../vendor/VendorAddPage";
import CashInPositionPage from "../cash-in-position/CashInPositionPage";
import NewTransactionPage from "../accountModule/new-transaction/NewTransactionPage";
import TransactionPage from "../accountModule/transaction/TransactionPage";
import menus from "./menu.json";
import CashInBankPage from "../cash-in-position/cashInBank/CashInBankPage";
import CashReceiptPage from "../cash-in-position/cashReceipt/CashInReceiptPage";
import CashDisbursementPage from "../cash-in-position/cashDisbursements/CashDisbusementPage";
import CashPaidOutPage from "../cash-in-position/cashPaidOut/CashPaidOutPage";
import Test from "../cash-in-position/Test";
import InvoicesFormPage from "../invoices/InvoicesFormPage";
import InvoicesPage from "../invoices/InvoicesPage";
import EstimatePage from "../estimate/EstimatePage";
import EventFormPage from "../estimate/event/EventFormPage";
import ConstructionFormPage from "../estimate/construction/ConstructionFormPage";
import ClientListPage from "../clientModule/ClientListPage";
import ClientFormPage from "../clientModule/ClientFormPage";
import FabricationFormPage from "../estimate/fabrication/FabricationFormPage";
import EditEstimatePage from "../estimate/EditEstimatePage";

import InvoicesEditPage from "../invoices/InvoicesEditPage";
import TaskListPage from "../tasks/TaskListPage";
import TaskFormPage from "../tasks/TaskFormPage";
import InvoicesEditPayment from "../invoices/InvoicesEditPayment";
import EstimateTable from "../estimate/EstimateTable";

import AnnouncementPage from "../announcement/AnnouncementPage";
import AnnouncementFormPage from "../announcement/AnnouncementFormPage";
import UserListPage from "../user/UserList/UserListPage";
import HolidaysPage from "../holidays/HolidaysPage";
import HolidaysFormPage from "../holidays/HolidaysFormPage";
import CashAdvancePage from "../cash-advance/CashAdvancePage";
import CashAdvanceFormPage from "../cash-advance/CashAdvanceFormPage";
import LeaveCreditsPage from "../leaveCredits/LeaveCreditsPage";
import LeaveCreditsFormPage from "../leaveCredits/LeaveCreditsFormPage";
import TotalLeaveCreditsFormPage from "../leaveCredits/TotalLeaveCredits/TotalLeaveCreditsFormPage";
import LeaveRequestFormPage from "../leaveRequest/LeaveRequestFormPage";
import LeaveRequestPage from "../leaveRequest/LeaveRequestPage";
import OvertimeFormPage from "../overtime/overtimeForm/OvertimeFormPage";
import OvertimeListPage from "../overtime/overtimeList/OvertimeListPage";
import UserFormPage from "../user/UserForm/UserFormPage";
import PayrollListPage from "../payroll/payrollList/PayrollListPage";
import PayrollFormPage from "../payroll/payrollForm/PayrollFormPage";
import EditPayrollPage from "../payroll/editPayroll/EditPayrollPage";
import EditIndividualPayrollPage from "../payroll/editIndividualPayroll/EditIndividualPayrollPage";
import LeaveRequestView from "../leaveRequest/LeaveRequestView";
import EstimateInvoiceFormPage from "../estimate/estimate-invoice/EstimateInvoiceFormPage";
import InvoiceFormPage from "../invoices/estimate-invoice/InvoiceFormPage";
import InvoiceFormEditPage from "../invoices/estimate-invoice-edit/InvoiceFormEditPage";
import ProjectListPage from "../project/ProjectListPage";
import ProjectFormPage from "../project/ProjectFormPage";
import ProjectViewPage from "../project/projectView/ProjectViewPage";
import ProjectTimelineForm from "../project/projectView/ProjectTimelineForm";
import ProjectTaskListPage from "../project/projectView/projectTaskList/ProjectTaskListPage";
import DesignListPage from "../design/DesignListPage";
import DesignFormPage from "../design/DesignFormPage";
import ProjectTabFormPage from "../project/projectView/projectTabs/ProjectTabFormPage";
import ProjectPrintPage from "../project/projectView/projectPrint/ProjectPrintPage";
import SettingsFormPage from "../profileSettings/settingsForm/SettingsFormPage";
import PayslipPage from "../payslip/payslipPage/PayslipPage";
import getMenu from "../getMenu";

class MainPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new MainPagePresenter(
      this,
      getCurrentUserUseCase(),
      signOutUseCase(),
      getAllSchemasUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  onClickSignOut() {
    this.presenter.onClickSignOut();
  }

  onClickMenu(e, item) {
    e.preventDefault();
    this.navigateTo(item.route);
  }

  render() {
    const user = this.getCurrentUser();
    const schemas = this.getSchemas();
    const roles = this.getCurrentRoles();

    console.log("USR", user, schemas, roles);

    if (!user || !schemas || !roles) {
      console.log("Progress");
      return <Progress />;
    }

    const hook = [
      {
        name: "Hooks",
        route: "/hooks",
        icon: "bi bi-person-check",
      },
      {
        name: "Function",
        route: "/function",
        icon: "bi bi-person-check",
      },
    ];

    // const menu = [...menus];
    return (
      <Layout>
        <Layout.Context.Consumer>
          {(value) => (
            <OffCanvas onSetShow={value.setCollapse} show={value.collapsed}>
              <div
                className="offcanvas-body p-0"
                style={{ backgroundColor: "white" }}
              >
                <nav className="">
                  <div
                    className="text-center p-4"
                    style={{ backgroundImage: `url('/bg-gmd.png')` }}
                  >
                    <LogoHolder
                      className="bg-white"
                      textClassName="text-dark"
                      logo={user.picture}
                      name={user.fullName}
                    />
                    <p className="text-dark mt-3">{user.fullName}</p>
                  </div>
                  <hr className="dropdown-divider bg-light" />
                  <div className="m-3">
                    <Menu
                      onClickItem={this.onClickMenu.bind(this)}
                      menus={getMenu(menus, roles[0]?.id)}
                    />
                  </div>
                </nav>
              </div>
              <div className="bg-white">
                <button
                  className="nav-link text-dark btn btn-link m-3"
                  onClick={this.onClickSignOut.bind(this)}
                >
                  <i className="bi bi-power"></i>
                  <span className="ms-2 fw-bold small">Log out</span>
                </button>
              </div>
            </OffCanvas>
          )}
        </Layout.Context.Consumer>
        <main className="vh-100 d-flex flex-column">
          <Routes>
            <Route exact path={"/dash"} element={<DashboardPage />} />
            <Route
              exact
              path={"/collection/:name"}
              element={<CollectionListPage />}
            />
            <Route path={"/collection/roles/form"} element={<RoleFormPage />} />
            <Route
              path={"/collection/roles/form/:id"}
              element={<RoleFormPage />}
            />
            <Route
              path={"/collection/:name/form"}
              element={<CollectionFormPage />}
            />
            <Route
              path={"/collection/:name/form/:id"}
              element={<CollectionFormPage />}
            />

            <Route path={"/migration"} element={<MigrationPage />} />
            <Route path={"/schema"} element={<SchemaPage />} />
            <Route path={"/account"} element={<AccountPage />} />
            <Route path={"/hooks"} element={<HooksPage />} />
            <Route path={"/function"} element={<FunctionPage />} />
            <Route
              exact
              path={"/budget-request"}
              element={<BudgetRequestPage />}
            />
            <Route path={"/form/:name/"} element={<BudgetRequestFormPage />} />
            <Route
              path={"/form/:name/:id"}
              element={<BudgetRequestFormPage />}
            />
            <Route
              path={"/liquidate/form/:id"}
              element={<LiquidateFormPage />}
            />
            <Route path={"/payables"} element={<PayablesPage />} />
            <Route path={"/form/expenses/:id"} element={<PayablesFormPage />} />
            <Route path={"/form/expenses"} element={<PayablesFormPage />} />

            <Route path={"/expenses"} element={<ExpensePage />} />
            <Route path={"/forms/expenses/:id"} element={<ExpenseFormPage />} />
            <Route path={"/forms/expenses"} element={<ExpenseFormPage />} />
            <Route path={"/form/add/vendor"} element={<VendorAddPage />} />
            <Route path="/clients" element={<ClientListPage />} />
            <Route path="/forms/clients/:id" element={<ClientFormPage />} />
            <Route path="/forms/clients" element={<ClientFormPage />} />
            <Route path="/forms/clients" element={<ClientFormPage />} />
            <Route path="/tasks" element={<TaskListPage />} />
            <Route path="/forms/tasks/:id" element={<TaskFormPage />} />
            <Route path="/forms/tasks" element={<TaskFormPage />} />

            <Route
              path={"/purchase-orders"}
              element={<PurchaseOrderListPage />}
            />
            <Route
              path={"/forms/purchase-orders"}
              element={<PurchaseOrderFormPage />}
            />
            <Route
              path={"/forms/purchase-orders/:id"}
              element={<PurchaseOrderFormPage />}
            />
            <Route
              path={"/cash-in-position"}
              element={<CashInPositionPage />}
            />
            <Route
              path={"/cash-in-position/all-banks"}
              element={<CashInBankPage />}
            />
            <Route
              path={"/cash-in-position/cash-receipts"}
              element={<CashReceiptPage />}
            />
            <Route
              path={"/cash-in-position/cash-disbursement"}
              element={<CashDisbursementPage />}
            />
            <Route
              path={"/cash-in-position/cash-paid-out"}
              element={<CashPaidOutPage />}
            />
            <Route path={"/cash-in-position/test"} element={<Test />} />
            <Route path={"/accounts"} element={<AccountsPage />} />
            <Route
              path={"/accounts/new-transaction"}
              element={<NewTransactionPage />}
            />
            <Route
              path={"/accounts/transactions"}
              element={<TransactionPage />}
            />
            <Route path={"/invoices"} element={<InvoicesPage />} />
            <Route path={"/forms/invoices"} element={<InvoicesFormPage />} />
            <Route
              path={"/forms/invoices/edit/:id"}
              element={<InvoicesEditPage />}
            />
            <Route
              path={"/forms/invoices/edit/payments"}
              element={<InvoicesEditPayment />}
            />

            <Route path={"/accounts/history"} element={<TransactionPage />} />
            <Route element={<NotFoundPage />} />

            {/* Project */}

            <Route path={"/estimate"} element={<EstimatePage />} />
            <Route path={"/estimateable"} element={<EstimateTable />} />
            <Route path={"/estimate/edit/:id"} element={<EditEstimatePage />} />
            <Route path={"forms/event"} element={<EventFormPage />} />
            <Route path={"/forms/event/:id"} element={<EventFormPage />} />
            <Route
              path={"/forms/event/:pid/:cid"}
              element={<EventFormPage />}
            />
            <Route
              path={"/forms/construction"}
              element={<ConstructionFormPage />}
            />
            <Route
              path={"/forms/construction/:id"}
              element={<ConstructionFormPage />}
            />
            <Route
              path={"/forms/construction/:pid/:cid"}
              element={<ConstructionFormPage />}
            />
            <Route
              path={"/forms/fabrication"}
              element={<FabricationFormPage />}
            />
            <Route
              path={"/forms/fabrication/:id"}
              element={<FabricationFormPage />}
            />
            <Route
              path={"/forms/fabrication/:pid/:cid"}
              element={<FabricationFormPage />}
            />
            <Route path="/project" element={<ProjectListPage />} />
            <Route path="/forms/project" element={<ProjectFormPage />} />
            <Route path="/forms/project/:id" element={<ProjectFormPage />} />
            <Route
              path="/view/project/:id/:cid/:name"
              element={<ProjectViewPage />}
            />
            <Route
              path="/view/project/print/:id"
              element={<ProjectPrintPage />}
            />
            <Route
              path="/view/project/tasks/"
              element={<ProjectTaskListPage />}
            />
            <Route
              path="/view/project/timeline/:id"
              element={<ProjectTimelineForm />}
            />
            <Route
              path={"/view/project/:name/forms/:pid/:cid"}
              element={<ProjectTabFormPage />}
            />
            <Route
              path={"/view/project/:name/forms/:pid/:cid/:id"}
              element={<ProjectTabFormPage />}
            />

            <Route path="/design/" element={<DesignListPage />} />
            <Route path="/forms/design/" element={<DesignFormPage />} />
            <Route
              path="/forms/design/:pid/:cid"
              element={<DesignFormPage />}
            />
            <Route
              path="/forms/design/:pid/:cid/:pName"
              element={<DesignFormPage />}
            />
            <Route path="/forms/design/:id" element={<DesignFormPage />} />

            {/* Project Tabs */}
            <Route
              path="/view/project/timeline/:id"
              element={<ProjectTimelineForm />}
            />

            {/* Human Resource */}
            <Route path="/announcement" element={<AnnouncementPage />} />
            <Route
              path="/forms/announcement/:id"
              element={<AnnouncementFormPage />}
            />
            <Route
              path="/forms/announcement"
              element={<AnnouncementFormPage />}
            />
            <Route path={"/users"} element={<UserListPage />} />
            <Route path={"/forms/users"} element={<UserFormPage />} />
            <Route path={"/forms/users/:id"} element={<UserFormPage />} />

            <Route path={"/time-sheet"} element={<OvertimeListPage />} />
            <Route path={"/forms/time-sheet"} element={<OvertimeFormPage />} />
            <Route
              path={"/forms/time-sheet/:id"}
              element={<OvertimeFormPage />}
            />

            <Route path="/holidays" element={<HolidaysPage />} />
            <Route path="/forms/holidays/:id" element={<HolidaysFormPage />} />
            <Route path="/forms/holidays" element={<HolidaysFormPage />} />

            <Route path="/cash-advance" element={<CashAdvancePage />} />
            <Route
              path="/forms/cash-advance/:id"
              element={<CashAdvanceFormPage />}
            />
            <Route
              path="/forms/cash-advance"
              element={<CashAdvanceFormPage />}
            />

            <Route path="/leave-credits" element={<LeaveCreditsPage />} />
            <Route
              path="/forms/leave-credits/:id"
              element={<LeaveCreditsFormPage />}
            />
            <Route
              path="/forms/total-leave-credits/:id"
              element={<TotalLeaveCreditsFormPage />}
            />
            <Route
              path="/forms/leave-credits"
              element={<LeaveCreditsFormPage />}
            />
            <Route
              path="/forms/leave-credits"
              element={<LeaveCreditsFormPage />}
            />
            <Route path="/leave-request" element={<LeaveRequestPage />} />
            <Route
              path="/forms/leave-request"
              element={<LeaveRequestFormPage />}
            />
            <Route
              path="/forms/leave-request/:id"
              element={<LeaveRequestFormPage />}
            />
            <Route path={"/payroll"} element={<PayrollListPage />} />
            <Route path={"/forms/payroll"} element={<PayrollFormPage />} />
            <Route path={"/forms/payroll/:id"} element={<EditPayrollPage />} />
            <Route
              path={"/forms/payroll-info/:id"}
              element={<PayrollFormPage />}
            />
            <Route
              path={"/forms/payroll-info-edit/:id"}
              element={<EditIndividualPayrollPage />}
            />
            <Route
              path="/view/leave-request/:id"
              element={<LeaveRequestView />}
            />
            <Route
              path="/create-invoice/:id"
              element={<EstimateInvoiceFormPage />}
            />
            <Route path="/create-invoices" element={<InvoiceFormPage />} />
            <Route
              path="/forms/invoices/:pid/:cid"
              element={<InvoiceFormPage />}
            />
            <Route
              path="/forms/invoices/:pid/:cid/:pName"
              element={<InvoiceFormPage />}
            />
            <Route
              path="/edit-invoices/:id"
              element={<InvoiceFormEditPage />}
            />

            <Route path={"/profile-settings"} element={<SettingsFormPage />} />
            <Route path={"/payslip"} element={<PayslipPage />} />
          </Routes>
        </main>
      </Layout>
    );
  }
}

export default withRouter(MainPage);
