import BaseListPresenter from "../../../base/BaseListPresenter";
import CollapseView from "../components/CollapseView";

class UserListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
    this.fieldsToDisplay = [
      "fullName",
      // "roles",
      "department",
      "employeeType",
      "status",
      "hiredDate",
      "effectiveDate",
    ];
  }

  // OVERRIDES
  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { fullName: 1 };
    this.progress = true;
    this.reset();
  }

  // async findObjects() {
  //   const collection = this.view.getCollectionName();
  //   const query = this.createQuery();

  //   try {
  //     this.showProgress();
  //     this.findObjectUseCase.abort();

  //     const objects = await this.findObjectUseCase.execute(collection, query);
  //     this.objects = this.objects.concat(objects);
  //     this.view.setTotal(this.objects.length);
  //     this.view.setObjects(this.objects);
  //     this.hideProgress();
  //   } catch (error) {
  //     this.hideProgress();
  //     this.view.showError(error);
  //   }
  //   this.progress = false;
  // }
  async findObjects() {
    const collection = this.view.getCollectionName();
    const query = this.createQuery();

    try {
      this.showProgress();

      // Assuming there's a way to track page or offset for infinite scroll
      query.page = this.page || 1; // Track the current page for infinite scroll (if using page)
      query.limit = this.limit || 20; // Example limit for each page

      this.findObjectUseCase.abort();

      const objects = await this.findObjectUseCase.execute(collection, query);

      // Avoid duplicates: only concatenate new objects
      const newObjects = objects.filter(
        (obj) => !this.objects.some((existing) => existing.id === obj.id)
      );
      this.objects = this.objects.concat(newObjects);

      // Set objects and update view
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);

      // Increment the page for the next fetch (if using page-based pagination)
      this.page = (this.page || 1) + 1;

      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  // this.objects = [];
  // this.view.setObjects([]);

  onClickAdd() {
    this.view.navigateTo("/forms/users");
  }

  onClickItem(index) {
    const object = this.objects[index];
    this.view.navigateTo("/forms/users/" + object.id);
  }

  async onClickDeleteSelected() {
    const selected = this.view.getSelected();
    const collection = this.view.getCollectionName();

    try {
      this.view
        .showDeleteUserDialog(selected)
        .then(async () => {
          for (const obj of selected) {
            await this.deleteObjectUseCase.execute(collection, obj.id);
            const index = this.objects.indexOf(obj);
            this.objects.splice(index, 1);
            this.view.setObjects(this.objects);
          }
          this.view.showToast("User has been successfully removed.");
          this.view.setSelected([]);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }

  searchSubmit(search) {
    this.reset();
    this.search = search;
    this.getObjects();
  }

  async toggleStatus(object) {
    const status = object.status;
    const collection = this.view.getCollectionName();

    try {
      if (status === "Active") {
        await this.upsertUseCase.execute(collection, {
          status: "Inactive",
          id: object.id,
        });
        this.view.showToast("User is now inactive");
      } else {
        await this.upsertUseCase.execute(collection, {
          status: "Active",
          id: object.id,
        });
        this.view.showToast("User is now active.");
      }
      this.getObjects();
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }

  // CUSTOM FUNCTIONS

  // Change table label from the default schema label
  formatTableFieldsLabel(fields) {
    return {
      ...fields,
      // roles: {
      //   ...fields.roles,
      //   label: "Department",
      // },
      employeeType: {
        ...fields.employeeType,
        label: "Position",
      },
    };
  }

  // Format table values
  // formatTableObjectValue(objects) {
  //   if (Array.isArray(objects)) {
  //     return objects.map((object) => {
  //       return {
  //         ...object,
  //         roles: object.roles[0],
  //       };
  //     });
  //   } else {
  //     return {
  //       ...objects,
  //       roles: objects.roles?.name,
  //     };
  //   }
  // }

  onCollapse(index, object) {
    const schema = this.view.getSchema(this.view.getCollectionName());
    return (
      <CollapseView
        // object={this.formatTableObjectValue(object)}
        object={object}
        fields={this.formatTableFieldsLabel(schema.fields)}
        includedFields={this.fieldsToDisplay}
        onClickItem={this.onClickItem.bind(this)}
        onToggleStatus={this.toggleStatus.bind(this)}
        index={index}
      />
    );
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] =
          field === "fullName"
            ? { $regex: value, $options: "i" }
            : field === "status" && value
            ? { $eq: value }
            : { $regex: value, $options: "i" };
    }
    this.view.filterSubmit(where);
  }
}

export default UserListPresenter;
