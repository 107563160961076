import BaseFormPresenter from "../../../base/BaseFormPresenter";
import { dialog } from "nq-component";
import classNames from "../../../classNames";
import { findObjectUseCase } from "../../../usecases/object";
class InvoiceFormPresenter extends BaseFormPresenter {
  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  componentDidMount() {
    this.init();

    this.displayData();
    this.getObject();
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    console.log("ididd", id);
    if (id) {
      const params = {
        include: [
          "client_name",
          "name",
          "services",
          "services.questions",
          "services.questions.service",
          "project_name",
        ],
      };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        console.log("ovovov", this.object);
        this.view.hideProgress();
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  displayData() {
    const collection = this.view.getCollectionName();
    const exam = this.view.getExamId();
    const query = {
      where: { id: exam?.id },
      include: [
        "All",
        "services",
        "services.questions",
        // "categories.questions.answers",
        ,
      ],
    };
    console.log("query", query);
    if (exam?.id) {
      this.view.showProgress();
      this.findObjectUseCase
        .execute(collection, query)
        .then(([object]) => {
          this.view.hideProgress();
          this.object = object;
          console.log("edit", this.object);
          this.view.setObject(Object.assign({}, object));
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }
  async approved() {
    const collection = "estimate_forms";
    const id = this.object.id;
    const approved = "Approved";
    const approveAt = this.getCurrentDateFormatted();
    console.log("Approve Date:", approveAt);

    const objectToUpsert = {
      id: id,
      statuses: approved,
      approveAt: approveAt,
    };

    await this.upsertUseCase.execute(collection, objectToUpsert);

    this.view.showSuccessSnackbar("Successfully Approved!");
    await this.view.navigateTo("/estimate");
  }
  async save(updatedObject) {
    const collection = "estimate_forms";
    const id = this.object.id;

    console.log("id:", id);

    try {
      if (Object.values(this.change).length === 0) {
        if (typeof updatedObject.subTotal === "string") {
          updatedObject.subTotal = parseFloat(updatedObject.subTotal);
        }
        if (typeof updatedObject.taxAmount === "string") {
          updatedObject.taxAmount = parseFloat(updatedObject.taxAmount);
        }
        if (typeof updatedObject.total === "string") {
          updatedObject.total = parseFloat(updatedObject.total);
        }

        this.view.showSuccessSnackbar("Changes saved!");
        await this.view.navigateTo("/estimate");

        await this.upsertUseCase.execute(collection, updatedObject);
        return;
      }

      const updatedChange = {};
      const { client_name, project_name, discount, ASF } = this.change;
      if (client_name) {
        updatedChange.client_name = updatedObject.client_name;
      }
      if (project_name) {
        updatedChange.project_name = updatedObject.project_name;
      }
      if (discount) {
        updatedChange.discount = updatedObject.discount || 0;
      }
      if (ASF) {
        updatedChange.ASF = updatedObject.ASF || 0;
      }

      if (Object.keys(updatedChange).length > 0) {
        const updatedObjectWithChanges = {
          ...updatedObject,
          ...updatedChange,
        };

        if (typeof updatedObjectWithChanges.subTotal === "string") {
          updatedObjectWithChanges.subTotal = parseFloat(
            updatedObjectWithChanges.subTotal
          );
        }
        if (typeof updatedObjectWithChanges.taxAmount === "string") {
          updatedObjectWithChanges.taxAmount = parseFloat(
            updatedObjectWithChanges.taxAmount
          );
        }
        if (typeof updatedObjectWithChanges.total === "string") {
          updatedObjectWithChanges.total = parseFloat(
            updatedObjectWithChanges.total
          );
        }

        this.view.showSuccessSnackbar("Changes saved!");
        await this.view.navigateTo("/estimate");

        await this.upsertUseCase.execute(collection, updatedObjectWithChanges);
      }
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }

  async onClickApproved() {
    const collection = "invoices_final";
    const id = this.object.id;
    const approved = "Approved";
    // const approveAt = this.getCurrentDateFormatted();

    const objectToUpsert = {
      id: id,
      statuses: approved,
      // approveAt: approveAt
    };

    await this.upsertUseCase.execute(collection, objectToUpsert);

    this.view.showSuccessSnackbar("Successfully Approved!");
    await this.view.navigateTo("/invoices");
  }
  // async submit() {
  //   const invoice = await findObjectUseCase().execute("invoices_final");
  //   const mappedServices = [];
  //   const queryPID = {
  //     where: {
  //       id: this.view.props.params.pid,
  //     },
  //   };
  //   const queryCID = {
  //     where: {
  //       id: this.view.props.params.cid,
  //     },
  //   };

  //   console.log("PARAMS", this.view.getParams());

  //   const pid = await findObjectUseCase().execute("projects", queryPID);
  //   const cid = await findObjectUseCase().execute("clients", queryCID);

  //   let highestInvNo = 0;
  //   invoice.forEach((item) => {
  //     if (item.invoice_num && item.invoice_num > highestInvNo) {
  //       highestInvNo = item.invoice_num;
  //     }
  //   });
  //   const object = this.view.getObject();
  //   if (object.id) {
  //     this.change.id = object.id;
  //     console.log("asasas", this.change);
  //   } else {
  //     // this.change.acl = this.view.getAcl();
  //     const collection = "services_final";
  //     object.statuses = "Pending";
  //     object.estimate_type = "Fabrication";
  //   }

  //   const collection = "invoices_final";
  //   if (this.view.props.params.pid && this.view.props.params.cid) {
  //     object.project_name = pid[0];
  //     object.client_name = cid[0];
  //   } else if (!this.change.project_name || !this.object.project_name) {
  //     object.project_name = this.view.state.selectedProject;
  //   }
  //   if (!this.change.received || !object.received) {
  //     object.received = 0;
  //   }
  //   object.statuses = "Pending";
  //   object.estimate_type = "Fabrication";
  //   object.due_date = this.view.state.due_date;
  //   // object.collectibles = object.total;
  //   object.collectibles =
  //     // object.total - this.view.state.object.downpaymentAmount ||
  //     // this.change.downpaymentAmount ||
  //     // object.downpaymentAmount ||
  //     // 0;
  //     // object.total - this.view.state.object.downpaymentVal ||
  //     // this.change.downpaymentVal ||
  //     // object.downpaymentVal ||
  //     // 0;
  //     // object.total - this.view.state.object.downpaymentVal ||
  //     // this.change.downpaymentVal ||
  //     // object.downpaymentVal ||
  //     // 0;
  //     parseFloat(object.downpaymentVal.toFixed(2).toLocaleString());
  //   console.log(
  //     "HELLO",
  //     this.change.downpaymentAmount,
  //     this.view.state.object.downpaymentAmount
  //   );
  //   console.log("DPA", object.downpaymentAmount);
  //   object.invoice_num = this.view.getParams().hasOwnProperty("id")
  //     ? object.invoice_num
  //     : highestInvNo + 1;
  //   object.asf = this.view.state.object.asf || 0;
  //   object.discount = this.view.state.object.discount || 0;

  //   let updatedDescriptions = sessionStorage.getItem(
  //     "updatedDescriptionsInvoices"
  //   );

  //   if (updatedDescriptions) {
  //     // Parse the JSON string into an array of objects
  //     updatedDescriptions = JSON.parse(updatedDescriptions);

  //     // Map over the array to extract the descriptions and categoryIndex
  //     object.descriptionArray = updatedDescriptions.map((desc) => {
  //       return {
  //         description: desc.description,
  //         categoryIndex: desc.categoryIndex,
  //       };
  //     });

  //     console.log("UD", updatedDescriptions);
  //   } else {
  //     console.log("No updatedDescriptions found in sessionStorage");
  //   }

  //   await this.upsertUseCase.execute(collection, object);
  //   this.view.submissionSuccess();
  //   this.view.showSuccessSnackbar("Successfully saved!");
  //   this.view.navigateBack();
  // }
  async submit() {
    const object = this.view.getObject();
    if (!object.downpaymentAmount || object.downpaymentAmount === 0) {
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-exclamation-triangle",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Invalid Invoice</h4>
              <p className="m-0">Invoice Amount is required.</p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Okay
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
    } else {
      const invoice = await findObjectUseCase().execute("invoices_final");
      const mappedServices = [];
      const queryPID = {
        where: {
          id: this.view.props.params.pid,
        },
      };
      const queryCID = {
        where: {
          id: this.view.props.params.cid,
        },
      };

      console.log("PARAMS", this.view.getParams());

      const pid = await findObjectUseCase().execute("projects", queryPID);
      const cid = await findObjectUseCase().execute("clients", queryCID);

      let highestInvNo = 0;
      invoice.forEach((item) => {
        if (item.invoice_num && item.invoice_num > highestInvNo) {
          highestInvNo = item.invoice_num;
        }
      });
      const object = this.view.getObject();
      if (object.id) {
        this.change.id = object.id;
        console.log("asasas", this.change);
      } else {
        // this.change.acl = this.view.getAcl();
        const collection = "services_final";
        object.statuses = "Pending";
        object.estimate_type = "Fabrication";
      }

      const collection = "invoices_final";
      if (this.view.props.params.pid && this.view.props.params.cid) {
        object.project_name = pid[0];
        object.client_name = cid[0];
      } else if (!this.change.project_name || !this.object.project_name) {
        object.project_name = this.view.state.selectedProject;
      }
      if (!this.change.received || !object.received) {
        object.received = 0;
      }

      object.statuses = "Pending";
      object.estimate_type = "Fabrication";
      object.due_date = this.view.state.due_date;

      object.collectibles =
        // object.total - this.view.state.object.downpaymentVal ||
        // this.change.downpaymentVal ||
        // object.downpaymentVal ||
        // 0;
        parseFloat(object.downpaymentVal.toFixed(2).toLocaleString());
      console.log(
        "HELLO",
        this.change.downpaymentAmount,
        this.view.state.object.downpaymentAmount
      );
      console.log("DPA", object.downpaymentAmount);
      object.invoice_num = this.view.getParams().hasOwnProperty("id")
        ? // highestInvNo
          object.invoice_num
        : highestInvNo + 1;
      object.asf = this.view.state.object.asf || 0;
      object.discount = this.view.state.object.discount || 0;
      object.tax =
        this.view.state.object.addVAT === true
          ? this.view.state.object.tax || 0
          : 0;

      object.services = object.services.filter(
        (service) => service.__operation !== "REMOVE"
      );

      let updatedDescriptions = sessionStorage.getItem(
        "updatedDescriptionsInvoices"
      );

      if (updatedDescriptions) {
        // Parse the JSON string into an array of objects
        updatedDescriptions = JSON.parse(updatedDescriptions);

        const query = {
          where: {
            id: object.id,
          },
        };

        const response = await findObjectUseCase().execute(collection, query);
        console.log("response: ", response);

        // Initialize the descriptionArray if it's not already there
        if (!object.descriptionArray) {
          object.descriptionArray = [];
        }

        // Iterate over updatedDescriptions and update the corresponding descriptionArray entry based on categoryIndex
        updatedDescriptions.forEach((updatedDesc) => {
          // Find the matching categoryIndex in the existing descriptionArray
          const existingDescIndex = object.descriptionArray.findIndex(
            (desc) => desc.categoryIndex === updatedDesc.categoryIndex
          );

          if (existingDescIndex !== -1) {
            // Update the existing description entry
            object.descriptionArray[existingDescIndex].description =
              updatedDesc.description;
          } else {
            // If categoryIndex doesn't exist, add the new description entry
            object.descriptionArray.push({
              description: updatedDesc.description,
              categoryIndex: updatedDesc.categoryIndex,
            });
          }
        });

        console.log("Updated DescriptionArray", object.descriptionArray);
      } else {
        console.log("No updatedDescriptions found in sessionStorage");
      }

      // Filter out deleted descriptions from descriptionArray
      object.descriptionArray = object.descriptionArray.filter(
        (desc) => !desc.__operation || desc.__operation !== "REMOVE"
      );

      console.log("final send", object);

      await this.upsertUseCase.execute(collection, object);
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
      sessionStorage.removeItem("updatedDescriptionsInvoices");
      this.view.navigateBack();

      // if (updatedDescriptions) {
      //   // Parse the JSON string into an array of objects
      //   updatedDescriptions = JSON.parse(updatedDescriptions);

      //   // Map over the array to extract the descriptions and categoryIndex
      //   // object.descriptionArray = updatedDescriptions.map((desc) => {
      //   //   return {
      //   //     description: desc.description,
      //   //     categoryIndex: desc.categoryIndex,
      //   //   };
      //   // });

      //   object.descriptionArray = updatedDescriptions
      //     .filter((desc) =>
      //       object.services.some(
      //         (service, index) => index === desc.categoryIndex
      //       )
      //     )
      //     .map((desc) => ({
      //       description: desc.description,
      //       categoryIndex: desc.categoryIndex,
      //     }));

      //   console.log("UD", updatedDescriptions);
      // } else {
      //   console.log("No updatedDescriptions found in sessionStorage");
      // }

      // await this.upsertUseCase.execute(collection, object);
      // this.view.submissionSuccess();
      // this.view.showSuccessSnackbar("Successfully saved!");
      // sessionStorage.removeItem("updatedDescriptionsInvoices");
      // this.view.navigateBack();
    }
  }
  async cancel() {
    dialog.fire({
      html: (
        <div className="text-end">
          <div className="text-center p-4">
            <i
              className={classNames("bi bi-emoji-frown", "text-danger")}
              style={{ fontSize: "5rem" }}
            ></i>
            <h4 className="fw-bold">Cancelled!</h4>
            <p className="m-0">Operation has been cancelled.</p>
            <button
              className="btn mb-2 mt-2 me-2"
              style={{ backgroundColor: "#EBBD2F" }}
              onClick={() => dialog.close()}
            >
              Okay
            </button>
          </div>
        </div>
      ),
      footer: false,
    });

    await this.view.navigateTo("/estimate");
  }
}

export default InvoiceFormPresenter;
