import toast from "react-hot-toast";
import BaseListPresenter from "../../../base/BaseListPresenter";
import { aggregateUseCase, findObjectUseCase } from "../../../usecases/object";

class PayrollListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(
      view,
      findObjectUseCase,
      countObjectUseCase,
      upsertUseCase,
      deleteObjectUseCase
    );
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
    this.fieldsToDisplay = ["name", "createdAt", "total", "type", "status"];
  }

  // OVERRIDES

  async getObjects() {
    this.reset();
    await this.countObjects();
    await this.findObjects();
    await this.getHolidays();
    await this.getEmployeePayrollInfo();
  }

  onClickAdd() {
    this.view.navigateTo("/forms/payroll");
  }

  onClickItem(index) {
    const object = this.objects[index];
    this.view.navigateTo("/forms/payroll/" + object.id);
  }

  async getHolidays() {
    try {
      const holidays = await this.findObjectUseCase.execute("holidays", {
        keys: ["date", "type"],
        include: ["type"],
      });
      this.view.setState({ holidays });
    } catch (error) {
      console.log(error);
    }
    this.progress = false;
  }

  countRegularHolidays(startDate, endDate) {
    const holidays = this.view.state.holidays;

    const filteredHolidays = holidays.filter((holiday) =>
      holiday.type.name.includes("Regular Holiday")
    );

    let regularHolidayCount = 0;

    const getMonthDay = (date) => {
      const d = new Date(date);
      return { month: d.getMonth() + 1, day: d.getDate() };
    };

    const start = getMonthDay(startDate);
    const end = getMonthDay(endDate);

    filteredHolidays.forEach((holiday) => {
      const holidayDate = getMonthDay(holiday.date);

      if (
        (holidayDate.month > start.month ||
          (holidayDate.month === start.month &&
            holidayDate.day >= start.day)) &&
        (holidayDate.month < end.month ||
          (holidayDate.month === end.month && holidayDate.day <= end.day))
      ) {
        regularHolidayCount++;
      }
    });

    // holidays.forEach((holiday) => {
    //   const holidayDate = new Date(holiday.date);
    //   const holidayMonth = holidayDate.getMonth() + 1;
    //   const holidayDay = holidayDate.getDate();

    //   const startMonth = new Date(startDate).getMonth() + 1;
    //   const startDay = new Date(startDate).getDate();

    //   const endMonth = new Date(endDate).getMonth() + 1;
    //   const endDay = new Date(endDate).getDate();

    //   if (
    //     holidayMonth === startMonth &&
    //     holidayDay >= startDay &&
    //     holidayMonth === endMonth &&
    //     holidayDay <= endDay
    //   ) {
    //     regularHolidayCount++;
    //   }
    // });

    return regularHolidayCount;
  }

  // async getEmployeePayrollInfo() {
  //   console.log("game", this.objects);
  //   const payrollIds = this.objects.map((payroll) => payroll.id);
  //   console.log("pppp", payrollIds);

  //   const employeePayrolls = await this.findObjectUseCase.execute(
  //     "employee_payroll_info",
  //     { where: { payrollId: { $in: payrollIds } } }
  //   );

  //   console.log("employeePayrolls", employeePayrolls);

  //   const userIds = employeePayrolls.map((emp) => emp.userId);

  //   console.log("userIds", userIds);

  //   const users = await findObjectUseCase().execute("users", {
  //     where: {
  //       id: {
  //         $in: userIds,
  //       },
  //     },
  //   });

  //   // [
  //   //   "030cef0a-ec59-479c-8a01-3799e2b2b779",
  //   //   "77702c4b-d848-444f-9df5-322c3a45fd86",
  //   // ],
  //   console.log("usrs", users);

  //   const userMap = new Map(users.map((user) => [user.id, user]));

  //   let totalHolidayPay = 0;
  //   const newObjects = this.objects.map((payroll) => {
  //     const relatedPayrolls = employeePayrolls.filter(
  //       (emp) => emp.payrollId === payroll.id
  //     );

  //     console.log("relatedPayrolls: ", relatedPayrolls);

  //     let total = 0;
  //     for (const employePayroll of relatedPayrolls) {
  //       const user = userMap.get(employePayroll.userId);

  //       totalHolidayPay =
  //         user?.employeeType === "On call"
  //           ? 0
  //           : this.countRegularHolidays(payroll.startDate, payroll.endDate) *
  //               user?.dailyRate || 0;

  //       total +=
  //         employePayroll.total || user?.monthlyRate / 2 || totalHolidayPay || 0;
  //     }

  //     console.log("total", total);

  //     return {
  //       ...payroll,
  //       total: total,
  //     };
  //   });

  //   this.view.setState({ objects: newObjects });
  // }
  async getEmployeePayrollInfo() {
    console.log("game", this.objects);
    const payrollIds = this.objects.map((payroll) => payroll.id);
    console.log("payrollIds", payrollIds);

    // Fetch employee payroll info by payrollIds
    const employeePayrolls = await this.findObjectUseCase.execute(
      "employee_payroll_info",
      { where: { payrollId: { $in: payrollIds } } }
    );

    console.log("employeePayrolls", employeePayrolls);

    // Extract userIds from employee payrolls
    const userIds = employeePayrolls.map((emp) => emp.userId);
    console.log("userIds", userIds);

    // Batch the userIds query to avoid database limitations
    const chunkSize = 100; // Adjust this based on your system's performance
    const chunkedUserIds = [];
    for (let i = 0; i < userIds.length; i += chunkSize) {
      chunkedUserIds.push(userIds.slice(i, i + chunkSize));
    }

    // Fetch users in chunks
    const userPromises = chunkedUserIds.map((chunk) =>
      findObjectUseCase().execute("users", {
        where: { id: { $in: chunk } },
      })
    );

    const userResults = await Promise.all(userPromises);
    const users = userResults.flat(); // Flatten the array of arrays
    console.log("users", users);

    // Map user data for easy lookup by userId
    const userMap = new Map(users.map((user) => [user.id, user]));

    // Calculate total holiday pay and other payroll calculations
    const newObjects = this.objects.map((payroll) => {
      const relatedPayrolls = employeePayrolls.filter(
        (emp) => emp.payrollId === payroll.id
      );

      console.log("relatedPayrolls: ", relatedPayrolls);

      let total = 0;
      for (const employeePayroll of relatedPayrolls) {
        const user = userMap.get(employeePayroll.userId);

        // Handle missing user scenario gracefully
        if (!user) {
          console.warn(`User not found for userId: ${employeePayroll.userId}`);
          continue;
        }

        // Calculate totalHolidayPay based on employee type
        let totalHolidayPay = 0;
        if (user.employeeType !== "On call") {
          totalHolidayPay =
            this.countRegularHolidays(payroll.startDate, payroll.endDate) *
            user.dailyRate;
        }

        // Calculate total payroll value
        total += employeePayroll.total || totalHolidayPay || 0;
        // total +=
        //   employeePayroll.total || user.monthlyRate / 2 || totalHolidayPay || 0;
      }

      console.log("total", total);

      // Return updated payroll object with total included
      return {
        ...payroll,
        total: total,
      };
    });

    // Update the view state with new payroll objects
    this.view.setState({ objects: newObjects });
  }

  async onClickDeleteSelected() {
    const selected = this.view.getSelected();
    const collection = this.view.getCollectionName();

    try {
      this.view
        .showDeletePayrollDialog(selected)
        .then(async () => {
          for (const obj of selected) {
            toast.loading("Deleting payroll...", { position: "top-right" });
            await this.deleteObjectUseCase.execute(collection, obj.id);
            const employeePayroll = await this.findObjectUseCase.execute(
              "employee_payroll_info",
              { where: { payrollId: obj.id } }
            );

            for (const emp of employeePayroll) {
              await this.deleteObjectUseCase.execute(
                "employee_payroll_info",
                emp.id
              );
            }

            const index = this.objects.indexOf(obj);
            this.objects.splice(index, 1);
            this.view.setObjects(this.objects);
            toast.dismiss();
            toast.success("Payroll has been successfully removed.", {
              position: "top-right",
            });
          }
          this.view.showToast("Payroll has been successfully removed.");
          this.view.setSelected([]);
          this.getObjects();
        })
        .catch((error) => console.log(error));
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }
}

export default PayrollListPresenter;
