import React from "react";
import BaseListPage from "../../../base/BaseListPage";
import Table from "../../../components/Table";
import TransactionTablePresenter from "./TransactionTablePresenter";
import {
  countObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";

class TransactionTablePage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TransactionTablePresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
    };
  }

  onClick() {
    this.presenter.onClick();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getCollectionName() {
    return "transaction";
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  // onCollapse(index, object) {
  //   console.log("ON", object);
  //   const role = this.getCurrentRoles().map((item) => item.name);
  //   const formatCurrency = (amount) => {
  //     return new Intl.NumberFormat("en-US", {
  //       style: "currency",
  //       currency: "PHP",
  //     }).format(amount);
  //   };
  //   return (
  //     <div>
  //       <div className="d-flex">
  //         <ul className="list-unstyled ms-1 text-truncate">
  //           <li>
  //             <span className="ms-2 fw-light fw-bold">Note: </span>
  //             <span className="text-nowrap">{object.note}</span>
  //           </li>
  //           <li>
  //             <span className="ms-2 fw-light fw-bold">
  //               Money In/Money Out:{object?.types?.type}
  //             </span>
  //             <span className="text-nowrap">{object.type}</span>
  //           </li>

  //           <li>
  //             <span className="ms-2 fw-light fw-bold">Account Balance: </span>
  //             <span className="text-nowrap">
  //               {role.includes("masterAdmin")
  //                 ? formatCurrency(object.account_balance)
  //                 : "****"}
  //             </span>
  //           </li>
  //           <li></li>
  //         </ul>
  //       </div>
  //     </div>
  //   );
  // }

  onCollapse(index, object) {
    console.log("ON", object);
    const role = this.getCurrentRoles().map((item) => item.id);

    const formatCurrency = (amount) => {
      if (typeof amount === "number") {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "PHP",
          minimumFractionDigits: 2, // Ensures two decimal places
          maximumFractionDigits: 2,
        }).format(amount);
      }
      return "₱" + amount; // If it's not a number, return as is
    };

    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Note: </span>
              <span className="text-nowrap">{object.note}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">
                Money In/Money Out: {object?.types?.type}
              </span>
              <span className="text-nowrap">{object.type}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Account Balance: </span>
              <span className="text-nowrap">
                {role.includes("masterAdmin")
                  ? formatCurrency(object.account_balance)
                  : "****"}
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const roleName = this.getCurrentRoles().map((item) => item.id);
    const { objects, selected, progress } = this.state;

    const formatCurrency = (amount) => {
      if (typeof amount === "number") {
        return (
          "₱" +
          new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(amount)
        );
      }
      return "₱" + amount;
    };

    // Format account_balance during the render phase
    const updatedObjects = objects.map((object) => ({
      ...object,
      account_balance: formatCurrency(object.account_balance),
    }));
    console.log("updatedObjects", objects);

    return (
      <>
        <div className=" mx-3 mb-5">
          <Table
            fields={schema.fields}
            groups={schema.groups}
            objects={updatedObjects}
            collapsable
            selectable
            excludeFields={Object.keys(schema.fields).reduce(
              (acc, key) => {
                const options = schema.fields[key];
                if (options.read === false) {
                  acc.push(key);
                }
                switch (options._type || options.type) {
                  case "Relation":
                  case "Array":
                  case "Object":
                  case "File":
                    acc.push(key);
                    break;
                  default:
                }
                return acc;
              },
              [
                "acl",
                "id",
                "updatedAt",
                "note",
                "payee",
                "type",
                "types",
                "amount",
                "is_revolving",
                "fund_source",
                "reciept",
                "clients",
                "chart_of_accounts",
              ]
            )}
            selected={selected}
            onSelect={this.onSelect.bind(this)}
            onSelectAll={this.onSelectAll.bind(this)}
            progress={progress}
            onClickItem={this.onClickItem.bind(this)}
            onCollapse={this.onCollapse.bind(this)}
            className="mt-3"
            role={roleName}
          />
          <div className="d-flex justify-content-center align-items-center w-100">
            <button
              className="m-2 btn btn-warning"
              onClick={this.onClick.bind(this)}
            >
              MORE
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default TransactionTablePage;
