import InputFactory from "../../../components/InputFactory";
import TimeSelect from "./TimeSelect";

export default function CreateOvertimeForm({
  fields,
  excludedFields,
  onClickBack,
  onChange,
  object,
  view,
  editObject,
}) {
  const fieldsToDisableWhenEditing = [
    "employeeName",
    "department",
    "employeeType",
    "totalOvertime",
    "nightDifferential",
    "status",
    "projectName",
    "approver",
  ];

  return (
    <div
      className="w-100 p-4 mt-3 rounded"
      style={{ border: "1px solid #EFEFEF" }}
    >
      <div className="col-4 mt-2 p-0">
        {Object.keys(fields).map((field) => {
          const { type, marker, start, end, ...options } = fields[field];

          if (excludedFields.includes(field)) return null;

          return (
            <div className="row m-0 p-0 mb-4" key={field}>
              {marker === "WorkedHours" || marker === "WorkedHour" ? (
                <label
                  class="form-label fw-semibold"
                  style={{ fontSize: "0.875rem", color: "#434343" }}
                >
                  {options.customLabel.split("-")[0]} -
                  <span style={{ color: "#DAB44E" }}>
                    {`${options.customLabel.split("-")[1]} - ${
                      options.customLabel.split("-")[2]
                    }`}
                  </span>
                  {options.required || options._required ? (
                    <span className="text-danger ms-1">*</span>
                  ) : null}
                </label>
              ) : (
                <label
                  class="form-label fw-semibold"
                  style={{ fontSize: "0.875rem", color: "#434343" }}
                >
                  {options.label}
                  {options.required || options._required ? (
                    <span className="text-danger ms-1">*</span>
                  ) : null}
                </label>
              )}
              <div className="col">
                {marker === "WorkedHours" ? (
                  <TimeSelect
                    start={start}
                    end={end}
                    key={field}
                    field={field}
                    onChange={onChange}
                    object={object}
                    view={view}
                  />
                ) : (
                  <InputFactory
                    field={field}
                    key={field}
                    object={object}
                    type={type}
                    {...(field === "employeeType" && {
                      value: editObject?.employee?.employeeType,
                    })}
                    {...(field === "department" && {
                      value: editObject?.employee?.roles[0]?.id,
                    })}
                    {...(field === "projectName" &&
                      view && {
                        value: editObject?.projectName?.name,
                      })}
                    disabled={
                      view || fieldsToDisableWhenEditing.includes(field)
                    }
                    {...(field === "approver" && {
                      where: {
                        roles: {
                          $in: [
                            // { id: "1e591070-0c8e-40e6-b3d3-0ee3ea0980ab" },
                            // { id: "9e71c70a-aabd-4789-8a29-88c70af25cce" },
                            // { id: "376319ca-f478-4ef7-97e5-c8dd8bf68f72" },
                            { id: "user" },
                            { id: "masterAdmin" },
                            { id: "hrAdmin" },
                          ],
                        },
                      },
                    })}
                    onChange={onChange}
                    {...options}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      {object.status === "Approved" ? null : (
        <div className="row mt-5 m-0 gap-3">
          <div className="col-auto p-0">
            <button
              onClick={onClickBack}
              type="button"
              className="btn btn-light fw-semibold"
              style={{ width: "8rem", height: "2.75rem" }}
            >
              CANCEL
            </button>
          </div>
          <div className="col-auto p-0">
            <button
              type="submit"
              className="btn fw-semibold"
              style={{
                background: "#D8AE3F",
                width: "8rem",
                height: "2.75rem",
              }}
            >
              SUBMIT
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
