import React from "react";
import { Progress } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import { restUseCase } from "../../../usecases/rest";
import NavBar from "../../../components/navbar";
import InputFactory from "../../../components/InputFactory";
import InputFactory2 from "../../../components/InputFactory2";
import Drop from "../../../components/DropdownFilter";
import BaseFormPage from "../../../base/BaseFormPage";
import withRouter from "../../../withRouter";
import ExamQuestion from "../../../components/ExamQuestion";
import EstimateInvoiceFormPresenter from "./EstimateInvoiceFormPresenter";
import InvoiceQuestion from "../../../components/InvoiceQuestion";

class EstimateInvoiceFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {
        services: [],
        project_name: "",
        discountType: "",
        discount: null,
        exclusive: "",
        notes: "",
        asfType: "",
        asf: null,
        tax: 0,
        subTotal: 0,
        total: 0,
        notes:
          "1) Inclusive of Labor Fees (Inclusive of Night Differential) 2) Inclusive of Contingency Fee 3) Inclusive of Indirect Cost Fee 4) Inclusive of Value Added Taxes 5) Price Validity: 30 Days after Receiving 6) If the quotation is approved, Quotation Number must be indicated at Purchase Orders (PO) 7) Any alteration of the design and additional items not included in the contract will be billed accordingly. 8) GMD deserves the right to hold, pullout, suspend delivery if payments and other conditions are not met. - GMD Productions has a NO DP & NO Signed Contract = NO Production Policy - We request the following Payment Terms: 50% downpayment upon confirmation of project - Cost is based on specified requirements however additional requirements other than stated above shall be billed separately. - All permits must be c/o client Account Name: GMD PRODUCTIONS INC BDO CHECKING - 012758000370 BPI CHECKING - 6011 04 82 03",
      },
      selectedProject: null,
      filtered: [],
      due_date: {},
      po_num: 0,
    };
    this.presenter = new EstimateInvoiceFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
    this.contractPDF = React.createRef();

    this.handleSubtotalChange = this.handleSubtotalChange.bind(this);
    this.handleTaxChange = this.handleTaxChange.bind(this);
    this.handleTotalChange = this.handleTotalChange.bind(this);
  }

  getCollectionName() {
    return "services_final";
  }

  handleProjectChange = (selectedProjectId) => {
    console.log(
      "handleProjectChange - selected project ID:",
      selectedProjectId
    );
    const selectedProject = this.state.filtered.find(
      (project) => project.id === selectedProjectId
    );
    console.log("handleProjectChange - selected project:", selectedProject);

    if (selectedProject) {
      // this.setState({ selectedProject });
      this.setState((prevState) => ({
        object: {
          ...prevState.object,
          project_name: selectedProject,
        },
      }));
      console.log("Project name selected:", selectedProject.name);
      this.onChange(selectedProject.name, "project_name");
    } else {
      console.log("No project found with ID:", selectedProjectId);
      this.setState({ selectedProject: null });
    }
  };

  getObject() {
    return this.state.object;
  }

  getExamId() {
    return this.getArgument();
  }

  onSubmitForm(e) {
    e.preventDefault();
    this.presenter.submit();
  }

  setObject(object) {
    this.setState({ object });
  }

  async onChange(value, field) {
    console.log("value", value, "field", field);
    if (field === "client_name") {
      const clientName = value.id;
      const projects = await findObjectUseCase().execute("projects");

      const filteredProjects = projects
        .filter((project) => project.client.id === clientName)
        .map((project) => ({
          name: project.name,
          id: project.id,
        }));

      this.setState(
        {
          filtered: filteredProjects,
          selectedProject:
            filteredProjects.length > 0 ? filteredProjects[0] : null,
        },
        () => {}
      );
    } else if (field === "project_name") {
    } else {
      this.presenter.onChange(value, field);
    }
  }

  handleSubtotalChange(newSubtotal) {
    if (this.state.object.subTotal !== newSubtotal) {
      this.setState((prevState) => ({
        object: {
          ...prevState.object,
          subTotal: newSubtotal,
        },
      }));
    }
  }

  handleTaxChange(newTax) {
    if (this.state.object.tax !== newTax) {
      this.setState((prevState) => ({
        object: {
          ...prevState.object,
          tax: newTax,
        },
      }));
    }
  }

  handleTotalChange(newTotal) {
    if (this.state.object.total !== newTotal) {
      this.setState((prevState) => ({
        object: {
          ...prevState.object,
          total: newTotal,
        },
      }));
    }
  }

  onClickApproved(e) {
    e.preventDefault();
    this.presenter.onClickApproved();
  }

  render() {
    const object = this.state.object;
    const selectedProject = this.state.selectedProject;

    const schema = this.getSchema(this.getCollectionName());

    console.log("Objectooooaeteotweg", object);

    if (!schema) return <Progress />;

    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="row align-items-center">
              <div className="col-auto d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-link"
                  style={{ padding: "0" }}
                >
                  <i
                    className="bi bi-arrow-left fs-4"
                    onClick={this.onClickBack.bind(this)}
                    style={{ color: "#DAB451", margin: "0" }}
                  ></i>
                </button>
              </div>

              <div className="col">
                <h1 className="fw-bold mt-3 text-capitalize">
                  {object && object.id ? "Create Invoice" : "Create Invoice"}
                </h1>
              </div>
              <div className="col-auto ml-auto">
                {object && object.id && object.statuses === "Pending" && (
                  <button
                    type="button"
                    className="btn fs-sm text-white"
                    style={{ backgroundColor: "#3FC65C" }}
                    onClick={this.onClickApproved.bind(this)}
                  >
                    <i className="bi bi-file-earmark-check me-2"></i>Approve
                    Estimate
                  </button>
                )}
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-auto d-flex align-items-center">
                <h6
                  className="fw-normal mt-3 text-sm"
                  style={{
                    paddingLeft: "50px",
                    color: "#434343",
                    opacity: "0.5",
                  }}
                >
                  Select service type and enter data to the required fields.
                </h6>
              </div>
              <div className="mt-2 rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  {/* <div className="row" style={{ width: "50%" }}>
                    <div className="col">
                      <label className="form-label">Client Name</label>
                      <InputFactory
                        type="UserInputPointer"
                        dynamic={false}
                        target="clients"
                        field="client_name"
                        object={object}
                        placeholder="Select Client Name"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />
                    </div>
                    <div className="col">
                      <label className="form-label">Project Name</label>
                      <Drop
                        placeholder={
                          selectedProject
                            ? "Select a project"
                            : object.project_name
                        }
                        value={selectedProject ? selectedProject : null}
                        options={this.state.filtered}
                        onChange={(selectedValue) =>
                          this.handleProjectChange(selectedValue)
                        } // Pass the selected value directly
                      />
                    </div>
                  </div> */}
                  <InvoiceQuestion
                    object={object}
                    setObjectState={this.setObject.bind(this)}
                    onChangeService={this.onChange.bind(this)}
                    back={this.onClickBack.bind(this)}
                    onSubtotalChange={this.handleSubtotalChange}
                    onTaxChange={this.handleTaxChange}
                    onTotalChange={this.handleTotalChange}
                    selectedProject={selectedProject}
                    options={this.state.filtered}
                    projectChange={(selectedValue) =>
                      this.handleProjectChange(selectedValue)
                    }
                    onDateChange={(newDate) =>
                      this.setState({ due_date: newDate })
                    }
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(EstimateInvoiceFormPage);
