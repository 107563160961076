import { dialog } from "nq-component";
import BaseFormPresenter from "../../../base/BaseFormPresenter";
import { findObjectUseCase } from "../../../usecases/object";
import classNames from "../../../classNames";

class LiquidateFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
    }).format(amount);
  };

  async save(reim, returns, brTotal, liquiAmount) {
    const collection = this.view.getCollectionName();
    const user = this.view.getCurrentUser();
    console.log("data", this.change);
    console.log("reim", reim);
    console.log("return", returns);

    if (this.object.id) {
      this.change.id = this.object.id;
      this.change.statuses = "Liquidation";
      this.change.reim =
        brTotal < liquiAmount && brTotal !== returns ? reim : 0;
      this.change.forReturn =
        brTotal > liquiAmount && brTotal !== returns ? returns : 0;
    } else {
      this.change.acl = this.view.getAcl();
    }
    console.log("NEW CHNAGE", this.change);
    try {
      await this.upsertUseCase.execute(collection, this.change);

      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit(reim, returns, brTotal, liquiAmount) {
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    try {
      this.view.submitting();
      await this.save(reim, returns, brTotal, liquiAmount);
      this.view.submissionSuccess();
      dialog.fire({
        html: (
          <div className="text-end">
            <div className="text-center p-4">
              <i
                className={classNames(
                  "bi bi-file-earmark-check",
                  "text-warning"
                )}
                style={{ fontSize: "5rem" }}
              ></i>
              <h4 className="fw-bold">Success!</h4>
              <p className="m-0">
                Please wait for the admin to approve your liquidation request.
              </p>
              <button
                className="btn mb-2 mt-2 me-2"
                style={{ backgroundColor: "#EBBD2F" }}
                onClick={() => dialog.close()}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });
      await this.view.navigateTo("/budget-request");

      await this.getObject();
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
  async onClickEdit(reim, status, obj) {
    dialog.close();
    const collection = "budget_request";
    this.change = this.object;
    this.change.statuses = "Liquidation";
    const data = await this.upsertUseCase.execute(collection, this.change);
    // window.location.reload();

    dialog.fire({
      html: (
        <div>
          <div>
            <h5 className=" text-center">
              <i className="bi bi-file-earmark-medical"></i> Updated
              Successfully!
            </h5>
          </div>
          <div className="text-center m-4">
            {/* <button
              className="btn btn-light  btn-sm me-2"
              onClick={() => dialog.close()}
            >
              Cancel
            </button> */}
            <button
              className="btn btn-sm"
              onClick={() => {
                dialog.close(); // Close the dialog
                this.view.navigateTo("/budget-request"); // Navigate to the desired route
              }}
              style={{ backgroundColor: "#EBBD2F", color: "white" }}
            >
              Confirm
            </button>
          </div>
        </div>
      ),
      footer: false,
    });

    // this.view.navigateTo("/budget-request");
  }
  async onClickStatus(reim, status, object) {
    dialog.close();
    const collection = "budget_request";
    const expensesCollection = "expense";
    const expenses = {};
    const gmdFundsObject = await findObjectUseCase().execute("gmd_accounts");
    const combineBal = gmdFundsObject.reduce(
      (total, account) => total + account.balance,
      0
    );
    const gmdFundObject = gmdFundsObject.find(
      (fund) => fund.name === "GMD Revolving Fund"
    );
    console.warn("reim", reim);
    const brTotal = object.totalAmount;

    //console.log("br total", brTotal);
    //console.log("lllll", this.view.state.liquiAmount[0]?.amount);
    const liquiAmount = this.object.items_list
      .map((item) => Number(item.amounts?.replace(/,/g, "")))
      .reduce((acc, prev) => acc + prev, 0);
    const liquiAmountReim = this.view.state.liquiAmount
      .slice(1) // Excludes the first element of the array
      .map((item) => Number(item.amounts.replace(/,/g, "")))
      .reduce((acc, curr) => acc + curr, 0);

    const returnAmount = isNaN(liquiAmount)
      ? brTotal - reim
      : brTotal - liquiAmount;

    const reimBurse = liquiAmountReim;

    if (!gmdFundObject) {
      console.error("GMD Revolving Fund object not found.");
      return;
    }

    const updatedBalance =
      gmdFundObject.balance +
      (brTotal ===
      Number(this.view.state.liquiAmount[0]?.amount?.replace(/,/g, ""))
        ? 0
        : returnAmount) -
      (reimBurse || 0);

    console.log("UB", brTotal);
    console.log(
      "UG",
      Number(this.view.state.liquiAmount[0]?.amount?.replace(/,/g, ""))
    );
    console.log("UW", returnAmount);

    if (updatedBalance < 0) {
      this.view.showError("Insufficient Fund.");
      return;
    }

    gmdFundObject.balance = updatedBalance;

    const originalObject = {
      note: brTotal < liquiAmount ? "Reimbursement" : "For Return",
      amount:
        brTotal ===
        Number(this.view.state.liquiAmount[0]?.amount?.replace(/,/g, ""))
          ? reimBurse
          : returnAmount,
      types: {
        id: "5c3d711b-69ae-413a-9c2f-ea49a11f64b4",
        type: "Money In",
      },

      account: {
        name: "GMD Revolving Fund",
      },

      account_balance: gmdFundObject.balance,
    };

    const obAccount = {
      id: "79f9b3c7-84b1-4a95-9530-efd31953ef72",
      name: "GMD Revolving Fund",
    };

    const obTypes = {
      id:
        brTotal < liquiAmount
          ? "f3a5c4d2-cbd0-4305-9835-5c8c75de59c4"
          : "5c3d711b-69ae-413a-9c2f-ea49a11f64b4",
      type: brTotal < liquiAmount ? "Money Out" : "Money In",
    };

    const desiredObject = {
      note: originalObject.note,
      account: obAccount,
      account_balance:
        brTotal < liquiAmount
          ? combineBal + originalObject.amount
          : combineBal + originalObject.amount,
      amounts: Math.abs(originalObject.amount).toString(),
      types: obTypes,
      project_name: object?.project_name,
    };

    object.statuses =
      status === "Approved"
        ? "Approved"
        : status === "Paid"
        ? "Paid"
        : status === "Disapproved"
        ? "Disapproved"
        : status === "Done"
        ? "Done"
        : "Liquidation";

    console.log(liquiAmount);
    object.totalAmount = isNaN(liquiAmount) ? reim : liquiAmount;

    //object.remark = object.liqui[0].remark
    console.warn("PASS", object);

    try {
      const trans = await this.upsertUseCase.execute(
        "transaction",
        desiredObject
      );
      const data = await this.upsertUseCase.execute(collection, object);
      const expenseData = {
        project_name: object?.project_name,
        client_name: object.client_name,
        amount: isNaN(liquiAmount) ? reim : liquiAmount,
        items: object.items_list,
        requestNumber: object.requestNumber,
        remarkss: object.items_list[0].remark,
        accounts: gmdFundObject,
        chart_accounts: object.items_list[0].chart_of_accounts,
      };

      const expensesData = await this.upsertUseCase.execute(
        expensesCollection,
        expenseData
      );
      const newGmdFunds = await this.upsertUseCase.execute(
        "gmd_accounts",
        gmdFundObject
      );
      // window.location.reload();

      dialog.fire({
        html: (
          <div>
            <div>
              <h5 className=" text-center">
                <i className="bi bi-file-earmark-medical"></i> Updated
                Successfully!
              </h5>
            </div>
            <div className="text-center m-4">
              {/* <button
                className="btn btn-light  btn-sm me-2"
                onClick={() => dialog.close()}
              >
                Cancel
              </button> */}
              <button
                className="btn btn-sm"
                onClick={() => {
                  dialog.close(); // Close the dialog
                  this.view.navigateTo("/budget-request"); // Navigate to the desired route
                }}
                style={{ backgroundColor: "#EBBD2F", color: "white" }}
              >
                Confirm
              </button>
            </div>
          </div>
        ),
        footer: false,
      });

      // this.view.navigateBack();
      // await this.view.navigateTo("/budget-request");

      this.getObject();
    } catch (error) {
      this.view.showError(error);
    }
  }
}

export default LiquidateFormPresenter;
