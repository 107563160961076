import React from "react";
import NavBar from "../../components/navbar";
import { Button, InfiniteScroll, Progress, dialog } from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import InputFactory from "../../components/InputFactory";
import DateRange from "../../components/DateRange";
import CashAdvancePresenter from "./CashAdvancePresenter";
import { browseFile } from "nq";
import CashAdvTable from "../../components/CashAdvTable";
import classNames from "../../classNames";
import ApproveDialogContent from "./components/approveDialog/ApproveDialogContent";
import { ActionButton } from "./components/actionButton/ActionButton";
import DisapproveDialogContent from "./components/disapproveDialog/DisapproveDialogContent";
import MarkPaidDialogContent from "./components/markPaidDialog/MarkPaidDialogContent";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

class CashAdvancePage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new CashAdvancePresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.filterList();
  }

  filterList() {
    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    if (
      role.some((role) => role.id === "hrAdmin" || role.id === "masterAdmin")
    ) {
      this.presenter.filterListing({});
    } else {
      this.presenter.filterListing({ name: { id: user?.id } });
    }
  }

  getCollectionName() {
    return "cash_advance";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeObject(objects, index) {
    this.presenter.onChangeObject(objects, index);
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onSetApprovedAmount(e) {
    this.setState({ amounts: e.target.value });
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] =
          field === "statuses" && value
            ? { $eq: value }
            : { $regex: value, $options: "i" };
    }
    this.filterSubmit(where);
  }

  approve(index, dp) {
    this.presenter.onClickStatus(index, "Approved", dp);
    console.log("dp", dp);
  }

  onChangeFilter2(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    this.filterSubmit2(where);
  }

  filterSubmit2(where) {
    this.presenter.filterSubmit2(where);
  }

  onClickApprove(index, object) {
    console.log("test", object);
    dialog.fire({
      html: (
        <ApproveDialogContent
          object={object}
          onInput={(e) => {
            let value = e.target.value;

            value = value.replace(/[^\d]/g, "");

            if (value === "") {
              value = "0";
            }

            if (value.length > 1 && value[0] === "0") {
              value = value.slice(1);
            }

            while (value.length < 2) {
              value = "0" + value;
            }

            value = value.slice(0, -2) + "." + value.slice(-2);

            value = Number(value).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            });

            e.target.value = value;

            this.onSetApprovedAmount(e);
          }}
          onApprove={() => {
            this.approve(index, this.state.amounts);
            dialog.close();
            console.log("approved", this.state.amounts);
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  onClickDisapprove(index) {
    dialog.fire({
      html: (
        <DisapproveDialogContent
          onDisapprove={() => {
            this.presenter.onClickStatus(index, "Disapproved");
            dialog.close();
          }}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  onClickMarkAsPaid(index, object, dp) {
    dialog.fire({
      html: (
        <MarkPaidDialogContent
          onMarkPaid={() => {
            this.presenter.onClickStatus(index, "Paid", object.amounts);
            dialog.close();
          }}
          object={object}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  onCollapse(index, object) {
    const user = this.getCurrentUser();

    const buttonConfig = [];

    if (object.statuses === "Pending") {
      buttonConfig.push(
        {
          condition: true,
          onClick: this.onClickApprove.bind(this, index, object),
          className: "btn text-white mb-2 mt-2 me-2",
          style: { backgroundColor: "#4aab73", border: 0 },
          iconClass: "bi bi-check",
          label: "Approve",
        },
        {
          condition: true,
          onClick: this.onClickDisapprove.bind(this, index),
          className: "btn btn-danger mb-2 mt-2 me-2",
          style: { border: 0 },
          iconClass: "bi bi-x",
          label: "Disapprove",
        }
      );
    } else if (object.statuses === "Approved") {
      buttonConfig.push({
        condition: true,
        onClick: this.onClickMarkAsPaid.bind(this, index, object),
        className: "btn btn-info mb-2 mt-2 me-2",
        style: { border: 0 },
        iconClass: "bi bi-cash",
        label: "Mark as Paid",
      });
    }

    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">Name: </span>
              <span className="text-nowrap">{object.name.fullName}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">Date: </span>
              <span className="text-nowrap">
                {formatDate(object.createdAt)}
              </span>
            </li>
            {object.statuses !== "Disapproved" &&
              object.statuses !== "Paid" && (
                <li>
                  <span className="ms-2 fw-light fw-bold">Amount: </span>
                  <span className="text-nowrap">₱{object.amounts}</span>
                </li>
              )}
            <li>
              <span className="ms-2 fw-light fw-bold">Status: </span>
              <span
                className={`text-nowarp fw-bold ${
                  object.statuses === "Cancelled"
                    ? "text-primary"
                    : object.statuses === "Approved"
                    ? "text-success"
                    : object.statuses === "Disapproved"
                    ? "text-danger"
                    : object.statuses === "Pending"
                    ? "text-warning"
                    : object.statuses === "Paid"
                    ? ""
                    : ""
                }`}
                style={object.statuses === "Paid" ? { color: "#CF6F00" } : {}}
              >
                {object.statuses}
              </span>
            </li>
          </ul>
        </div>

        <div>
          {user.roles.some(
            (role) => role.id === "hrAdmin" || role.id === "masterAdmin"
          ) && (
            <>
              {object.statuses === "Approved" && (
                <div>
                  <p
                    className="ms-2 fw-light fw-bold mb-0"
                    style={{ fontSize: "15px" }}
                  >
                    This Cash Advance is approved.
                  </p>
                  <span className="ms-2 fw-light" style={{ fontSize: "15px" }}>
                    Mark it as Paid when creating the employee's next payroll
                    under the "CA Request" column.
                  </span>
                </div>
              )}
              <div>
                {buttonConfig.map(
                  (btn, idx) =>
                    btn.condition && (
                      <ActionButton
                        key={idx}
                        onClick={btn.onClick}
                        className={btn.className}
                        style={btn.style}
                        iconClass={btn.iconClass}
                        label={btn.label}
                      />
                    )
                )}
              </div>
            </>
          )}

          {object.statuses === "Pending" && (
            <>
              {!user.roles.some(
                (role) => role.id === "hrAdmin" || role.id === "masterAdmin"
              ) && (
                <button
                  onClick={this.onClickItem.bind(this, index)}
                  className="btn btn-primary mb-2 mt-2 me-2"
                  style={{ backgroundColor: "#EBBD2F", border: 0 }}
                >
                  View
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  render() {
    const { isOffcanvasOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, total, count, progress } = this.state;
    const user = this.getCurrentUser();

    console.log("Object", objects);

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Cash Advance</h1>

                <div className="d-block d-md-none mt-2">
                  <div className="d-flex align-items-center ">
                    <i
                      className="bi bi-filter"
                      style={{ fontSize: "25px", color: "#EBBD2F" }}
                      onClick={this.toggleOffcanvas}
                      aria-controls="offcanvasRight"
                    ></i>
                  </div>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}
                        {Object.keys(schema.filters2 || {}).map((field) => {
                          let { type, ...options } = schema.filters2[field];

                          return (
                            <div className="mb-2 pe-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter2.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        <DateRange
                          onChange={this.onChangeDate.bind(this)}
                          field="createdAt"
                        />
                        {user.roles.some(
                          (role) =>
                            role.id === "admin" ||
                            role.id === "hrAdmin" ||
                            role.id === "masterAdmin" ||
                            role.id === "Production"
                        ) && this.state.selected.length > 0 ? (
                          <button
                            className="btn btn-danger"
                            aria-expanded="false"
                            onClick={this.onClickDeleteSelected.bind(this)}
                          >
                            <i className="bi bi-trash"></i>Delete
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3 justify-content-between align-items-center ">
                <div className="d-flex">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];

                    return (
                      <div className="mb-2">
                        <InputFactory
                          key={field}
                          className="ms-1"
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter.bind(this, type)}
                          {...options}
                        />
                      </div>
                    );
                  })}
                  {Object.keys(schema.filters2 || {}).map((field) => {
                    let { type, ...options } = schema.filters2[field];

                    return (
                      <div className="mb-2 pe-2">
                        <InputFactory
                          key={field}
                          className="ms-1"
                          type={type}
                          field={field}
                          where={{}}
                          onChange={this.onChangeFilter2.bind(this, type)}
                          {...options}
                        />
                      </div>
                    );
                  })}

                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>
                {user.roles.some(
                  (role) => role.id === "hrAdmin" || role.id === "masterAdmin"
                ) && this.state.selected.length > 0 ? (
                  <button
                    className="btn btn-danger"
                    aria-expanded="false"
                    onClick={this.onClickDeleteSelected.bind(this)}
                  >
                    <i className="bi bi-trash"></i>Delete
                  </button>
                ) : (
                  <></>
                )}
              </div>

              <CashAdvTable
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                excludeFields={Object.keys(schema.fields).reduce((acc, key) => {
                  const options = schema.fields[key];
                  if (options.read === false) {
                    acc.push(key);
                  }
                  switch (options._type || options.type) {
                    case "Relation":
                    case "Array":
                    case "Object":
                    case "File":
                      acc.push(key);
                      break;
                    default:
                  }
                  return acc;
                  // }, ["acl", "password", "br", "or", "items", "id", "description"].concat(user.roles.some((role) => role.name !== "hrAdmin" && role.name !== "masterAdmin") ? ["name"] : []))}
                }, ["acl", "password", "br", "or", "items", "id", "description", "deduction"].concat(user.roles.some((role) => role.id !== "hrAdmin" && role.id !== "masterAdmin") ? ["name"] : []))}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>

            <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#EBBD2F",
                }}
              >
                <i className="bi bi-plus" />
              </Button>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default CashAdvancePage;
