import React from "react";
import NavBar from "../../components/navbar";
import { Button, InfiniteScroll, Progress } from "nq-component";
import BaseListPage from "../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
  getObjectUseCase,
  updateObjectUseCase,
} from "../../usecases/object";
import InputFactory from "../../components/InputFactory";
import DateRange from "../../components/DateRange";
import PayablesPresenter from "./PayablesPresenter";
import Table from "../../components/Table";
import CliTable from "../../components/CliTable";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

class PayablesPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new PayablesPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase(),
      getObjectUseCase(),
      updateObjectUseCase()
    );
    this.state = {
      isOffcanvasOpen: false,
      objects: [],
      selected: [],
      progress: true,
      total: 0,
      count: 0,
    };
  }

  getCollectionName() {
    return "payables";
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if objects have been populated and pending_balance not initialized
    if (prevState.objects.length === 0 && this.state.objects.length > 0) {
      const objectsWithPendingBalance = this.state.objects.map((obj) => {
        // Check if pending_balance is already initialized
        if (obj.pending_balance === undefined) {
          // If not initialized, set a default value
          return {
            ...obj,
            pending_balance: obj.default_pending_balance || obj.amount,
            // Use obj.default_pending_balance as the default value if provided, otherwise use obj.amount
          };
        } else {
          // If already initialized, leave it unchanged
          return obj;
        }
      });
      this.setState({ objects: objectsWithPendingBalance });
    }
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeEmployee(where) {
    this.presenter.onChangeEmployee(where);
  }

  filterSubmit2(where) {
    this.presenter.filterSubmit2(where);
  }

  filterSubmit3(where) {
    this.presenter.filterSubmit3(where);
  }
  filterSubmit10(where) {
    this.presenter.filterSubmit10(where);
  }

  onChangeFilter2(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      case "String":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit2(where);
  }

  onChangeFilter3(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit3(where);
  }
  onChangeFilter10(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }

    console.log("WHERE", where);
    this.filterSubmit10(where);
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickApprove(index) {
    this.presenter.onClickStatus(index, "approved");
  }

  onClickLiquidation(index) {
    this.presenter.onClickStatus(index, "liquidation");
  }

  onClickApproveLiquidation(index) {
    this.presenter.onClickStatus(index, "paid");
  }

  onClickDisapproved(index) {
    this.presenter.onClickStatus(index, "disapproved");
  }

  onClickLiquidate(index, field) {
    this.presenter.onClickLiquidate(index, field);
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }
  onClickPaid(index) {
    const { objects } = this.state;
    this.presenter.onClickPaid(index, objects);
  }
  onClickPartPaid(index) {
    const { objects } = this.state;
    if (objects) {
      this.presenter.onClickPartPaid(index, objects);
    } else {
      console.error("Objects are undefined.");
    }
  }

  onCollapse(index, object) {
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    return (
      <div>
        <div className="d-flex">
          <ul className="list-unstyled ms-1 text-truncate">
            <li>
              <span className="ms-2 fw-light fw-bold">DUE DATE: </span>
              <span className="text-nowrap">{formatDate(object.due_date)}</span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">ITEMS: </span>
              <span className="text-nowrap">
                {object.items.map((i) => (
                  <>
                    <ul>
                      <li>
                        <span>
                          {i.name} -{" "}
                          {formatCurrency(Number(i.amounts?.replace(/,/g, "")))}
                          {console.warn(object)}
                        </span>
                      </li>
                    </ul>
                  </>
                ))}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-light fw-bold">ACCOUNT: </span>
              <span className=" text-nowrap">{object.accounts.name}</span>
            </li>

            <li>
              <span className="ms-2 fw-light fw-bold">AMOUNT: </span>
              <span className=" text-nowrap">
                {formatCurrency(object.amount)}
              </span>
            </li>

            {object.chart_accounts && (
              <li>
                <span className="ms-2 fw-light fw-bold">
                  CHART OF ACCOUNT:{" "}
                </span>
                <span className="text-nowrap">
                  {object.chart_accounts?.name}
                </span>
              </li>
            )}
            {object.requestNumber && (
              <li>
                <span className="ms-2 fw-light fw-bold">
                  BUDGET REQUEST NUMBER:{" "}
                </span>
                <span className="text-nowrap">{object.requestNumber}</span>
              </li>
            )}
            {object.purchaseOrderNumber && (
              <li>
                <span className="ms-2 fw-light fw-bold">
                  PURCHASE ORDER NUMBER:{" "}
                </span>
                <span className="text-nowrap">
                  {object.purchaseOrderNumber}
                </span>
              </li>
            )}

            {object.remark && (
              <li>
                <span className="ms-2 fw-light fw-bold">REMARKS: </span>
                <span className="text-nowrap">{object.remarks}</span>
              </li>
            )}
          </ul>
        </div>
        <>
          <h1>{object.remark}</h1>
          <button
            onClick={this.onClickItem.bind(this, index)}
            className="btn btn-primary btn-sm"
          >
            EDIT
          </button>
          <button
            onClick={this.onClickPartPaid.bind(this, index)}
            className="btn  btn-sm ms-2 "
            style={{ backgroundColor: "#F2E78A", color: "#000000" }}
          >
            PARTIAL PAID
          </button>
          <button
            onClick={this.onClickPaid.bind(this, index)}
            className="btn  btn-sm ms-2 "
            style={{ backgroundColor: "#EBBD2F", color: "BLACK" }}
          >
            FULLY PAID
          </button>
        </>
      </div>
    );
  }

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  render() {
    const user = this.getCurrentRoles();
    const schema = this.getSchema(this.getCollectionName());

    const { objects, selected, total, count, progress } = this.state;

    const { isOffcanvasOpen } = this.state;

    console.log("OBJECT", objects);

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects?.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold mt-3 text-capitalize">Payables</h1>
                <div className="d-block d-md-none mt-2">
                  <i
                    className="bi bi-filter"
                    style={{ fontSize: "25px", color: "#EBBD2F" }}
                    onClick={this.toggleOffcanvas}
                    aria-controls="offcanvasRight"
                  ></i>

                  <div
                    className={`offcanvas offcanvas-end ${
                      isOffcanvasOpen ? "show" : ""
                    }`}
                    tabIndex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{
                      visibility: isOffcanvasOpen ? "visible" : "hidden",
                    }}
                  >
                    <div
                      className="offcanvas-header"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Filters
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.toggleOffcanvas}
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <div className=" mt-3">
                        {Object.keys(schema.filters || {}).map((field) => {
                          let { type, ...options } = schema.filters[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters2 || {}).map((field) => {
                          let { type, ...options } = schema.filters2[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1"
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter2.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}

                        {Object.keys(schema.filters3 || {}).map((field) => {
                          let { type, ...options } = schema.filters3[field];

                          return (
                            <div className="mb-2">
                              <InputFactory
                                key={field}
                                className="ms-1 "
                                type={type}
                                field={field}
                                where={{}}
                                onChange={this.onChangeFilter3.bind(this, type)}
                                {...options}
                              />
                            </div>
                          );
                        })}
                        <div className="">
                          {" "}
                          <DateRange
                            onChange={this.onChangeDate.bind(this)}
                            field="due_date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-md-flex d-none mt-3">
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];

                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}
                {Object.keys(schema.filters10 || {}).map((field) => {
                  let { type, ...options } = schema.filters10[field];

                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}

                {Object.keys(schema.filters2 || {}).map((field) => {
                  let { type, ...options } = schema.filters2[field];

                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter2.bind(this, type)}
                      {...options}
                    />
                  );
                })}

                {Object.keys(schema.filters3 || {}).map((field) => {
                  let { type, ...options } = schema.filters3[field];

                  return (
                    <InputFactory
                      key={field}
                      className="ms-1 "
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter3.bind(this, type)}
                      {...options}
                    />
                  );
                })}

                <DateRange
                  onChange={this.onChangeDate.bind(this)}
                  field="due_date"
                />
                {(user[0].id === "admin" || user[0].id === "masterAdmin") &&
                  selected.length > 0 && (
                    <div className="dropdown">
                      <button
                        className="border-0"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-three-dots"></i>
                      </button>
                      <ul className="dropdown-menu">
                        <li
                          className="dropdown-item"
                          role="button"
                          onClick={this.onClickDeleteSelected.bind(this)}
                        >
                          <i className="bi bi-trash"></i> Delete Selected
                        </li>
                      </ul>
                    </div>
                  )}
              </div>
              <CliTable
                fields={schema.fields}
                groups={schema.groups}
                objects={objects}
                collapsable
                selectable
                tableHeadBackground="#DAB44E"
                shadow={false}
                striped={false}
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "br",
                    "statuses",
                    "requestNumber",
                    // "project_name",
                    "accounts",
                    "chart_accounts",
                    "remarks",
                    "items",
                    "purchaseOrderNumber",
                    "client_name",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
            <div className="position-fixed bottom-0 end-0 m-4">
              <Button
                className="btn shadow-sm"
                onClick={this.onClickAdd.bind(this)}
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "25px",
                  backgroundColor: "#EBBD2F",
                }}
              >
                <i className="bi bi-plus" />
              </Button>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default PayablesPage;
