import React from "react";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import withRouter from "../../../withRouter";
import BaseFormPage from "../../../base/BaseFormPage";
import NavBar from "../../../components/navbar";
import { restUseCase } from "../../../usecases/rest";
import InputFactory from "../../../components/InputFactory";
import NewTransactionPresenter from "./NewTransactionPresenter";
import InputFactory2 from "../../../components/InputFactory2";
import Drop from "../../../components/DropdownFilter";
class NewTransactionPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      showSourceOfFunds: false,
      advanced: false,
      selectedProject: null,
      filtered: [],
    };

    this.presenter = new NewTransactionPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
    this.contractPDF = React.createRef();
  }

  getCollectionName() {
    return "transaction";
  }
  getCollectionExpense() {
    return "expense";
  }

  async onChange(value, field) {
    if (field === "clients") {
      //console.log(value.id)
      const clientName = value.id;
      const proj = await findObjectUseCase().execute("projects");
      console.warn("proj", proj);
      console.warn("id", clientName);
      const filteredProjects = proj
        .filter((project) => project.client?.id === clientName)
        .map((project) => ({
          name: project.name,
          id: project.id,
        }));
      this.setState({
        filtered: filteredProjects,
        selectedProject:
          filteredProjects.length > 0 ? filteredProjects[0] : null,
      });
    } else if (field === "project_name" && !value) {
      this.setState({ filtered: [], selectedProject: null });
    } else if (Array.isArray(value)) {
      this.setState({ itemsAmount: value });
    }

    this.presenter.onChange(value, field);
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:admin", "role:masterAdmin"],
      write: [user.id, "role:admin", "role:masterAdmin"],
    };
  }

  handleCheckboxChange = (event) => {
    this.setState({
      showSourceOfFunds: event.target.checked,
    });
  };
  handleProjectChange = (value) => {
    const selectedProject = this.state.filtered.find(
      (project) => project.id === value
    );
    this.setState({ selectedProject: selectedProject || null });
  };

  displayCurrentBalance(balance, formatCurrency, variant = "balance") {
    const property = variant === "balance" ? "account" : "fund_source";
    console.log("BALANCE", balance);

    if (!this.state.object.hasOwnProperty(property)) {
      return "";
    }

    const role = this.getCurrentRoles().map((item) => item.id);

    if (role.includes("masterAdmin")) {
      return formatCurrency(balance);
    } else {
      const object =
        variant === "balance"
          ? this.state.object.account.name
          : this.state.object.fund_source.name;
      if (object === "GMD Fund" || object === "GMD Revolving Fund") {
        return formatCurrency(balance);
      } else {
        return "****";
      }
    }
  }

  render() {
    const { object, selectedProject } = this.state;
    console.log("Object", object);
    console.log("acc", object.account?.balance);

    const user = this.getCurrentUser();
    const role = this.getCurrentRoles();
    const schema = this.getSchema(this.getCollectionName());

    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    const indicator =
      object.account?.name === undefined
        ? false
        : object.types?.type === "Money Out" &&
          object.account?.name === "GMD Revolving Fund"
        ? false
        : object.account?.name === "GMD Revolving Fund"
        ? true
        : false;
    const balance = object.account?.balance;
    const fund = object?.fund_source?.balance;
    console.log("fund fund", fund);
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };

    return (
      <>
        <div className="d-none" ref={this.contractPDF}></div>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />

        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              {role.some((role) => role.id === "admin") ? (
                <h1
                  className="fw-bold mt-3 text-capitalize"
                  onClick={this.onClickBack.bind(this)}
                >
                  <i className="bi bi-chevron-left"></i>{" "}
                  {label + (schema.label || this.getCollectionName())}
                </h1>
              ) : (
                <h1 className="fw-bold mt-3 text-capitalize">
                  {label + (schema.label || this.getCollectionName())}
                </h1>
              )}

              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3 py-4">
                    <div className="mb-3">
                      <label className="form-label">Date</label>
                      <InputFactory
                        type="Date"
                        field="createdAt"
                        object={object}
                        // placeholder="Add Due Date"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        // min={currentDate}
                        // disabled={
                        //   object.statuses === "Approved" ||
                        //   object.statuses === "Sent" ||
                        //   object.statuses === "Paid"
                        // }
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Client:</label>
                      <InputFactory
                        type="UserInputPointer"
                        target="clients"
                        field="clients"
                        object={object}
                        placeholder="Select"
                        onChange={this.onChange.bind(this)}
                        required={false}
                      />
                    </div>

                    <div className="mb-3">
                      {this.state.filtered.length > 0 ||
                      selectedProject ||
                      object.project_name?.name ? (
                        <>
                          <label className="form-label">Project Name</label>
                          <Drop
                            placeholder={
                              selectedProject
                                ? "Select a project"
                                : object.project_name?.name
                            }
                            value={
                              selectedProject
                                ? selectedProject
                                : object.project_name?.name
                            }
                            options={this.state.filtered}
                            onChange={this.handleProjectChange}
                          />
                        </>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Enter Note:</label>
                      <InputFactory
                        type="String"
                        target="transaction"
                        field="note"
                        object={object}
                        placeholder="Select"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        options={["Loan", "Investment", "Bank Transfer"]}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label className="form-label">Type:</label>
                      <InputFactory
                        type="String"
                        _type="Enum"
                        target="transaction"
                        field="type"
                        object={object}
                        placeholder="Select Type"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        options={["Money In", "Money Out"]}
                      />
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label">Types</label>

                      <InputFactory
                        type="UserInputPointer"
                        target="mio"
                        field="types"
                        object={object}
                        placeholder="Select"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        excludeFields={["GMD Fund"]}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Amount:</label>
                      <InputFactory
                        type="parseNumber"
                        target="transaction"
                        field="amounts"
                        object={object}
                        placeholder="Enter Amount"
                        onChange={this.onChange.bind(this)}
                        required={true}
                      />

                      {object.types?.type === "Money Out" && (
                        <div className="mb-3">
                          <label className="form-label">
                            Chart of Accounts
                          </label>
                          <InputFactory
                            type="UserInputPointer"
                            field="chart_of_accounts"
                            target="chart_of_accounts"
                            object={
                              object.chart_of_accounts?.["Chart of Accounts"]
                                ?.name
                            }
                            onChange={this.onChange.bind(this)}
                            required={true}
                            excludeFields={[
                              "name",
                              "amount",
                              "amounts",
                              "quantity",
                              "supplier",
                              "tin#",
                              "or#",
                            ]}
                          />
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Account:</label>
                      <p className="text-muted">
                        <em>
                          Balance -{" "}
                          {this.displayCurrentBalance(balance, formatCurrency)}
                        </em>
                      </p>
                      <InputFactory
                        type="ModdedInputPointer"
                        target="gmd_accounts"
                        field="account"
                        object={object}
                        placeholder="Select Account"
                        onChange={this.onChange.bind(this)}
                        required={true}
                        excludeFields={
                          user.roles[0].id === "admin"
                            ? ["Management money"]
                            : ["GMD Fund"]
                        }
                      />
                    </div>
                    {indicator && (
                      <div className="mb-3">
                        <label className="form-label">Source of Funds:</label>
                        <p className="text-muted">
                          <em>
                            Balance -{" "}
                            {this.displayCurrentBalance(
                              fund,
                              formatCurrency,
                              "fund"
                            )}
                          </em>
                        </p>
                        <InputFactory
                          type="UserInputPointer"
                          target="gmd_accounts"
                          field="fund_source"
                          object={object}
                          placeholder="Select Source"
                          onChange={this.onChange.bind(this)}
                          required={true}
                          // excludeFields={[
                          //   "Metrobank",
                          //   "BPI",
                          //   "GMD Revolving Fund",
                          // ]}
                          excludeFields={
                            user.roles[0].id === "admin"
                              ? [
                                  "Metrobank",
                                  "Management money",
                                  "BPI",
                                  "GMD Revolving Fund",
                                ]
                              : ["Metrobank", "BPI", "GMD Revolving Fund"]
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="d-flex justify-content-between ">
                    <button
                      type="submit"
                      className="btn  fs-sm me-3"
                      style={{ backgroundColor: "#EBBD2F" }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      CANCEL
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(NewTransactionPage);
