import BaseListPresenter from "../../base/BaseListPresenter";
import { dialog } from "nq-component";
import UpsertUseCase from "../../usecases/object/UpsertUseCase";
import { findObjectUseCase, deleteObjectUseCase } from "../../usecases/object";

class InvoicesPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    deleteObjectUseCase,
    getObjectUseCase,
    exportCSVUseCase,
    addSchemaUseCase,
    updateSchemaUseCase,
    deleteSchemaUseCase
  ) {
    super(view, findObjectUseCase, countObjectUseCase, deleteObjectUseCase);

    this.view = view;
    this.getObjectUseCase = getObjectUseCase;
    this.exportCSVUseCase = exportCSVUseCase;
    this.addSchemaUseCase = addSchemaUseCase;
    this.updateSchemaUseCase = updateSchemaUseCase;
    this.deleteSchemaUseCase = deleteSchemaUseCase;
    this.upsertUseCase = new UpsertUseCase();
    this.deleteObjectUseCase = deleteObjectUseCase; // Instantiating UpsertUseCase

    this.state = {
      selectedAccount: "",
    };
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.include = [
      "services",
      "services.questions",
      "services.questions.service",
      "client_name",
      "project_name",
    ];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  async findObjects() {
    const collection = this.view.getCollectionName();
    const query = this.createQuery();
    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
  async onClickItemDelete(index) {
    const object = this.objects[index].id;
    const collection = this.view.getCollectionName();
    try {
      await this.deleteObjectUseCase.execute(collection, object);
      this.objects.splice(index, 1);

      if (this.objects) {
        this.view.setObjects(this.objects);
      }
    } catch (error) {}
  }

  handleAccountChange = (event) => {
    this.setState({ selectedAccount: event.target.value });
  };
  onChange(object) {
    console.warn(object);
    this.change = object;
    this.object = object;
  }
  componentDidUpdate(prevProps) {
    const prevClassName = prevProps.params.name;
    const newClassName = this.view.getCollectionName();
    //if collection change
    if (prevClassName !== newClassName) {
      this.init();
      this.getObjects();
    }
  }
  onClickExport(index) {
    this.view.exportPDF(index);
  }
  onClickItem() {
    //const object = this.objects[index];
    const collection = this.view.getCollectionName();
    // this.view.navigateTo("/collection/" + collection + "/form/" + object.id);
    // this.view.navigateTo("/forms/invoices");
    this.view.navigateTo("/create-invoices");
  }
  handleEdit(index, objects) {
    const object = this.objects[index];
    console.log("heke", object);
    // this.view.navigateTo("/forms/invoices/edit/" + object.id);
    this.view.navigateTo("/edit-invoices/" + object.id);
  }

  async handleApprove(index, objects) {
    const object = this.objects[index];
    object.statuses = "Approved";
    await this.upsertUseCase.execute("invoices", object);
    this.view.navigateTo("/invoices");
    this.view.reload();
  }

  async handleSubmit(index, object, change, accounts) {
    const ids = accounts.find((item) => item.id === change.account);

    const mode = await this.findObjectUseCase.execute("paymode");
    const paymentMethodId = change.payment_method;
    const paymentMethodObject = mode.find(
      (method) => method.id === paymentMethodId
    );

    const cashInBank = await this.findObjectUseCase.execute("gmd_accounts");
    const combineBal = cashInBank.reduce(
      (total, account) => total + account.balance,
      0
    );

    const mio = {
      id: "5c3d711b-69ae-413a-9c2f-ea49a11f64b4",
      type: "Money In",
    };

    if (change === undefined || change === null) {
      dialog.fire({
        html: (
          <>
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              <span
                className="bi bi-exclamation-triangle-fill"
                style={{ fontSize: "2em", color: "red" }}
              ></span>
            </div>
            <div
              style={{
                fontSize: "1.2em",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              Submission cannot proceed without completing required fields.
            </div>
            <button
              className="btn mb-2 mt-2 me-2"
              style={{ backgroundColor: "#EBBD2F", float: "right" }}
              onClick={() => dialog.close()}
            >
              Confirm
            </button>
          </>
        ),
        footer: false,
      });
      return;
    }

    const missingFields = ["payment_method", "account", "amount"].filter(
      (key) => !Object.keys(change).includes(key)
    );

    if (missingFields.length > 0) {
      let errorMessage = "";
      missingFields.forEach((field) => {
        switch (field) {
          case "payment_method":
            errorMessage = "Payment Method field is required. ";
            break;
          case "account":
            errorMessage = "Account field is required. ";
            break;
          case "amount":
            errorMessage = "Amount field is required. ";
            break;
          default:
            break;
        }
      });
      dialog.fire({
        html: (
          <>
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              <span
                className="bi bi-exclamation-triangle-fill"
                style={{ fontSize: "2em", color: "red" }}
              ></span>
            </div>
            <div
              style={{
                fontSize: "1.2em",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              {errorMessage}
            </div>
            <button
              className="btn mb-2 mt-2 me-2"
              style={{ backgroundColor: "#EBBD2F", float: "right" }}
              onClick={() => dialog.close()}
            >
              Confirms
            </button>
          </>
        ),
        footer: false,
      });
      return;
    }
    const amountCheck = parseFloat(change.amount.replace(/,/g, ""));
    const collectibles = this.view.state.objects[index]?.collectibles;
    if (amountCheck > collectibles) {
      dialog.fire({
        html: (
          <>
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              <span
                className="bi bi-exclamation-triangle-fill"
                style={{ fontSize: "2em", color: "red" }}
              ></span>
            </div>
            <div
              style={{
                fontSize: "1.2em",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              Amount must be valid
            </div>
            <button
              className="btn mb-2 mt-2 me-2"
              style={{ backgroundColor: "#EBBD2F", float: "right" }}
              onClick={() => dialog.close()}
            >
              Confirm
            </button>
          </>
        ),
        footer: false,
      });
      return;
    }
    change.amount = parseFloat(change.amount.replace(/,/g, ""));
    console.log("CA", change.amount);
    change.invoice_num = this.view.state.objects[index]?.invoice_num;
    change.payment_method = paymentMethodObject;
    change.account = ids;

    const query = {
      where: {
        id: this.objects[index].id,
      },
    };

    const receivedPrevious = await findObjectUseCase().execute(
      "invoices_final",
      query
    );
    console.log("RP", receivedPrevious);

    const invObj = this.objects[index];
    invObj.downpaymentVal = this.view.state.objects[index]?.downpaymentVal;
    invObj.total = this.view.state.objects[index]?.total;
    invObj.collectibles = Math.abs(amountCheck - collectibles);
    console.log("asas", this.view.state.objects?.totalReceived);
    // invObj.received = receivedPrevious[0].received + amountCheck;

    if (receivedPrevious[0].received == null) {
      invObj.received = amountCheck;
    } else {
      invObj.received = receivedPrevious[0].received + amountCheck;
    }

    console.log("rece", receivedPrevious[0].received, "cere", amountCheck);
    invObj.statuses =
      // invObj.collectibles === 0 || invObj.total === invObj.received
      parseFloat(invObj.collectibles.toFixed(2)) === 0 ||
      invObj.downpaymentVal === invObj.received
        ? "Paid"
        : "Partially Paid";

    // const projQuery = {
    //   where: {
    //     // client_name: { id: this.view.props.params.cid },
    //     id: { id: invObj.project_name?.id },
    //   },
    // };
    // const cliQuery = {
    //   where: {
    //     id: { id: invObj.client_name?.id },
    //   },
    // };

    // const project = await findObjectUseCase().execute("projects", projQuery);
    // const client = await findObjectUseCase().execute("clients", cliQuery);
    console.log("OBJ", invObj);
    const transObj = {
      clients: invObj.client_name?.name,
      amounts: String(amountCheck),
      account: change.account,
      types: mio,
      note: "Invoice #" + invObj.invoice_num,
      account_balance: combineBal + amountCheck,
      project_name: { id: invObj.project_name.id, name: invObj.project_name },
    };

    const accObj = {
      name: change.account?.name,
      balance: change.account.balance + amountCheck,
      id: change.account.id,
    };
    console.log("xcv", transObj, invObj, invObj.client_name?.name);

    this.upsertUseCase.execute("payvoice", change);
    this.upsertUseCase.execute("invoices_final", invObj);
    this.upsertUseCase.execute("transaction", transObj);
    this.upsertUseCase.execute("gmd_accounts", accObj);

    dialog.close();
    this.view.submitting();

    this.view.submissionSuccess();
    await this.view.showSuccess("", "Payment Successful");
    await this.view.reload();
  }

  async onClickApprove(index, status) {
    const collection = "invoices_final";
    const object = this.objects[index];

    object.statuses = status === "Approved" ? "Approved" : status;

    try {
      const data = await this.upsertUseCase.execute(collection, object);
      if (data.statuses === object.statuses) {
        this.objects[index] = object;
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      this.view.showError(error);
    }
  }
}

export default InvoicesPresenter;
